import {
  Button,
  Field,
  FieldProps,
  Input,
  InputOnChangeData,
  InputProps,
  makeStyles,
  mergeClasses,
  shorthands,
} from '@fluentui/react-components';
import { Eye20Regular, EyeOff20Regular } from '@fluentui/react-icons';
import { omit } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type FormFieldProps = UseControllerProps<any> &
  Omit<FieldProps, 'name'> &
  Omit<InputProps, 'name' | 'label' | 'onChange'> & {
    helperText?: string;
    label?: string;
    onChange?: (
      ev: ChangeEvent<HTMLInputElement>,
      data: InputOnChangeData,
      formField: (...event: any[]) => void,
    ) => void;
  };

const useStyles = makeStyles({
  textUpperCase: {
    textTransform: 'uppercase',
  },
  inputFieldWrapper: {
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
    backgroundColor: '#F5F5F5',
  },
  inputFieldLabel: {
    fontSize: '10.24px',
    color: '#616161',
    lineHeight: '16.38px',
  },
  inputField: {
    ...shorthands.padding(0),
    ...shorthands.border('0px'),
    backgroundColor: 'transparent',
  },
});

export const FormField = ({ label, type, helperText, onChange, ...props }: FormFieldProps) => {
  const styles = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const {
    field: { onChange: fieldOnchange, ...field },
    fieldState: { error },
  } = useController(omit(props, ['disabled']));

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onChangeFn = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    if (onChange) {
      onChange(ev, data, fieldOnchange);
    }
    fieldOnchange(data.value);
  };

  return (
    <Field
      className={styles.inputFieldWrapper}
      label={<span className={mergeClasses(styles.inputFieldLabel, styles.textUpperCase)}>{label}</span>}
      validationMessage={helperText ?? error?.message}
    >
      <Input
        className={styles.inputField}
        type={type === 'password' && showPassword ? 'text' : type}
        onChange={onChangeFn}
        contentAfter={
          type === 'password' ? (
            <Button
              onClick={toggleShowPassword}
              size="small"
              appearance="transparent"
              icon={showPassword ? <EyeOff20Regular /> : <Eye20Regular />}
            />
          ) : undefined
        }
        {...props}
        {...field}
      />
    </Field>
  );
};
