import {
  Button,
  CounterBadge,
  Field,
  Input,
  InputOnChangeData,
  Label,
  makeStyles,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  shorthands,
  Title2,
  tokens,
  Tooltip,
} from '@fluentui/react-components';
import { Tag32Regular } from '@fluentui/react-icons';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import GroupedMetadataSelector from '../../../components/GroupedMetadataSelector';
import Loading from '../../../components/Loading';
import useAppData from '../../../hooks/useAppData';
import { Tag, tagsApi } from '../../../services';

export type SuggestionFilterFormProps = {
  appId: string;
};

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    rowGap: '24px',
    ...shorthands.overflow('hidden', 'auto'),
    ...shorthands.padding('16px', 0),
    boxSizing: 'border-box',
  },
  toolbarContainer: {
    flexGrow: 1,
    textAlign: 'center',
    boxSizing: 'border-box',
    width: '100%',
    ...shorthands.padding(0, '20px'),
  },
  formRow: {
    width: '100%',
    ...shorthands.flex(0, 0.8, 'auto'),
    ...shorthands.overflow('hidden', 'auto'),
  },
  filterForm: {
    ...shorthands.flex(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    minHeight: '60vh',
    boxSizing: 'border-box',
    ...shorthands.padding(0, '20px'),
    ...shorthands.overflow('hidden', 'auto'),
  },
  textInputArea: {
    minHeight: '90px',
    minWidth: '100%',
  },
  formActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '17px',
    width: '100%',
    ...shorthands.padding(0, '20px'),
    boxSizing: 'border-box',
  },
  formActionBtn: {
    minWidth: '165px',
    fontWeight: 'bold',
  },
  fullWidth: {
    minWidth: '100%',
  },
  dividerContainer: {
    height: '20px',
  },
  dividerText: {
    color: '#242424',
    fontSize: '12px',
    lineHeight: '16px',
    wordWrap: 'break-word',
  },
  filterInputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    flexGrow: 1,
    alignItems: 'flex-start',
    boxSizing: 'border-box',
  },
  popOverTrigger: {
    position: 'relative',
    minWidth: '32px',
  },
  popOverTriggerActive: {
    zIndex: '1000',
    backgroundColor: 'white',
    ...shorthands.borderRadius('50%'),
  },
  popOverTriggerBadge: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  filterContainerSurface: {
    ...shorthands.padding(0),
    '@media screen and (max-width: 600px)': { maxWidth: '80vw', width: '80vw' },
    '@media screen and (min-width: 601px) and (max-width: 10000px)': {
      maxWidth: '430px',
      width: '430px',
    },
  },
});

const SuggestionFilterForm = React.memo(
  function SuggestionFilterForm({ appId }: SuggestionFilterFormProps) {
    const [filters, setFilters] = useAppData<Record<string, any>>(appId, 'filters');
    const [isFilterOpened, setIsFilterOpened] = useAppData<boolean>(appId, 'isFilterOpened');

    const [keywordText, setKeywordText] = useState<string>((filters?.keywords as string) ?? '');

    const [selectedMetadataOptions, setSelectedMetadataOptions] = useState<Record<string, Array<any>>>(
      _.omit(filters, 'keywords'),
    );

    const { data: { labels, groupedTags } = { labels: [], groupedTags: {} }, isFetching } = tagsApi.useFetchTagsQuery(
      undefined,
      {
        selectFromResult: ({ data, ...rest }) => {
          const sortedGroupedTags = _.transform(
            data?.groupedTags || {},
            (result, values, key) => {
              result[key] = _.sortBy(values, value => value.name.toLowerCase());
            },
            {} as Record<string, Tag[]>,
          );

          return {
            data: { labels: data?.labels || [], groupedTags: sortedGroupedTags },
            ...rest,
          };
        },
        refetchOnReconnect: false,
        refetchOnMountOrArgChange: false,
        refetchOnFocus: false,
      },
    );

    const filterCount = useMemo(
      () => _.sumBy(_.values(filters), v => (v && !(_.isNil(v) || _.isEmpty(v)) ? 1 : 0)),
      [filters],
    );

    const styles = useStyles();

    const onUpdateSuggestion = () => {
      setFilters({
        keywords: keywordText,
        ...selectedMetadataOptions,
      });
      setIsFilterOpened(false);
    };

    const onKeywordTextChange = (_ev: React.ChangeEvent, data: InputOnChangeData) => {
      setKeywordText(data.value as string);
    };

    const clearFilters = () => {
      setKeywordText('');
      setSelectedMetadataOptions({});
    };

    return (
      <Popover
        open={isFilterOpened}
        onOpenChange={(_ev, data) => setIsFilterOpened(data.open as boolean)}
        inline
        size="large"
        withArrow
        positioning={'below-end'}
      >
        <PopoverTrigger disableButtonEnhancement>
          <Tooltip content="Filter search results" relationship="label" positioning="below">
            <Button
              appearance={'transparent'}
              icon={
                <>
                  {!isFilterOpened && filterCount !== 0 && (
                    <CounterBadge
                      iconPosition="after"
                      count={filterCount}
                      size="small"
                      appearance="filled"
                      className={styles.popOverTriggerBadge}
                      color="brand"
                      shape="circular"
                    />
                  )}
                  <Tag32Regular color={isFilterOpened ? tokens.colorBrandBackground : ''} />
                </>
              }
              className={mergeClasses(styles.popOverTrigger, isFilterOpened ? styles.popOverTriggerActive : '')}
            ></Button>
          </Tooltip>
        </PopoverTrigger>
        <PopoverSurface className={styles.filterContainerSurface}>
          <div className={styles.formContainer}>
            <div className={styles.toolbarContainer}>
              <Title2 className={styles.fullWidth}>Filters</Title2>
            </div>
            <div className={mergeClasses(styles.formRow, styles.filterForm)}>
              <div className={mergeClasses(styles.filterInputsContainer)}>
                <Field
                  key={'keysearchtext'}
                  className={styles.fullWidth}
                  label={<Label weight="semibold">Keywords</Label>}
                >
                  <Input
                    style={{ borderColor: tokens.colorNeutralStroke1 }}
                    value={keywordText}
                    placeholder="Insert a keyword or phrase as filter"
                    onChange={onKeywordTextChange}
                  />
                </Field>
                {isFetching && <Loading size="large" />}
                {!isFetching && (
                  <GroupedMetadataSelector
                    labels={labels}
                    groupedTags={groupedTags}
                    selectedOptions={selectedMetadataOptions}
                    onChange={selectedOptions => setSelectedMetadataOptions(selectedOptions)}
                  />
                )}
              </div>
            </div>
            <div className={mergeClasses(styles.formRow, styles.formActionContainer)}>
              <Button
                className={styles.formActionBtn}
                type="submit"
                shape="rounded"
                appearance="primary"
                onClick={onUpdateSuggestion}
              >
                Update Results
              </Button>
              <Button style={{ width: '100%' }} shape="rounded" appearance="outline" onClick={clearFilters}>
                Clear All Filters
              </Button>
            </div>
          </div>
        </PopoverSurface>
      </Popover>
    );
  },
  (_prev, _next) => false,
);

export default SuggestionFilterForm;
