import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import { appSelector, setAppDataFields, updateApp } from '../store/slices/appsSlice';

const useAppData = <T>(
  appId: string,
  field: string,
  defaultValue?: T,
): [T, (valueOrUpdater?: T | ((prevValue: T) => T)) => void] => {
  const fieldData: T = useAppSelector(state => {
    const app = appSelector.selectById(state, appId);
    if (!(field in app) && defaultValue !== undefined) {
      return defaultValue;
    }
    return app[field];
  });

  const dispatch = useAppDispatch();

  const setFieldValue = useCallback(
    (valueOrUpdater?: T | ((prevValue: T) => T)) => {
      dispatch(
        updateApp({
          id: appId,
          field: field,
          valueOrUpdater,
        }),
      );
    },
    [appId, dispatch, field],
  );

  useEffect(() => {
    if (fieldData === defaultValue && defaultValue !== undefined) {
      setFieldValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [fieldData, setFieldValue];
};

export const useAppDataFields = <T extends Record<string, any>>(
  appId: string,
  fields: Array<string>,
  defaultValue?: T,
): [T, (valueOrUpdater?: T | ((prevValue: T) => T)) => void] => {
  const selectAppData = (state: RootState) => appSelector.selectById(state, appId);

  const partialAppDataSelector = createSelector([selectAppData], app => {
    const dataPartials = _.pick(app, fields);
    _.forEach(fields, field => {
      if (!dataPartials[field] && defaultValue !== undefined) {
        dataPartials[field] = defaultValue[field];
      }
    });
    return dataPartials as T;
  });

  const data: T = useAppSelector(state => partialAppDataSelector(state));

  const dispatch = useAppDispatch();

  const setFieldsValue = useCallback(
    (valueOrUpdater?: T | ((prevValue: T) => T)) => {
      dispatch(
        setAppDataFields({
          id: appId,
          valueOrUpdater,
        }),
      );
    },
    [appId, dispatch],
  );

  useEffect(() => {
    if (data === defaultValue && defaultValue !== undefined) {
      setFieldsValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, setFieldsValue];
};

export default useAppData;
