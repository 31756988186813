import {
  Divider,
  Input,
  InputOnChangeData,
  makeStyles,
  shorthands,
  Subtitle2Stronger,
  tokens,
  Toolbar,
  ToolbarButton,
} from '@fluentui/react-components';
import {
  ChevronDownRegular,
  ChevronUpRegular,
  DismissRegular,
  DocumentText24Regular,
  SearchRegular,
} from '@fluentui/react-icons';
import { debounce } from 'lodash';
import React, { Dispatch } from 'react';
import { IDocument } from '../../../interfaces';

const useStyles = makeStyles({
  readerHead: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.overflow('hidden', 'hidden'),
    rowGap: '8px',
    width: '100%',
    boxSizing: 'border-box',
  },
});

type DocumentReaderToolbarProps = {
  doc: IDocument;
  searchText: string;
  activeMark: number;
  filteredSearchLength: number;
  setSearchText: Dispatch<React.SetStateAction<string>>;
  setActiveMark: Dispatch<React.SetStateAction<number>>;
  onClose?: () => void;
};

export const DocumentReaderToolbar = ({
  doc,
  searchText,
  activeMark,
  filteredSearchLength,
  setSearchText,
  setActiveMark,
  onClose,
}: DocumentReaderToolbarProps) => {
  const styles = useStyles();

  const navigateDown = () => {
    setActiveMark((activeMark + 1) % filteredSearchLength);
  };

  const navigateUp = () => {
    setActiveMark((activeMark + filteredSearchLength - 1) % filteredSearchLength);
  };

  const onSearchTextChange = debounce((_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSearchText(data.value as string);
    setActiveMark(0);
  }, 300);

  return (
    <div className={styles.readerHead}>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '5px',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignContent: 'center',
            maxWidth: '90%',
          }}
        >
          <DocumentText24Regular color={tokens.colorBrandBackground} />
          <Subtitle2Stronger wrap={false} style={{ textOverflow: 'ellipsis' }}>
            {doc.doc_file_name}
          </Subtitle2Stronger>
        </div>
        <ToolbarButton aria-label="Dismiss" icon={<DismissRegular />} appearance="subtle" onClick={() => onClose?.()} />
      </Toolbar>
      <Divider />
      <Toolbar>
        <Input
          appearance="underline"
          contentBefore={<SearchRegular />}
          contentAfter={
            <div style={{ alignItems: 'center', display: 'flex', gap: '12px' }}>
              <span style={{ fontSize: '12px' }}>
                {filteredSearchLength > 0 ? `${activeMark + 1}/${filteredSearchLength}` : '0/0'}
              </span>

              <Divider vertical />

              <ToolbarButton
                disabled={filteredSearchLength === 0}
                icon={<ChevronUpRegular />}
                onClick={navigateUp}
                style={{ padding: '0px', minWidth: '20px', maxWidth: '20px' }}
              />

              <ToolbarButton
                disabled={filteredSearchLength === 0}
                icon={<ChevronDownRegular />}
                onClick={navigateDown}
                style={{ padding: '0px', minWidth: '20px', maxWidth: '20px' }}
              />
            </div>
          }
          onChange={onSearchTextChange}
          placeholder="Search by keywords..."
          style={{ flexGrow: 1 }}
          type="text"
        />
      </Toolbar>
    </div>
  );
};
