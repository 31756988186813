import React from 'react';
import { AnomaliesPage } from '../enums';
import AnomaliesForm from '../pages/AnomaliesForm';
import AnomaliesHome from '../pages/AnomaliesHome';
import AnomaliesReport from '../pages/AnomaliesReport';

type AnomaliesPageType = { [P in keyof AnomaliesPage]: AnomaliesPage[P] };

type ComponentType<T extends AnomaliesPageType> = T extends AnomaliesPage.ANOMALIES_HOME
  ? React.ElementType<typeof AnomaliesHome>
  : T extends AnomaliesPage.ANOMALIES_FORM
    ? React.ElementType<typeof AnomaliesForm>
    : T extends AnomaliesPage.ANOMALIES_REPORT
      ? React.ElementType<typeof AnomaliesReport>
      : React.ElementType;

type GenericAnomaliesAppType<
  C extends React.ElementType = ComponentType<
    AnomaliesPage.ANOMALIES_HOME | AnomaliesPage.ANOMALIES_FORM | AnomaliesPage.ANOMALIES_REPORT
  >,
> = {
  as: C;
} & React.ComponentProps<C>;

export default function GenericAnomaliesApp<T extends AnomaliesPageType>({
  as,
  children,
  ...props
}: GenericAnomaliesAppType<ComponentType<T>>) {
  const Component = as || 'div';

  return <Component {...props}>{children}</Component>;
}
