import { CachedBookmarks, GetBookmarksResponse } from '../../../models/responses/bookmarks';

export const transformBookmarksResponse = ({ bookmarks = [] }: GetBookmarksResponse): CachedBookmarks => {
  const cachedBookmarks: CachedBookmarks = { clauses: [], comments: [], documents: [] };

  bookmarks?.forEach(({ clause, comment, document }) => {
    if (clause && clause.clauseId) {
      cachedBookmarks.clauses.push(clause.clauseId);
    }
    if (comment && comment.commentId) {
      cachedBookmarks.comments.push(comment.commentId);
    }
    if (document && document.documentId) {
      cachedBookmarks.documents.push(document.documentId);
    }
  });

  return cachedBookmarks;
};
