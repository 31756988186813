import { makeStyles, mergeClasses, Text } from '@fluentui/react-components';
import DiffMatchPatch from 'diff-match-patch';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import parseHtmlContent from '../../utils/parseHtmlContent';
import { getDiffs } from './helper';

export interface ClauseDiffHighlighterProps {
  originalText: string;
  text: string;
  improveReadability: boolean;
  className?: string;
  hasTextOverflow?: (isOverflow: boolean) => void;
}

const useStyles = makeStyles({
  preWrapped: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  insert: {
    color: 'blue',
  },
  delete: {
    color: 'red',
  },
});

export default function ClauseDiffHighlighter(props: ClauseDiffHighlighterProps) {
  const [diffs, setDiffs] = useState<DiffMatchPatch.Diff[]>(() => {
    return getDiffs(props.originalText.trim(), props.text.trim(), props.improveReadability);
  });

  const styles = useStyles();

  const clauseTextRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const isOverflow = clauseTextRef?.current?.scrollHeight! > clauseTextRef?.current?.clientHeight!;
    props.hasTextOverflow?.(!!isOverflow);
  }, [props]);

  useEffect(() => {
    setDiffs(getDiffs(props.originalText.replace('\n', ' ').trim(), props.text.trim(), props.improveReadability));
  }, [props.originalText, props.text, props.improveReadability]);

  const RenderDiffs = useCallback(() => {
    if (_.isEmpty(diffs)) {
      return <span className={styles.preWrapped}>We are here {props.text}</span>;
    }
    const elements = diffs.map(([op, text], idx) => {
      switch (op) {
        case DiffMatchPatch.DIFF_INSERT:
          return (
            <ins key={`text_${idx}`} className={mergeClasses(styles.preWrapped, styles.insert)}>
              {text}
            </ins>
          );
        case DiffMatchPatch.DIFF_DELETE:
          return (
            <del key={`text_${idx}`} className={mergeClasses(styles.preWrapped, styles.delete)}>
              {text}
            </del>
          );
        case DiffMatchPatch.DIFF_EQUAL:
          return (
            <span key={`text_${idx}`} className={styles.preWrapped}>
              {text}
            </span>
          );
        default:
          return <></>;
      }
    });
    return <span>{elements}</span>;
  }, [diffs, styles.insert, styles.preWrapped, styles.delete, props.text]);

  const onDrag: React.DragEventHandler = (ev: React.DragEvent) => {
    const htmlTextSerializer = new XMLSerializer();
    const text = htmlTextSerializer.serializeToString(clauseTextRef.current!);
    ev.dataTransfer.setData('text', parseHtmlContent(text));
  };

  return (
    <Text truncate ref={clauseTextRef} className={props.className} onDrag={onDrag}>
      {RenderDiffs()}
    </Text>
  );
}
