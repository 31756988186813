import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { GetUsersResponse, User } from '../../models/responses/user';
import { axiosBaseQuery } from './axiosConfig';

const getUsersQuery = () => ({ url: '', method: 'GET' });
const transformGetUsers = ({ users }: GetUsersResponse) => _.orderBy(users, [user => user], ['asc']);

export const UsersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: 'v3/users',
  }),
  endpoints: build => ({
    getUsers: build.query<User[], void>({
      query: () => getUsersQuery(),
      transformResponse: transformGetUsers,
    }),
  }),
});
