import {
  makeStyles,
  mergeClasses,
  Radio,
  RadioGroup,
  RadioOnChangeData,
  tokens,
  Tooltip,
} from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import { SuggestionSearchMode } from '../../enums';

export type SuggestionTypeSwitchProps = {
  type: SuggestionSearchMode;
  enableTooltip?: boolean;
  onChange: Function;
};

const useStyles = makeStyles({
  searchTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '4px',
    alignItems: 'center',
    alignContent: 'center',
  },
  activeSearchMode: {
    fontWeight: tokens.fontWeightSemibold,
  },
  searchModeLabel: {
    color: tokens.colorNeutralForeground1,
    display: 'flex',
    flexDirection: 'row',
    columnGap: '4px',
    alignItems: 'center',
  },
  spanWidth: {
    width: '200px',
  },
});

export default function SuggestionTypeSwitch({ type, onChange, enableTooltip }: SuggestionTypeSwitchProps) {
  const searchMode = useMemo(() => (type === SuggestionSearchMode.SEMANTIC_SEARCH ? '1' : '2'), [type]);
  const styles = useStyles();

  const onSearchModeChange = (ev: React.FormEvent<HTMLDivElement>, data: RadioOnChangeData) => {
    const selectedType =
      data.value === '1' ? SuggestionSearchMode.SEMANTIC_SEARCH : SuggestionSearchMode.KEYWORD_SEARCH;
    onChange(selectedType);
  };

  return (
    <RadioGroup
      value={searchMode}
      layout="horizontal"
      onChange={onSearchModeChange}
      className={styles.searchTypeContainer}
    >
      <Radio
        value={'1'}
        label={
          <div className={mergeClasses(searchMode === '1' ? styles.activeSearchMode : '', styles.searchModeLabel)}>
            <span>Smart Search</span>
            <Tooltip
              content={{
                children: (
                  <>
                    This utilizes AI-driven semantic vector similarity to find relevant text based on the meaning of
                    your query. <br /> <br /> Type in a description of the provision you want to find as if you were
                    describing it to another lawyer. <br /> <br /> Ideal for comprehensive, context-aware searches.
                  </>
                ),
                className: styles.spanWidth,
              }}
              positioning={'below-start'}
              relationship={'description'}
              withArrow
            >
              <InfoRegular fontSize={18} />
            </Tooltip>
          </div>
        }
      />
      <Radio
        value={'2'}
        label={
          <div className={mergeClasses(searchMode === '2' ? styles.activeSearchMode : '', styles.searchModeLabel)}>
            <span>Boolean Search (Beta)</span>

            <Tooltip
              content={{
                children: (
                  <>
                    This employs traditional Boolean logic for targeted text searches.
                    <br />
                    <br />
                    Specify your exact search criteria using operators like AND, OR, NOT for precise, rule-based
                    results. <br />
                    <br />
                    Perfect for specific, criteria-driven queries.
                  </>
                ),
                className: styles.spanWidth,
              }}
              positioning={'below'}
              relationship={'description'}
              withArrow
            >
              <InfoRegular fontSize={18} />
            </Tooltip>
          </div>
        }
      />
    </RadioGroup>
  );
}
