import React from 'react';

export default function SettingsAppIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3591_29286)">
        <path
          d="M7.08333 0H32.9167C36.8333 0 40 3.24786 40 7.26496V32.735C40 36.7521 36.8333 40 32.9167 40H7.08333C3.16667 40 0 36.7521 0 32.735V7.26496C0 3.24786 3.16667 0 7.08333 0Z"
          fill="#6A467C"
        />
        <g clipPath="url(#clip1_3591_29286)">
          <path
            d="M27.1401 20.94C27.1801 20.64 27.2001 20.33 27.2001 20C27.2001 19.68 27.1801 19.36 27.1301 19.06L29.1601 17.48C29.3401 17.34 29.3901 17.07 29.2801 16.87L27.3601 13.55C27.2401 13.33 26.9901 13.26 26.7701 13.33L24.3801 14.29C23.8801 13.91 23.3501 13.59 22.7601 13.35L22.4001 10.81C22.3601 10.57 22.1601 10.4 21.9201 10.4H18.0801C17.8401 10.4 17.6501 10.57 17.6101 10.81L17.2501 13.35C16.6601 13.59 16.1201 13.92 15.6301 14.29L13.2401 13.33C13.0201 13.25 12.7701 13.33 12.6501 13.55L10.7401 16.87C10.6201 17.08 10.6601 17.34 10.8601 17.48L12.8901 19.06C12.8401 19.36 12.8001 19.69 12.8001 20C12.8001 20.31 12.8201 20.64 12.8701 20.94L10.8401 22.52C10.6601 22.66 10.6101 22.93 10.7201 23.13L12.6401 26.45C12.7601 26.67 13.0101 26.74 13.2301 26.67L15.6201 25.71C16.1201 26.09 16.6501 26.41 17.2401 26.65L17.6001 29.19C17.6501 29.43 17.8401 29.6 18.0801 29.6H21.9201C22.1601 29.6 22.3601 29.43 22.3901 29.19L22.7501 26.65C23.3401 26.41 23.8801 26.09 24.3701 25.71L26.7601 26.67C26.9801 26.75 27.2301 26.67 27.3501 26.45L29.2701 23.13C29.3901 22.91 29.3401 22.66 29.1501 22.52L27.1401 20.94ZM20.0001 23.6C18.0201 23.6 16.4001 21.98 16.4001 20C16.4001 18.02 18.0201 16.4 20.0001 16.4C21.9801 16.4 23.6001 18.02 23.6001 20C23.6001 21.98 21.9801 23.6 20.0001 23.6Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3591_29286">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
        <clipPath id="clip1_3591_29286">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}
