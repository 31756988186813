import { makeStyles, tokens } from '@fluentui/react-components';
import { useEffect, useMemo, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { DocumentTextBlock } from '../../../interfaces';
import './virtuoso.css';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
});

const ClauseText = (
  index: number,
  { text, clauseId }: DocumentTextBlock,
  highlightedText: string[],
  highlightedClauseId?: string,
  activeClauseIndex?: number,
  activeMark?: number,
) => {
  return (
    <p
      id={`clause-${index}`}
      key={clauseId}
      style={{
        whiteSpace: 'pre-line',
      }}
    >
      <Highlighter
        activeStyle={{ backgroundColor: tokens.colorPaletteMarigoldBackground3 }}
        activeIndex={activeClauseIndex === index ? activeMark : undefined}
        searchWords={highlightedText}
        autoEscape={true}
        textToHighlight={text}
        unhighlightStyle={{
          backgroundColor: clauseId === highlightedClauseId ? tokens.colorPaletteYellowBackground2 : 'transparent',
        }}
      />
    </p>
  );
};

type DocumentReaderProps = {
  documentNodes: Array<DocumentTextBlock>;
  filteredSearchList: number[];
  activeMark: number;
  searchText: string;
  textBlockId?: string;
};

export const DocumentReaderList = ({
  documentNodes,
  filteredSearchList,
  textBlockId,
  searchText,
  activeMark,
}: DocumentReaderProps) => {
  const virtuoso = useRef<VirtuosoHandle | null>(null);
  const isSearching = useRef(false);
  const styles = useStyles();

  const clauseIndex = useMemo(() => {
    if (filteredSearchList.length > 0) {
      return filteredSearchList[activeMark];
    } else if (textBlockId) {
      return documentNodes.findIndex(({ clauseId }) => clauseId === textBlockId);
    }
    return 0;
  }, [filteredSearchList, textBlockId, activeMark, documentNodes]);

  const markIndex = useMemo(() => {
    if (filteredSearchList.length === 0) return 0;
    const index = filteredSearchList.findIndex(item => item === filteredSearchList[activeMark]);
    if (index < 0) return activeMark;
    return activeMark - index;
  }, [filteredSearchList, activeMark]);

  useEffect(() => {
    const parentElement = document.getElementById(`clause-${clauseIndex}`);
    if (parentElement) {
      const markElements = parentElement.querySelectorAll('mark');
      const marker = markElements.item(markIndex);
      marker?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      virtuoso.current?.scrollToIndex(clauseIndex);
      isSearching.current = true;
    }
  }, [markIndex, clauseIndex]);

  const isScrolling = (isScrolling: boolean) => {
    if (!isScrolling && isSearching.current) {
      const marks = document.getElementsByTagName('mark');
      const marker = marks.item(0);
      marker?.scrollIntoView({ behavior: 'smooth' });
      isSearching.current = false;
    }
  };

  return (
    <div className={styles.container} id="documentReader">
      <Virtuoso
        className={useStyles().container}
        ref={virtuoso}
        isScrolling={isScrolling}
        data={documentNodes ?? []}
        itemContent={(index, node) => ClauseText(index, node, [searchText], textBlockId, clauseIndex, markIndex)}
      />
    </div>
  );
};
