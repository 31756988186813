import { makeStyles, shorthands } from '@fluentui/react-components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import WorkerPort from '../libs/services/worker';
import { HandleWorkerMessage } from '../libs/services/worker/MessageHandlers';
import { RootState } from '../libs/store';
import AuthenticatedApp from './pages/AuthenticatedApp';
import LoginPage from './pages/LoginPage';

const useStyles = makeStyles({
  appContainer: {
    minHeight: '100vmin',
    minWidth: '100vw',
    ...shorthands.overflow('hidden'),
  },
});

function App() {
  WorkerPort.onmessage = HandleWorkerMessage();
  const appUserContext = useSelector((state: RootState) => state.global.appContext?.isLoggedIn);

  useEffect(() => {
    const resizePWA = (event: any) => {
      const isBrowser = matchMedia('(display-mode: browser)').matches;
      if (!isBrowser) {
        window.resizeTo(560, window.screen.availHeight);
      }
    };
    window.addEventListener('load', resizePWA);
    return window.removeEventListener('load', resizePWA);
  }, []);

  const styles = useStyles();

  return (
    <div className={styles.appContainer}>
      <Routes>
        <Route index element={<>{appUserContext ? <AuthenticatedApp /> : <LoginPage />}</>} />
        <Route path="/standalone" element={appUserContext ? <AuthenticatedApp mode={'standalone'} /> : <LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
