import { Button } from '@fluentui/react-components';
import _ from 'lodash';
import React, { useState } from 'react';
import { ApplicationType, SuggestionAppPage, SuggestionSearchMode } from '../../../enums';
import { useAppData, useContextApp, useLocalDBStore } from '../../../hooks';
import { ISuggestionData } from '../../../interfaces';
import KeywordTransformer from '../../../utils/keywordTransformer';
import SuggestionWorkspaceSelector from './SuggestionWorkspaceSelector';

type SuggestionSearchActionsProps = {
  appId: string;
  userId: string;
  className?: string;
};

const SuggestionSearchActions = React.memo(({ className, appId, userId }: SuggestionSearchActionsProps) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [searchText] = useAppData<string>(appId, 'searchText');
  const [searchType] = useAppData<SuggestionSearchMode>(appId, 'searchType');
  const [workspaceId] = useAppData<string>(appId, 'workspaceId');
  const [metadataTags] = useAppData<string[]>(appId, 'metadataTags');
  const [filters] = useAppData<Record<string, any>>(appId, 'filters');

  const { createContextApp } = useContextApp();

  const { SaveSearchHistory } = useLocalDBStore();

  const onSubmitSearchClicked: React.MouseEventHandler = _.debounce((_ev: React.MouseEvent) => {
    setIsSubmitted(true);
    let keywordSearchText = '';
    if (searchType === SuggestionSearchMode.KEYWORD_SEARCH) {
      const keywordTransformer = new KeywordTransformer();
      keywordSearchText = keywordTransformer.sanitize(searchText as string)?.trim();
    }

    const searchAppData = {
      searchText,
      searchType,
      workspaceId,
      metadataTags,
      keywordSearchText,
      filters,
    } as ISuggestionData;

    createContextApp(ApplicationType.SUGGESTION_APP, {
      name: `Results - ${searchText}`,
      ...searchAppData,
      from: SuggestionAppPage.SEARCH_SUGGESTION,
      currentPage: SuggestionAppPage.SUGGESTION_RESULT,
    });

    SaveSearchHistory(searchAppData);

    setIsSubmitted(false);
  }, 500);

  const isSubmitDisabled = !workspaceId || workspaceId === '' || !searchText || searchText === '';

  return (
    <div className={className}>
      <Button
        disabled={isSubmitDisabled || isSubmitted}
        type="submit"
        shape="rounded"
        appearance="primary"
        onClick={onSubmitSearchClicked}
      >
        Search
      </Button>
      <SuggestionWorkspaceSelector appId={appId} userId={userId} />
    </div>
  );
});

export default SuggestionSearchActions;
