import React from 'react';

export default function SuggestionAppIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <span style={{ padding: '5px', margin: 0, backgroundColor: 'purple', borderRadius: '8px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height={props.height ?? '40'}
        viewBox="0 -960 960 960"
        width={props.width ?? '40'}
      >
        <path
          d="M80-200v-80h400v80H80Zm0-200v-80h200v80H80Zm0-200v-80h200v80H80Zm744 400L670-354q-24 17-52.5 25.5T560-320q-83 0-141.5-58.5T360-520q0-83 58.5-141.5T560-720q83 0 141.5 58.5T760-520q0 29-8.5 57.5T726-410l154 154-56 56ZM560-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
}
