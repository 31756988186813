const parseHtmlContent = (htmlContent: string): string => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlContent, 'text/html');

  parsedHtml.querySelectorAll('del, s, strike, br').forEach(el => el.remove());

  parsedHtml.body.childNodes.forEach((node: ChildNode) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      element.innerText = element.innerText?.replace(/\n/g, ' ').replace(/\s\s/g, ' ').trim() || '';
    }
  });

  return parsedHtml.body.innerText?.trim() || '';
};

export default parseHtmlContent;
