import { DocumentCopy48Regular } from '@fluentui/react-icons';
import { useLiveQuery } from 'dexie-react-hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import EmptyPlaceholder from '../../../components/EmptyPlaceholder';
import { LoadingContainer } from '../../../components/Loading';
import SearchHistoryCard from '../../../components/SearchHistoryCard';
import { useAppDataFields } from '../../../hooks/useAppData';
import { SearchHistory, localDbStore } from '../../../store/LocalDbStore';

type SuggestionSearchHistoryProps = {
  appId: string;
  userId: string;
};
const getSearchHistoryByUserId = (userId: string, count: number = 10) =>
  localDbStore.histories.where('userId').equalsIgnoreCase(userId).reverse().limit(count).toArray();

const onRenderItem = (idx: number, item: SearchHistory, context: any) => {
  const { onSearchAgain } = context;
  return (
    <div key={idx} style={{ paddingRight: '5px', paddingLeft: '1px' }}>
      <SearchHistoryCard history={item} onChangeSearch={onSearchAgain} />
    </div>
  );
};

const onRenderEmptyPlacehorder = () => (
  <EmptyPlaceholder text="No search history found." icon={<DocumentCopy48Regular />} />
);

const SuggestionSearchHistory = React.memo(({ userId, appId }: SuggestionSearchHistoryProps) => {
  const [, updateDataFields] = useAppDataFields<Record<string, any>>(appId, [
    'searchText',
    'searchType',
    'workspaceId',
    'metadataTags',
  ]);

  const searchHistories = useLiveQuery(() => getSearchHistoryByUserId(userId), [userId]);

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const onSearchAgain = (history: SearchHistory) => {
    updateDataFields({
      searchText: history.searchText,
      searchType: history.searchType,
      workspaceId: history.workspaceId,
      metadataTags: history.tags,
    });
  };

  return (
    <LoadingContainer isLoading={_.isNil(searchHistories)}>
      <Virtuoso
        ref={virtuosoRef}
        style={{ height: '100%' }}
        data={searchHistories ?? []}
        context={{ onSearchAgain }}
        itemContent={onRenderItem}
        components={{
          EmptyPlaceholder: onRenderEmptyPlacehorder,
        }}
      />
    </LoadingContainer>
  );
});

export default SuggestionSearchHistory;
