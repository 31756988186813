import {
  Button,
  Field,
  Link,
  Menu,
  MenuItemCheckbox,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
  tokens,
} from '@fluentui/react-components';
import { ArrowSyncRegular } from '@fluentui/react-icons';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceApi } from '../../../services';
import { RootState } from '../../../store';

type WorkspaceMenuSelectorProps = {
  value: string;
  onChange: (value: string) => void;
};

const WorkspaceMenuSelector = React.memo(({ value, onChange }: WorkspaceMenuSelectorProps) => {
  const userId = useSelector((state: RootState) => state.global?.appContext?.app_user_id as string);
  const {
    data: workspaces = [],
    isLoading,
    refetch,
    isFetching,
  } = WorkspaceApi.useGetWorkspacesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      const allowedWorkspaces = data?.filter(
        w => w.active && (w.authorizedUserIds.includes('-1') || w.authorizedUserIds.includes(userId)),
      );
      return { data: allowedWorkspaces, ...rest };
    },
  });

  const selectedWorkspace = useMemo(() => {
    if (isLoading) return {};
    if (_.isEmpty(workspaces)) return {};
    const w = _.find(workspaces, { workspaceId: value });
    if (!w) return { [workspaces[0].name]: [workspaces[0].workspaceId] };
    return { [w!.name]: [w!.workspaceId] };
  }, [workspaces, value, isLoading]);

  const refreshWorkspaces = async () => {
    await refetch().unwrap();
  };

  return (
    <Field
      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', columnGap: '3px' }}
    >
      Searching in workspace{' '}
      <Menu
        positioning={'below'}
        checkedValues={selectedWorkspace}
        onCheckedValueChange={(_ev, data) => onChange(data.checkedItems[0])}
      >
        <MenuTrigger disableButtonEnhancement>
          <Link inline disabled={isFetching || isLoading} style={{ fontWeight: tokens.fontWeightSemibold }}>
            {isLoading && 'Loading Workspaces...'}
            {!isLoading && Object.keys(selectedWorkspace)[0]}
          </Link>
        </MenuTrigger>
        <MenuPopover style={{ maxHeight: '200px' }}>
          <MenuList>
            {workspaces?.map(w => (
              <MenuItemCheckbox key={w.workspaceId} name={w.name} value={w.workspaceId}>
                {w.name}
              </MenuItemCheckbox>
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
      {isFetching && <Spinner size="extra-tiny" />}
      {!isFetching && (
        <Button
          size="small"
          appearance="transparent"
          icon={<ArrowSyncRegular color={tokens.colorBrandBackground} />}
          onClick={refreshWorkspaces}
        />
      )}
    </Field>
  );
});

export default WorkspaceMenuSelector;
