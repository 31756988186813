import { SelectTabData, Tab, TabList } from '@fluentui/react-components';
import {
  CommentNoteFilled,
  CommentNoteRegular,
  GroupListFilled,
  GroupListRegular,
  TextboxMoreFilled,
  TextboxMoreRegular,
  bundleIcon,
} from '@fluentui/react-icons';
import _ from 'lodash';
import React from 'react';
import useStyles from '../../styles';

type SelectableTabListProps = {
  value: string;
  onChange: (tab: string) => void;
};

const DocumentInfoIcon = bundleIcon(TextboxMoreFilled, TextboxMoreRegular);
const SimilarClauseIcon = bundleIcon(GroupListFilled, GroupListRegular);
const CommentIcon = bundleIcon(CommentNoteFilled, CommentNoteRegular);

export default React.memo(({ value, onChange }: SelectableTabListProps) => {
  const styles = useStyles();

  const onSelectedTabChange = _.debounce((_ev, data: SelectTabData) => {
    onChange(data.value as string);
  }, 300);

  return (
    <TabList
      className={styles.stretchedTabs}
      defaultSelectedValue={'documentInfo'}
      selectedValue={value}
      onTabSelect={onSelectedTabChange}
    >
      <Tab id="documentInfo" icon={<DocumentInfoIcon />} value={'documentInfo'}>
        More Info
      </Tab>
      <Tab id="similarClauses" icon={<SimilarClauseIcon />} value={'similarClauses'}>
        Similar Clauses
      </Tab>
      <Tab id="comments" icon={<CommentIcon />} value={'comments'}>
        Comments
      </Tab>
    </TabList>
  );
});
