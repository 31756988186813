import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export default makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    rowGap: '15px',
    justifyContent: 'center',
    boxSizing: 'border-box',
    ...shorthands.overflow('hidden', 'auto'),
    ...shorthands.padding(0),
  },
  mainClauseContainer: {
    ...shorthands.padding('0', '16px'),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '15px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    columnGap: '15px',
  },
  stretchedTabs: {
    flexWrap: 'nowrap',
    '> button': {
      flexGrow: 1,
    },
    ...shorthands.borderBottom('1px', 'solid', tokens.colorBrandBackground2),
  },
  tabContent: {
    ...shorthands.flex(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    height: '100%',
    ...shorthands.padding(0),
    ...shorthands.overflow('hidden', 'auto'),
  },
  displayNone: {
    display: 'none',
  },
  similarClauseContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'flex-start',
    rowGap: '15px',
    width: '100%',
    minHeight: '500px',
    ...shorthands.overflow('hidden', 'auto'),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100vh',
    width: '100%',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    backdropFilter: '10px',
    zIndex: 5,
    ...shorthands.transition('display', '5s', 'inherit', 'ease-in-out'),
  },
});
