import { Button, makeStyles, shorthands, Spinner } from '@fluentui/react-components';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import ClauseCard from '../../../components/clause-card/ClauseCard';
import { LoadingContainer } from '../../../components/Loading';
import { ApplicationType, ClauseCardMode, SuggestionSearchMode } from '../../../enums';
import { useAppData, useContextApp } from '../../../hooks';
import { IClause, IClauseInfoData, IDocument } from '../../../interfaces';
import { SearchSuggestionRequest } from '../../../models/requests';
import { bookmarksApi, favoritesApi, suggestionApi, trigramsApi, WordDocumentApi } from '../../../services';
import { useAppSelector } from '../../../store';
import { filteredAndRankedByOptions, getKeywords } from './helpers';

type SuggestionListProps = {
  appId: string;
  onOpenDocument?: (document: IDocument, clause?: IClause) => void;
};

const SuggestionMoreLoader = ({ context: { loadMore, loading, appId } }: any) => {
  const [moreAvailable] = useAppData<boolean>(appId, 'moreAvailable');

  if (!moreAvailable) {
    return <></>;
  }

  return (
    <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
      {loading && <Spinner></Spinner>}

      {!loading && (
        <Button appearance="primary" onClick={loadMore} style={{ marginTop: '10px' }}>
          Load More
        </Button>
      )}
    </div>
  );
};

const OnRenderSuggestion = (idx: number, sug: IClause, context: any) => {
  const {
    onAcceptClause,
    onDeclineClause,
    onClauseDetailClicked,
    onClauseAddToFavorite,
    onClauseBookmark,
    isClauseBookmarked,
    isClauseFavorite,
    searchText,
    searchKeywords,
    onOpenClauseDocument,
    highlightUnusualText,
    redlined,
    footNoteItems,
    unusualPhrases,
    filterKeywords,
    goodSuggestions,
    isKeywordSearch,
  } = context;

  const isGoodSuggestion = goodSuggestions?.includes(sug.clause_text);

  return (
    <div key={idx} style={{ paddingTop: '4px', paddingRight: '5px', paddingLeft: '1px' }}>
      <ClauseCard
        key={`suggesiton_${sug.clause_id}_${idx}`}
        clause={sug}
        isGoodSuggestion={isGoodSuggestion}
        isKeywordSearch={isKeywordSearch}
        footNoteItems={footNoteItems}
        mode={ClauseCardMode.SUGGESTION}
        unusualPhrases={unusualPhrases[sug.clause_id]}
        onAcceptClicked={() => onAcceptClause(sug)}
        onDeclineClicked={() => onDeclineClause(sug)}
        onClauseDetailClicked={() => onClauseDetailClicked(sug)}
        onAddFavoriteClick={clause => onClauseAddToFavorite(clause)}
        onBookmarkClick={clause => onClauseBookmark(clause)}
        isBookmarked={isClauseBookmarked(sug)}
        isFavorite={isClauseFavorite(sug)}
        searchText={searchText}
        searchKeywords={searchKeywords}
        filterKeywords={filterKeywords}
        readlineEnabled={redlined}
        onOpenDocument={onOpenClauseDocument}
        unusualTextHighlightEnabled={highlightUnusualText}
      />
    </div>
  );
};

const useStyles = makeStyles({
  placeholder: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    width: '100%',
    textAlign: 'center',
    ...shorthands.overflow('hiden'),
  },
});

const SuggestionList = React.memo(function SuggestionList({ appId, ...props }: SuggestionListProps) {
  const [searchText] = useAppData<string>(appId, 'searchText');
  const [keywordSearchText] = useAppData<string>(appId, 'keywordSearchText');
  const [suggestions] = useAppData<Array<IClause>>(appId, 'suggestions');
  const [redlined] = useAppData<boolean>(appId, 'redlined');
  const [highlightUnusualText] = useAppData<boolean>(appId, 'hightlightUnusualText');
  const [goodSuggestions, setGoodSuggestions] = useAppData<Array<string>>(appId, 'goodSuggestions');
  const [badSuggestions, setBadSuggestions] = useAppData<Array<string>>(appId, 'badSuggestions');
  const [isLoading] = useAppData<boolean>(appId, 'isLoading');
  const [filters] = useAppData<Record<string, any>>(appId, 'filters');
  const [rankingOptions] = useAppData<any>(appId, 'rankingOptions');
  const [footNoteItems] = useAppData<Array<string>>(appId, 'footnoteItems');
  const [workspaceId] = useAppData<string>(appId, 'workspaceId');
  const [searchType] = useAppData<SuggestionSearchMode>(appId, 'searchType');
  const [metadataTags] = useAppData<string[]>(appId, 'metadataTags');
  const [isLoadMore] = useAppData<boolean>(appId, 'isLoadMore');
  const [page] = useAppData<number>(appId, 'page');
  const userId: string = useAppSelector(state => state.global.appContext?.app_user_id as string);

  const isKeywordSearch = useMemo(() => searchType === SuggestionSearchMode.KEYWORD_SEARCH, [searchType]);

  const tagsIds: string[] = useMemo<Array<string>>(() => _.flatten(_.values(_.omit(filters, 'keywords'))), [filters]);
  const keywordsFilter: string = useMemo<string>(() => `${filters.keywords ?? ''}`.trim(), [filters]);

  const queryArgs = useMemo<SearchSuggestionRequest>(
    () =>
      ({
        appId,
        userId,
        keywordSearchText,
        searchText,
        workspaceId,
        excludeClauses: [],
        tagsIds,
        keywordsFilter,
        isBooleanSearch: isKeywordSearch,
      }) as SearchSuggestionRequest,
    [tagsIds, isKeywordSearch, appId, userId, keywordSearchText, searchText, workspaceId],
  );

  const queryRef = useRef<SearchSuggestionRequest>(queryArgs);

  const shouldRefetch = useMemo<boolean>(
    () => !_.isEqual(queryRef.current.tagsIds, tagsIds) || !_.isEqual(queryRef.current.keywordsFilter, keywordsFilter),
    [tagsIds, filters.keywords],
  );

  suggestionApi.useSearchSuggestionsQuery(queryArgs ?? skipToken, {
    skip: suggestions.length > 0 && !shouldRefetch,
    refetchOnMountOrArgChange: shouldRefetch,
  });

  useEffect(() => {
    queryRef.current = queryArgs;
  }, [queryArgs]);

  trigramsApi.useFetchTrigramsForSuggestionsQuery(
    { appId, clauses: suggestions, query: queryRef.current },
    { skip: suggestions.length <= 0, refetchOnReconnect: true },
  );

  const { data: unusualPhrases = {} } = trigramsApi.useGetTrigramsForAppQuery(appId, {
    selectFromResult: ({ data = [], ...rest }) => {
      const ids = suggestions.map(s => s.clause_id);
      const suggestionsUnusualPhrases = data.filter(u => ids.includes(u.id));
      return {
        ...rest,
        data: _.keyBy(suggestionsUnusualPhrases, 'id'),
      };
    },
    skip: isLoading || !suggestions.length,
  });

  const styles = useStyles();

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const { data: topics = [] } = WordDocumentApi.useFetchDocumentTopicsQuery();
  const { data: userBookmarks = [] } = bookmarksApi.useFetchUserBookmarksQuery(
    { appContext: { workspaceId } },
    {
      selectFromResult: ({ data, ...rest }) => {
        const ids = suggestions.map(s => s.clause_id);
        const bookmarkedSuggestions = data?.clauses?.filter(b => ids.includes(b)) || [];
        return {
          data: bookmarkedSuggestions,
          ...rest,
        };
      },
      skip: isLoading || !suggestions.length,
    },
  );
  const { data: userFavorites = [] } = favoritesApi.useFetchUserFavoritesQuery();

  const filteredSuggestions = useMemo(
    () =>
      isLoading
        ? []
        : filteredAndRankedByOptions(suggestions, rankingOptions, badSuggestions, topics, {
            bookmarks: userBookmarks,
            userFavorites: userFavorites,
          }),
    [isLoading, suggestions, rankingOptions, badSuggestions, userBookmarks, userFavorites, topics],
  );

  const searchKeywords = useMemo(() => searchText.split(' '), [searchText]);
  const filterKeywords = useMemo(() => getKeywords(filters?.keywords), [filters]);

  const { createContextApp } = useContextApp();

  const [asyncBookmarkClause] = bookmarksApi.useSaveBookmarkMutation();
  const [asyncFavoriteClause] = favoritesApi.useSetClauseFavoriteMutation();

  const [onLoadMoreSuggestions] = suggestionApi.useLoadMoreSuggestionsMutation();

  const onLoadMore = async () => {
    await onLoadMoreSuggestions({
      orignalQuery: { ...queryRef.current, excludeClauses: _.map(suggestions, 'clause_id') },
      pageCount: (page ?? 1) + 1,
    }).unwrap();
  };

  const onAcceptClause = (clause: IClause) => {
    setGoodSuggestions([...goodSuggestions, clause.clause_text]);
  };

  const onDeclineClause = (clause: IClause) => {
    setBadSuggestions([...badSuggestions, clause.clause_id]);
  };

  const onClauseDetailClicked = (clause: IClause) => {
    createContextApp(ApplicationType.CLAUSE_INFO_APP, {
      name: `Info - ${clause.clause_id}`,
      clause: clause,
      updatedText: clause.clause_text,
      selectedTab: 'documentInfo',
      workspaceId: workspaceId,
      similarClauseRedline: false,
      comments: [],
      similarClauses: [],
    } as IClauseInfoData);
  };

  const onClauseAddToFavorite = async (clause: IClause) => {
    try {
      await asyncFavoriteClause({
        id: clause.clause_id,
        isFavorite: true,
        appContext: { workspaceId },
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const onClauseBookmark = async (clause: IClause) => {
    try {
      await asyncBookmarkClause({
        bookmark: {
          relatedId: clause.clause_id,
          relatedObjectType: 'CLAUSE',
          bookmark: 'bookmark',
        },
        appContext: { workspaceId },
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const isClauseBookmarked = (clause: IClause) => userBookmarks.includes(clause.clause_id);

  const isClauseFavorite = (clause: IClause) => clause.is_favorite || userFavorites.includes(clause.clause_id);

  return (
    <LoadingContainer isLoading={isLoading} size="large" label="Searching for Results...." labelPosition="below">
      <Virtuoso
        ref={virtuosoRef}
        context={{
          loadMore: onLoadMore,
          loading: isLoadMore,
          appId,
          onAcceptClause,
          onDeclineClause,
          onClauseDetailClicked,
          onClauseAddToFavorite,
          onClauseBookmark,
          isClauseBookmarked,
          isClauseFavorite,
          searchText,
          searchKeywords,
          onOpenClauseDocument: props.onOpenDocument,
          highlightUnusualText,
          redlined,
          footNoteItems,
          unusualPhrases,
          filterKeywords,
          isKeywordSearch,
          goodSuggestions,
        }}
        style={{ width: '100%' }}
        data={filteredSuggestions}
        itemContent={OnRenderSuggestion}
        components={{
          Footer: SuggestionMoreLoader,
          EmptyPlaceholder: () => <div className={styles.placeholder}>No suggestion found.</div>,
        }}
      />
    </LoadingContainer>
  );
});

export default SuggestionList;
