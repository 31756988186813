import {
  Button,
  Checkbox,
  CheckboxProps,
  CounterBadge,
  Divider,
  Label,
  makeStyles,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  shorthands,
  Slider,
  SliderProps,
  Title3,
  tokens,
  Tooltip,
  useId,
} from '@fluentui/react-components';
import { Options24Regular } from '@fluentui/react-icons';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { DEFAULT_RANKING_OPTIONS } from '../../../constants';
import { useAppData } from '../../../hooks';

export type SuggestionRankingFormProps = {
  appId: string;
};
type RankingOptionType = {
  enabled: boolean;
  value: number;
  min: number;
  max: number;
  postfix: string;
};

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    rowGap: '24px',
    ...shorthands.overflow('hidden', 'auto'),
    ...shorthands.padding('16px', 0),
    boxSizing: 'border-box',
  },
  toolbarContainer: {
    flexGrow: 1,
    textAlign: 'center',
    boxSizing: 'border-box',
    width: '100%',
    ...shorthands.padding(0, '20px'),
  },
  formActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '16px',
    width: '100%',
    boxSizing: 'border-box',
    ...shorthands.padding(0, '20px'),
  },
  fullWidth: {
    minWidth: '100%',
  },
  formRow: {
    width: '100%',
    ...shorthands.flex(0, 0.8, 'auto'),
    ...shorthands.overflow('hidden', 'auto'),
  },
  filterForm: {
    ...shorthands.flex(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    minHeight: '60vh',
    width: '100%',
    boxSizing: 'border-box',
    ...shorthands.padding(0, '20px'),
    ...shorthands.overflow('hidden', 'auto'),
  },
  filterFormField: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    rowGap: '10px',
    boxSizing: 'border-box',
  },
  formActionBtn: {
    minWidth: '165px',
    fontWeight: 'bold',
  },
  wrapperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    flexDirection: 'row',
    columnGap: '10px',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifySelf: 'flex-end',
    boxSizing: 'border-box',
  },
  checkbox: {
    ...shorthands.flex(1, 0, '55%'),
    '&:hover': {
      '--fui-Checkbox__indicator--backgroundColor': '#6A467C',
    },
    '&:active': {
      '--fui-Checkbox__indicator--backgroundColor': '#6A467C',
    },
  },
  checkboxChecked: {
    '--fui-Checkbox__indicator--backgroundColor': '#6A467C',
  },
  slider: {
    '--fui-Slider__thumb--color': '#6A467C',
    '--fui-Slider__progress--color': '#6A467C',
    '&:hover': {
      '--fui-Slider__thumb--color': '#6A467C',
      '--fui-Slider__progress--color': '#6A467C',
    },
    '&:active': {
      '--fui-Slider__thumb--color': '#6A467C',
      '--fui-Slider__progress--color': '#6A467C',
    },
    width: '100%',
  },
  popOverTrigger: {
    position: 'relative',
    minWidth: '32px',
  },
  popOverTriggerActive: {
    zIndex: '1000',
    backgroundColor: 'white',
    ...shorthands.borderRadius('50%'),
  },
  popOverTriggerBadge: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  filterContainerSurface: {
    ...shorthands.padding(0),
    '@media screen and (max-width: 600px)': { maxWidth: '80vw', width: '80vw' },
    '@media screen and (min-width: 601px) and (max-width: 10000px)': {
      maxWidth: '430px',
      width: '430px',
    },
  },
});

const RenderRankingBooster = ({
  field,
  label,
  value,
  onChange,
}: {
  field: string;
  label: string;
  value: RankingOptionType;
  onChange?: (values: Record<string, any>) => void;
}) => {
  const onSliderChange: SliderProps['onChange'] = (_, data) => {
    onChange?.({ ...value, value: data.value });
  };

  const onCheckboxChange: CheckboxProps['onChange'] = (_, data) => {
    onChange?.({ ...value, enabled: data.checked as boolean });
  };

  const styles = useStyles();
  const id = useId();

  return (
    <div className={styles.wrapperContainer}>
      <Checkbox
        checked={value.enabled}
        label={label}
        className={mergeClasses(styles.checkbox, value.enabled ? styles.checkboxChecked : '')}
        onChange={onCheckboxChange}
      />
      <div className={styles.wrapper}>
        <Slider
          disabled={!value.enabled}
          className={styles.slider}
          min={value.min}
          max={value.max}
          value={value.value}
          id={id}
          onChange={onSliderChange}
        />
        <Label aria-hidden>
          {value.value}
          {value.postfix}
        </Label>
      </div>
    </div>
  );
};

const SuggestionRankingForm = React.memo(
  function SuggestionRankingForm({ appId }: SuggestionRankingFormProps) {
    const [storedRankingOptions, setStoredRankingOptions] = useAppData<Record<string, any>>(appId, 'rankingOptions');
    const [isRankingOpened, setIsRankinOpened] = useAppData<boolean>(appId, 'isRankingOpened');

    const [rankingOptions, setRankingOptions] = useState<Record<string, any>>(
      () => storedRankingOptions ?? DEFAULT_RANKING_OPTIONS,
    );

    const styles = useStyles();

    const rankCount = useMemo(
      () => _.sumBy(_.values(rankingOptions), ({ enabled }) => (enabled ? 1 : 0)),
      [rankingOptions],
    );

    const onUpdateSearchClick = () => {
      setIsRankinOpened(false);
      setStoredRankingOptions(rankingOptions);
    };

    const resetRankingToDefault = () => {
      setRankingOptions(DEFAULT_RANKING_OPTIONS);
    };

    const onRankingChange = (field: string, values: Record<string, any>) => {
      setRankingOptions({
        ...rankingOptions,
        [field]: values,
      });
    };

    return (
      <Popover
        open={isRankingOpened}
        onOpenChange={(_ev, data) => setIsRankinOpened(data.open as boolean)}
        inline
        size="large"
        withArrow
        positioning={'below-start'}
      >
        <PopoverTrigger disableButtonEnhancement>
          <Tooltip content="Adjust ranking of results" relationship="label" positioning="below">
            <Button
              appearance={'transparent'}
              icon={
                <>
                  {!isRankingOpened && rankCount !== 0 && (
                    <CounterBadge
                      iconPosition="after"
                      count={rankCount}
                      size="small"
                      appearance="filled"
                      className={styles.popOverTriggerBadge}
                      color="brand"
                      shape="circular"
                    />
                  )}
                  <Options24Regular color={isRankingOpened ? tokens.colorBrandBackground : ''} />
                </>
              }
              className={mergeClasses(styles.popOverTrigger, isRankingOpened ? styles.popOverTriggerActive : '')}
            ></Button>
          </Tooltip>
        </PopoverTrigger>
        <PopoverSurface className={styles.filterContainerSurface}>
          <div className={styles.formContainer}>
            <div className={styles.toolbarContainer}>
              <Title3>Adjust Ranking</Title3>
            </div>
            <div className={mergeClasses(styles.formRow, styles.filterForm)}>
              <div className={styles.filterFormField}>
                <Divider>Threshold for excluding results</Divider>
                <RenderRankingBooster
                  field="similarity_threshold"
                  label="Exclude low scoring results"
                  value={rankingOptions['similarity_threshold']}
                  onChange={values => onRankingChange('similarity_threshold', values)}
                />
              </div>

              <div className={styles.filterFormField}>
                <Divider>Adjust Weights for Contextual Factors</Divider>
                <RenderRankingBooster
                  field="topic_match"
                  label="Boost score for topics match"
                  value={rankingOptions['topic_match']}
                  onChange={values => onRankingChange('topic_match', values)}
                />

                <RenderRankingBooster
                  field="recency"
                  value={rankingOptions['recency']}
                  label="Boost score for recency"
                  onChange={values => onRankingChange('recency', values)}
                />
                <RenderRankingBooster
                  field="prevalence"
                  value={rankingOptions['prevalence']}
                  label="Boost score for prevalence"
                  onChange={values => onRankingChange('prevalence', values)}
                />
              </div>

              <div className={styles.filterFormField}>
                <Divider>Adjust Weights for Curated Content</Divider>
                <RenderRankingBooster
                  field="endorsed"
                  value={rankingOptions['endorsed']}
                  label="Boost score if endorsed"
                  onChange={values => onRankingChange('endorsed', values)}
                />
              </div>

              <div className={styles.filterFormField}>
                <Divider>Adjust Weights for User's Actions</Divider>
                <RenderRankingBooster
                  field="favorited"
                  value={rankingOptions['favorited']}
                  label="Boost score if favorited"
                  onChange={values => onRankingChange('favorited', values)}
                />
                <RenderRankingBooster
                  field="bookmarked"
                  value={rankingOptions['bookmarked']}
                  label="Boost score if bookmarked"
                  onChange={values => onRankingChange('bookmarked', values)}
                />
              </div>
            </div>
            <div className={mergeClasses(styles.formRow, styles.formActionContainer)}>
              <Button
                className={styles.formActionBtn}
                type="submit"
                shape="rounded"
                appearance="primary"
                onClick={onUpdateSearchClick}
              >
                Update Search
              </Button>
              <Button style={{ width: '100%' }} shape="rounded" appearance="outline" onClick={resetRankingToDefault}>
                Reset to default
              </Button>
            </div>
          </div>
        </PopoverSurface>
      </Popover>
    );
  },
  (_prev, _next) => false,
);

export default SuggestionRankingForm;
