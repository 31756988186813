import _ from 'lodash';
import { QueryBuilderContext } from '../../../enums';
import { SearchSuggestionRequest, SimilarSuggestionSearch } from '../../../models/requests';
import { textToSanitizedKeywordsText } from '../../../utils/helpers';
import SearchQueryBuilder from '../../../utils/searchQueryBuilder';

const generateRankingKeywords = (text: string): string[] => {
  const keywordSet = new Set<string>();
  const quoteReg = new RegExp(/"(.*?)"/, 'gi');
  const quotedTexts = Array.from(text.matchAll(quoteReg)).map(value => value[1]);
  const cleanKeywords = text
    .replace(quoteReg, ' ')
    .replace(/[^A-Za-z0-9 $]/gi, ' ')
    .replace(/(AND|OR)/g, ' ')
    .replace(/ +/g, ' ')
    .trim()
    .split(' ')
    .concat(quotedTexts)
    .filter(w => !!w.trim());

  cleanKeywords.forEach((w: string) => keywordSet.add(w.trim().toLowerCase()));
  return Array.from(keywordSet);
};

const genBoolRankingKeywords = (tsQuery: string): string[] => {
  const keywordSet = new Set<string>();
  // Remove negated words and group and wildcard words
  const negatedWordRegex = /!\w+/gi;
  const negatedBlockRegex = /!\((?:[^()]+|\([^()]+\){0,5}){0,5}\)/gi;
  const joinRegex = /(<->)|(\|)/gi;

  const words = tsQuery
    .replace(negatedWordRegex, '')
    .replace(negatedBlockRegex, '')
    .replace(/\w+\:\*/gi, '')
    .replace(joinRegex, '&')
    .replace(/(\()|(\))/gi, '')
    .trim()
    .split('&')
    .filter(w => !!w.trim());

  words.forEach((w: string) => keywordSet.add(w.trim().toLowerCase()));
  return Array.from(keywordSet);
};

export const suggestionRequestBuilder = (values: SearchSuggestionRequest) => {
  const queryBuilder = new SearchQueryBuilder(QueryBuilderContext.CLAUSE_SEARCH);
  const { userId, workspaceId } = values;
  const conditions = queryBuilder.buildQuery({
    ..._.omit(values, ['userId', 'workspaceId']),
  });

  const rankingKeywords = !values.isBooleanSearch
    ? generateRankingKeywords(textToSanitizedKeywordsText(values.searchText))
    : genBoolRankingKeywords(queryBuilder.getQueryField<string>(conditions, ['rule.field', 'keywords'], 'rule.value'));

  return {
    filter: {
      disableExternalTextSearch: values.isBooleanSearch,
      returnDocumentCount: true,
      limit: 100,
      conditions,
    },
    rankingOptions: {
      keywords: rankingKeywords,
      filterKeywords: values.keywordsFilter ?? '',
    },
    appContext: {
      userId,
      workspaceId,
    },
  };
};

export const similarSuggestionRequestBuilder = (values: SimilarSuggestionSearch) => {
  const queryBuilder = new SearchQueryBuilder(QueryBuilderContext.CLAUSE_SEARCH);
  const { workspaceId, userId } = values;
  const conditions = queryBuilder.buildQuery({
    searchText: values.searchText,
    excludeClauses: values.excludeIds,
  });

  const rankingKeywords = generateRankingKeywords(textToSanitizedKeywordsText(values.searchText));

  return {
    filter: {
      disableExternalTextSearch: false,
      limit: 50,
      conditions,
    },
    rankingOptions: {
      keywords: rankingKeywords,
    },
    appContext: {
      userId,
      workspaceId,
    },
  };
};
