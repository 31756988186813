import {
  Button,
  CompoundButton,
  Divider,
  MessageBar,
  MessageBarBody,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
  typographyStyles,
} from '@fluentui/react-components';
import { ChevronRight20Regular } from '@fluentui/react-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import { FormField } from '../../components/FormField';
import { LoadingContainer } from '../../components/Loading';
import { SSOIcon, SyntheiaLogo } from '../../components/icons';
import { signInSchema } from './signInSchema';
export type AuthenticationAppProps = {
  emailPlaceholder?: string;
  passwordPlaceholder?: string;
  isSSOEnabled?: boolean;
  customValidator?: (values: { username: string; password: string }) => void;
  onLoginSSO?: () => void;
  login: (username: string, password: string) => Promise<void>;
};

const useStyles = makeStyles({
  flexColumnStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexCentered: {
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
  },
  textUpperCase: {
    textTransform: 'uppercase',
  },
  fullWidth: {
    width: '100%',
  },
  authContainer: {
    minWidth: '300px',
    ...shorthands.border('0px'),
    rowGap: '15px',
  },
  logoContainer: {
    marginBottom: '50px',
  },
  headTitleContainer: {
    justifyItems: 'flex-start',
    rowGap: '5px',
  },
  headTitleSmall: {
    color: '#757575',
    fontSize: '12.80px',
    fontWeight: 400,
    wordWrap: 'break-word',
  },
  headTitleLarge: typographyStyles.title1,
  dividerContainer: {},
  optionDivider: {
    color: '#616161',
    fontSize: '12.80px',
    wordWrap: 'break-word',
    ...shorthands.margin('10px', '0px'),
  },
  formContainer: {
    rowGap: '17px',
  },
  inputFieldWrapper: {
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
    backgroundColor: '#F5F5F5',
  },
  inputFieldLabel: {
    fontSize: '10.24px',
    color: '#616161',
    lineHeight: '16.38px',
  },
  inputField: {
    ...shorthands.padding(0),
    ...shorthands.border('0px'),
    backgroundColor: 'transparent',
  },
  submitBtn: {
    fontSize: '12.80px',
    lineHeight: '22.53px',
    wordWrap: 'break-word',
    height: '55px',
    ...shorthands.borderRadius('8px'),
  },
  ssoBtn: {
    color: tokens.colorBrandBackground,
    height: '55px',
    textTransform: 'uppercase',
    justifyContent: 'center',
  },
  footNoteContainer: {
    marginTop: '15px',
  },
  footNote: typographyStyles.caption1,
});

export default function AuthenticationApp(props: AuthenticationAppProps) {
  const [errors, setErrors] = useState<string | undefined>(undefined);

  const methods = useForm({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    formState: { isSubmitting, isDirty },
  } = methods;

  const styles = useStyles();

  const onSubmit = async (values: InferType<typeof signInSchema>) => {
    await props.login(values.email, values.password).catch(error => {
      console.log(error);
      setErrors(error.message);
    });
  };

  const onSSOLoginClicked = (ev: React.MouseEvent) => {
    props.onLoginSSO?.();
  };

  return (
    <LoadingContainer
      className={mergeClasses(styles.flexColumnStyle, styles.authContainer)}
      isLoading={isSubmitting}
      size="extra-large"
      label={'LOADING'}
      labelPosition="below"
    >
      <FormProvider {...methods}>
        <div className={mergeClasses(styles.flexColumnStyle, styles.authContainer)}>
          {/* Logio Container */}
          <div
            className={mergeClasses(
              styles.flexColumnStyle,
              styles.fullWidth,
              styles.logoContainer,
              styles.flexCentered,
            )}
          >
            <SyntheiaLogo />
          </div>
          {/* Head title container */}
          <div className={mergeClasses(styles.flexColumnStyle, styles.headTitleContainer)}>
            <div>
              <span className={mergeClasses(styles.headTitleSmall, styles.textUpperCase)}>Welcome back</span>
            </div>
            <div>
              <span className={styles.headTitleLarge}>Sign into your account.</span>
            </div>
          </div>
          {props.isSSOEnabled && (
            <div className={styles.fullWidth}>
              <CompoundButton
                icon={<SSOIcon color={tokens.colorBrandBackground} />}
                shape="rounded"
                appearance="outline"
                size="large"
                onClick={onSSOLoginClicked}
                className={mergeClasses(styles.fullWidth, styles.ssoBtn)}
              >
                Log In with SSO
              </CompoundButton>
            </div>
          )}
          {/* Divider */}
          <div className={styles.dividerContainer}>
            <Divider className={styles.optionDivider}>{props.isSSOEnabled ? 'Or' : ''} Use Email</Divider>
          </div>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={mergeClasses(styles.flexColumnStyle, styles.formContainer)}
          >
            <FormField name="email" label={'email'} placeholder={props.emailPlaceholder || 'johndoe@email.com'} />
            <FormField
              name="password"
              label={'password'}
              type="password"
              placeholder={props.passwordPlaceholder || '••••••••••••••••'}
            />
            {errors && (
              <MessageBar layout="multiline" intent="error" shape="rounded">
                <MessageBarBody>
                  <span>{errors}</span>
                </MessageBarBody>
              </MessageBar>
            )}
            <div className={styles.fullWidth}>
              <Button
                type="submit"
                shape="rounded"
                appearance="primary"
                className={mergeClasses(styles.fullWidth, styles.submitBtn)}
                disabled={!isDirty}
              >
                <span className={styles.textUpperCase}>Continue</span> <ChevronRight20Regular></ChevronRight20Regular>
              </Button>
            </div>
          </form>
          <div
            className={mergeClasses(
              styles.flexColumnStyle,
              styles.fullWidth,
              styles.flexCentered,
              styles.footNoteContainer,
            )}
          >
            <div>
              <span className={styles.footNote}>Need help or forgot your password?</span>
            </div>
            <div>
              <span className={styles.footNote}>Please contact your system administrator.</span>
            </div>
          </div>
        </div>
      </FormProvider>
    </LoadingContainer>
  );
}
