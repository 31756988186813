import { Divider, mergeClasses, Textarea } from '@fluentui/react-components';
import { useCallback, useMemo, useRef, useState } from 'react';
import DocumentReader from '../../components/DocumentReader';
import { SuggestionSearchMode } from '../../enums';
import { useAppData } from '../../hooks';
import { IClause, IDocument } from '../../interfaces';
import { WorkspaceApi } from '../../services';
import SuggestionList from './components/SuggestionList';
import SuggestionToolbar from './components/SuggestionToolbar';
import { useStyles } from './styles';

export type SuggestionResultProps = {
  appId: string;
};

export const SuggestionResult = ({ appId }: SuggestionResultProps) => {
  const [searchText] = useAppData<string>(appId, 'searchText');
  const [searchType] = useAppData<SuggestionSearchMode>(appId, 'searchType');
  const [isFilterOpened] = useAppData<boolean>(appId, 'isFilterOpened');
  const [isRankingOpened] = useAppData<boolean>(appId, 'isRankingOpened');

  const [openDocumentViewer, setOpenDocumentViewer] = useState<boolean>(false);
  const documentViewerRef = useRef<{ clause?: IClause; document: IDocument }>();

  const documentViewerButtonRef = useRef<HTMLButtonElement>(null);
  const mountNodeRef = useRef<HTMLDivElement>(null);

  const styles = useStyles();

  const onOpenClauseDocument = useCallback((document: IDocument, clause?: IClause) => {
    documentViewerRef.current = { document, clause };
    setOpenDocumentViewer(true);
    documentViewerButtonRef.current?.click();
  }, []);

  const { data: workspaces = [] } = WorkspaceApi.useGetWorkspacesQuery();

  const workspaceDict = useMemo(() => {
    const dict: Record<string, string> = {};

    workspaces.forEach(w => {
      dict[w.workspaceId] = w.name;
    });

    return dict;
  }, [workspaces]);

  const [workspaceId] = useAppData<string>(appId, 'workspaceId');

  return (
    <div className={styles.container} ref={mountNodeRef} key={appId}>
      <div
        className={mergeClasses(
          styles.overlay,
          !isFilterOpened && !isRankingOpened && !openDocumentViewer ? styles.displayNone : '',
        )}
      ></div>
      <DocumentReader
        mountNode={mountNodeRef.current}
        document={documentViewerRef.current?.document!}
        textBlockId={documentViewerRef.current?.clause?.clause_id}
        textBlock={documentViewerRef.current?.clause?.clause_text}
        trigger={<button style={{ visibility: 'hidden' }} ref={documentViewerButtonRef}></button>}
        onClose={() => setOpenDocumentViewer(false)}
      />
      {/* Control toolbar */}
      <SuggestionToolbar appId={appId} />
      {/* Search Terms area */}
      <div className={styles.searchTextPlaceholderContainer}>
        <div className={styles.dividerContainer}>
          <Divider>
            <span>
              {searchType === SuggestionSearchMode.KEYWORD_SEARCH ? 'Boolean Search (Beta) ' : 'Smart Search'} in{' '}
              {workspaceDict[workspaceId]}
            </span>
          </Divider>
        </div>

        <Textarea
          className={styles.searchTextPlaceholder}
          disabled
          placeholder="Search terms"
          resize="none"
          style={{ minHeight: '96px' }}
          value={searchText}
        />
      </div>

      {/* Suggestions */}
      <div className={styles.suggestionListContainer}>
        <div className={styles.dividerContainer}>
          <Divider>
            <span className={styles.dividerText}>Results</span>
          </Divider>
        </div>
        <SuggestionList appId={appId} onOpenDocument={onOpenClauseDocument} />
      </div>
    </div>
  );
};

export default SuggestionResult;
