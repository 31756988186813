import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { GetWorkspacesResponse, Workspace } from '../../models/responses/workspaces';
import { axiosBaseQuery } from './axiosConfig';

const transformGetWorkspaces = ({ workspaces = [] }: GetWorkspacesResponse): Workspace[] => {
  return _.orderBy(workspaces, [w => w.name.toLowerCase()], ['asc']);
};

export const WorkspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: 'v3/workspaces',
  }),
  endpoints: build => ({
    getWorkspaces: build.query<Workspace[], void>({
      query: () => ({ url: '', method: 'GET' }),
      transformResponse: transformGetWorkspaces,
    }),
  }),
});
