import { Card, Switch, SwitchOnChangeData, Tooltip } from '@fluentui/react-components';
import React, { useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import ClauseCard from '../../../../../libs/components/clause-card/ClauseCard';
import { ClauseCardMode } from '../../../../../libs/enums';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import { LoadingContainer } from '../../../../components/Loading';
import { IClause, IDocument } from '../../../../interfaces';
import { UnusualTextPhrases } from '../../../../models/responses/suggestion';

type SimilarClauseRendererProps = {
  isLoading?: boolean;
  similarClauses: Array<IClause>;
  originalText: string;
  shouldRedline?: boolean;
  unusualPhrases?: Record<string, UnusualTextPhrases>;
  onSimilarClauseRedline?: (enabled: boolean) => void;
  onSimilarClauseDetail?: (clause: IClause) => void;
  onOpenDocumentViewer: (document: IDocument, clause?: IClause) => void;
  onBookmarkClause?: (clause: IClause) => Promise<void>;
  onClauseFavorite?: (clause: IClause) => Promise<void>;
};

const onRenderSuggestion = (idx: number, clause: IClause, context: any) => {
  const {
    originalText,
    highlightUnusualText,
    unusualPhrases,
    shouldRedlineSimilarClause,
    onSimilarClauseDetail,
    onOpenDocumentViewer,
    onBookmarkClause,
    onClauseFavorite,
  } = context;

  return (
    <div key={idx} style={{ paddingRight: '5px', paddingLeft: '1px', marginBottom: '15px' }}>
      <ClauseCard
        footNoteItems={['relevancy', 'documents']}
        key={clause.clause_id}
        mode={ClauseCardMode.BROWSE}
        clause={clause}
        searchText={originalText}
        unusualTextHighlightEnabled={highlightUnusualText}
        unusualPhrases={unusualPhrases?.[clause.clause_id]}
        readlineEnabled={shouldRedlineSimilarClause}
        onClauseDetailClicked={() => onSimilarClauseDetail?.(clause)}
        onOpenDocument={onOpenDocumentViewer}
        onBookmarkClick={onBookmarkClause}
        onAddFavoriteClick={onClauseFavorite}
      />
    </div>
  );
};

export default React.memo(
  ({
    similarClauses,
    shouldRedline,
    originalText,
    isLoading,
    unusualPhrases,
    onBookmarkClause,
    onClauseFavorite,
    onSimilarClauseRedline,
    onSimilarClauseDetail,
    onOpenDocumentViewer,
  }: SimilarClauseRendererProps) => {
    const [shouldRedlineSimilarClause, setShouldRedlineSimilarClause] = useState<boolean>(() => !!shouldRedline);
    const [highlightUnusualText, setHighlightUnusualText] = useState<boolean>(false);

    const virtuosoRef = useRef<VirtuosoHandle>(null);

    const onToggleSimilarRedline = (ev: React.ChangeEvent, data: SwitchOnChangeData) => {
      setShouldRedlineSimilarClause(data.checked as boolean);
      onSimilarClauseRedline?.(data.checked as boolean);
    };

    return (
      <LoadingContainer
        isLoading={Boolean(isLoading)}
        size="large"
        label="Loading similar clauses"
        labelPosition="below"
      >
        {similarClauses.length > 0 && (
          <Card
            appearance="subtle"
            style={{
              width: '100%',
              backgroundColor: 'transparent',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
              columnGap: '6px',
              justifyContent: 'flex-start',
              alignContent: 'center',
            }}
            key={'similarClausesRedlineSwitch'}
          >
            {/* TODO: This needs to refactored into one UI component with the one in the SuggestionToolbar */}

            <Tooltip content="Compare your search input against each result" relationship="label">
              <div>
                <Switch label="Redline" checked={shouldRedlineSimilarClause} onChange={onToggleSimilarRedline} />
              </div>
            </Tooltip>

            <Tooltip content="Highlight statistically anomalous language in the results" relationship="label">
              <div>
                <Switch
                  checked={highlightUnusualText}
                  label="Highlight Unusual Text"
                  onChange={(_ev, data) => setHighlightUnusualText(data.checked)}
                />
              </div>
            </Tooltip>
          </Card>
        )}
        <Virtuoso
          ref={virtuosoRef}
          data={similarClauses}
          context={{
            originalText,
            highlightUnusualText,
            unusualPhrases,
            shouldRedlineSimilarClause,
            onBookmarkClause,
            onClauseFavorite,
            onSimilarClauseDetail,
            onOpenDocumentViewer,
          }}
          style={{ margin: '0 10px' }}
          followOutput={(isBottom: boolean) => (isBottom ? 'smooth' : false)}
          itemContent={onRenderSuggestion}
          components={{
            EmptyPlaceholder: () => <EmptyPlaceholder text="No Similar Clause found" />,
          }}
        />
      </LoadingContainer>
    );
  },
);
