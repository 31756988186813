import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    ...shorthands.overflow('hidden', 'hidden'),
  },
  commentListContainer: {
    width: '100%',
    maxHeight: '90%',
    ...shorthands.overflow('hidden', 'auto'),
  },
  h70: {
    maxHeight: '70%',
  },
  h90: {
    maxHeight: '90%',
  },
  commentList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyItems: 'center',
    rowGap: '15px',
  },
  commentEditor: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
  commentSubmit: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'stretch',
    flexWrap: 'nowrap',
    columnGap: '15px',
  },
});
