import { createApi } from '@reduxjs/toolkit/query/react';
import { ToggleFavoriteRequest } from '../../models/requests';
import { axiosBaseQuery } from './axiosConfig';

const setClauseFavoriteQuery = (args: ToggleFavoriteRequest) => ({
  url: `/${args.id}`,
  method: 'PATCH',
  data: args,
});

export const favoritesApi = createApi({
  reducerPath: 'favorites',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'v3/clauses',
  }),
  endpoints: build => ({
    fetchUserFavorites: build.query<string[], void>({
      queryFn: async args => {
        return { data: [] };
      },
    }),
    setClauseFavorite: build.mutation<void, ToggleFavoriteRequest>({
      query: setClauseFavoriteQuery,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const optimisticUpdate = dispatch(
          favoritesApi.util.updateQueryData('fetchUserFavorites', undefined, savedData => {
            savedData.push(args.id);
          }),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          console.error(err);
          optimisticUpdate.undo();
        }
      },
    }),
  }),
});
