import { Button, makeStyles, mergeClasses, Switch, SwitchOnChangeData, Tooltip } from '@fluentui/react-components';
import { SlideTextSparkle32Regular } from '@fluentui/react-icons';
import React from 'react';
import { useAppData } from '../../../hooks';
import SuggestionFilterForm from './SuggestionFilterForm';
import SuggestionFootnoteMenu from './SuggestionFootnoteMenu';
import SuggestionRankingForm from './SuggestionRankingForm';

type SuggestionToolbarProps = {
  appId: string;
};

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    height: '48px',
  },
  toolbarToggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '6px',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  toolbarToggleLabel: {
    color: '#242424',
    fontSize: '14px',
    lineHeight: '20px',
    wordWrap: 'break-word',
  },
  toggleActive: {
    fontWeight: 600,
  },
});

const SuggestionToolbar = React.memo(function SuggestionToolbar({ appId }: SuggestionToolbarProps) {
  const [redlined, setRedlined] = useAppData<boolean>(appId, 'redlined');
  const [highlightUnusualText, setHighlightUnusualText] = useAppData<boolean>(appId, 'hightlightUnusualText');

  const styles = useStyles();

  const ontoggleRedlining = (_ev: React.ChangeEvent, data: SwitchOnChangeData) => {
    if (data.checked) {
      setHighlightUnusualText(false);
    }
    setRedlined(data.checked as boolean);
  };

  const onToggleUnusualTextHighlight = (_ev: React.ChangeEvent, data: SwitchOnChangeData) => {
    if (data.checked) {
      setRedlined(false);
    }
    setHighlightUnusualText(data.checked as boolean);
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarToggleContainer}>
        <Tooltip content="Compare your search input against each result" relationship="label">
          <div>
            <Switch
              checked={redlined}
              label={
                <span className={mergeClasses(styles.toolbarToggleLabel, redlined ? styles.toggleActive : '')}>
                  Redline
                </span>
              }
              onChange={ontoggleRedlining}
            />
          </div>
        </Tooltip>

        <Tooltip content="Highlight statistically anomalous language in the results" relationship="label">
          <div>
            <Switch
              checked={highlightUnusualText}
              label={
                <span
                  className={mergeClasses(styles.toolbarToggleLabel, highlightUnusualText ? styles.toggleActive : '')}
                >
                  Highlight Unusual Text
                </span>
              }
              onChange={onToggleUnusualTextHighlight}
            />
          </div>
        </Tooltip>
      </div>

      <div>
        <SuggestionFootnoteMenu appId={appId}>
          <Tooltip content="Change displayed metadata" relationship="label" positioning="below">
            <Button appearance="transparent" icon={<SlideTextSparkle32Regular />} />
          </Tooltip>
        </SuggestionFootnoteMenu>
        <SuggestionRankingForm appId={appId} />
        <SuggestionFilterForm appId={appId} />
      </div>
    </div>
  );
});

export default SuggestionToolbar;
