import { ToastIntent, useId, useToastController } from '@fluentui/react-components';
import React, { useCallback } from 'react';

export default function useToastNotification() {
  const toasterId = useId('notification-toaster');
  const { dispatchToast, dismissToast } = useToastController(toasterId);

  const notify = useCallback(
    (toastEl: React.ReactNode, intent: ToastIntent) => {
      dispatchToast(toastEl, { intent, politeness: intent === 'error' ? 'assertive' : 'polite' });
    },
    [dispatchToast],
  );

  const dismissNotification = useCallback(() => {
    dismissToast(toasterId);
  }, [dismissToast, toasterId]);

  return { toasterId, notify, dismissNotification };
}
