import React from 'react';

export default function AnomaliesAppIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.08333 1H32.9167C36.8333 1 40 4.24786 40 8.26496V33.735C40 37.7521 36.8333 41 32.9167 41H7.08333C3.16667 41 0 37.7521 0 33.735V8.26496C0 4.24786 3.16667 1 7.08333 1Z"
        fill="#46740C"
      />
      <path
        d="M22.4 28L21 26.6L23.6 24L21 21.4L22.4 20L25 22.6L27.6 20L29 21.4L26.4 24L29 26.6L27.6 28L25 25.4L22.4 28ZM24.375 19L20.825 15.45L22.225 14.05L24.35 16.175L28.6 11.925L30 13.35L24.375 19ZM10 25V23H19V25H10ZM10 17V15H19V17H10Z"
        fill="white"
      />
    </svg>
  );
}
