import { Caption1, CompoundButton, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { Folder24Regular } from '@fluentui/react-icons';
import React, { useCallback } from 'react';
import { ApplicationType } from '../../enums';
import {
  AnomaliesAppIcon,
  BrowseAppIcon,
  ChecklistAppIcon,
  DocumentAppIcon,
  SettingsAppIcon,
  SuggestionAppIcon,
} from '../icons';

export type AppListItemProps = {
  type: ApplicationType;
  name: string;
  logo?: any;
  className?: string;
  description: string;
  inactive?: boolean;
  onClick?: (type: ApplicationType) => void;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: '5px',
    height: 'auto',
    width: 'auto',
    justifyContent: 'center',
    alignContent: 'center',
    justifyItems: 'center',
  },
  appBtn: {
    width: '100%',
    alignSelf: 'center',
    ...shorthands.border('none'),
  },
  inactive: {
    opacity: 0.4,
    filter: 'alpha(opacity=40)',
  },
});

export default function AppListItem(props: AppListItemProps) {
  const styles = useStyles();

  const onAppClick: React.MouseEventHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    props.onClick?.(props.type);
  };

  const renderAppLogo = useCallback(() => {
    switch (props.type) {
      case ApplicationType.DOCUMENT_LIBRARY_APP:
        return <DocumentAppIcon className={props.inactive ? styles.inactive : ''} />;
      case ApplicationType.BROWSE_APP:
        return <BrowseAppIcon className={props.inactive ? styles.inactive : ''} />;
      case ApplicationType.ANOMALY_DETECTION_APP:
        return <AnomaliesAppIcon className={props.inactive ? styles.inactive : ''} />;
      case ApplicationType.CHECKLIST_APP:
        return (
          <ChecklistAppIcon
            type={props.name === 'Create Tasklist' ? 'add' : 'list'}
            className={props.inactive ? styles.inactive : ''}
          />
        );
      case ApplicationType.SUGGESTION_APP:
        return (
          <SuggestionAppIcon
            height={'34'}
            width={'34'}
            color={'#fff'}
            className={props.inactive ? styles.inactive : ''}
          />
        );
      case ApplicationType.SETTINGS_APP:
        return <SettingsAppIcon className={props.inactive ? styles.inactive : ''} />;
      default:
        return (props.logo && <span>{props.logo}</span>) || <Folder24Regular />;
    }
  }, [props, styles.inactive]);

  return (
    <div className={mergeClasses(styles.container, props.className)}>
      <CompoundButton
        icon={renderAppLogo()}
        onClick={onAppClick}
        shape="rounded"
        className={styles.appBtn}
        disabled={props.inactive}
      ></CompoundButton>
      <Caption1 style={{ textWrap: 'nowrap' }}>{props.name}</Caption1>
    </div>
  );
}
