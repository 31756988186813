import Dexie, { Table } from 'dexie';
import { SuggestionSearchMode } from '../enums';

export type SearchHistory = {
  id?: number;
  userId: number | string;
  workspaceId: string;
  workspaceName: string;
  searchText: string;
  keywordSearchText: string;
  tags: Array<string>;
  searchType: SuggestionSearchMode;
  searchTypeName: string;
  timestamps: string | Date;
};

export type AnomalyHistory = {
  id?: number;
  userId: number | string;
  workspaceId: number | string;
};

export class LocalDbStore extends Dexie {
  // Search History Table
  histories!: Table<SearchHistory>;

  anomalies!: Table<AnomalyHistory>;

  constructor(dbName: string) {
    super(dbName);
    this.version(2).stores({
      histories: '++id, userId, workspaceId',
      anomalies: '++id, userId, workspaceId',
    });
  }
}

export const localDbStore = new LocalDbStore('smart_drafter_db');
