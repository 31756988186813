import { ComponentType } from 'react';
import { AnomaliesPage } from '../enums';
import AnomaliesForm from '../pages/AnomaliesForm';
import AnomaliesHome from '../pages/AnomaliesHome';
import AnomaliesReport from '../pages/AnomaliesReport';
import { AppComponentProps } from '../types/AppComponentProps';

export const AnomaliesPageComponents: Record<AnomaliesPage, ComponentType<AppComponentProps>> = {
  [AnomaliesPage.ANOMALIES_HOME]: AnomaliesHome,
  [AnomaliesPage.ANOMALIES_FORM]: AnomaliesForm,
  [AnomaliesPage.ANOMALIES_REPORT]: AnomaliesReport,
};
