import {
  Body1Strong,
  Image,
  makeStyles,
  mergeClasses,
  shorthands,
  Spinner,
  SpinnerProps,
} from '@fluentui/react-components';
import { ReactNode, useEffect, useState } from 'react';
import { SyntheiaSmallLogo } from '../icons/images';

type LoadingProps = SpinnerProps & {
  className?: string;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    ...shorthands.overflow('hidden'),
    justifyContent: 'center',
    alignContent: 'center',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '36px',
    minHeight: '100vh',
    minWidth: '100vw',
    ...shorthands.overflow('hidden'),
    justifyContent: 'center',
    alignItems: 'center',
  },
  vBouncing: {
    animationName: [
      {
        from: { transform: 'translateY(0)' },
        to: { transform: 'translateY(30px)' },
      },
    ],
    animationDuration: '0.5s',
    animationDirection: 'alternate',
    animationIterationCount: 'infinite',
  },
});

export const Loading = ({ className, ...props }: LoadingProps) => {
  const styles = useStyles();

  return (
    <div className={mergeClasses(styles.container, className ?? '')}>
      <Spinner appearance="primary" {...props} />
    </div>
  );
};

type LoadingContainerProps = LoadingProps & {
  isLoading: boolean;
  children: ReactNode;
};

export const LoadingContainer = ({ isLoading, children, ...props }: LoadingContainerProps) => {
  if (!isLoading) return <>{children}</>;
  return <Loading {...props} />;
};

type AppLoadingContainerProps = LoadingContainerProps & {
  iconOnly?: boolean;
};

export const AppLoadingContainer = ({ isLoading, iconOnly, children, ...props }: AppLoadingContainerProps) => {
  const [index, setIndex] = useState<number>(() => 0);
  const phrases = [
    'Retrieving metadata...',
    'Analyzing document...',
    'Configuring personalization...',
    'Finalizing...',
  ];

  const styles = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      if (index >= phrases.length) {
        clearInterval(interval);
      } else {
        setIndex(index + 1);
      }
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  if (!isLoading) return <>{children}</>;

  return (
    <div className={mergeClasses(styles.loadingContainer)}>
      <Image src={SyntheiaSmallLogo} alt="Loading..." height="128" width="128" className={styles.vBouncing} />
      {!iconOnly && <Body1Strong className="loadingText">{phrases[index]}</Body1Strong>}
    </div>
  );
};

export default Loading;
