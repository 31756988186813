import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import store, { RootState } from '../../store';
import { setAppContext } from '../../store/slices/globalSlice';

const baseURL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : `${window.location.protocol}//${window.location.hostname.replace(/^[^.]+\./, 'api.')}`;

const axiosClient = axios.create({
  baseURL,
  withCredentials: true,
});

export const setupAxiosRequestInterceptors = (getState: () => RootState) => {
  axiosClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const currentState = getState();

      const currentStateAppContext = currentState.global.appContext;
      if (currentStateAppContext) {
        const appContext = {
          app_user_id: currentStateAppContext.app_user_id,
          workspace: currentStateAppContext.workspace_id,
        };

        if (config.data) {
          if (!config.data?.appContext) {
            config.data = {
              ...config.data,
              appContext,
            };
          }
        } else {
          config.data = { appContext };
        }
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
};

interface ErrorResponseFormat {
  Error: string;
  ErrorType: string;
}

const isErrorResponse = (response: any): response is ErrorResponseFormat => {
  return response && typeof response.Error === 'string' && typeof response.ErrorType === 'string';
};

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // Check if the response is an error in disguise
    if (response.status === 200 && isErrorResponse(response.data)) {
      const errorResponse: ErrorResponseFormat = response.data;
      if (errorResponse.Error === 'Not logged in') {
        store.dispatch(setAppContext(null));
      } else {
        throw new AxiosError(errorResponse.Error, errorResponse.ErrorType, response.config, response.request, response);
      }
    }
    return response;
  },
  error => {
    if (error.response?.data?.error?.message.toLowerCase() === 'not logged in') {
      store.dispatch(setAppContext(null));
    }
    return Promise.reject(error);
  },
);

const getErrorMessage = (error: AxiosError) => {
  switch (error.response?.status) {
    case 500:
      return 'something went wrong';
    default:
      return error.message;
  }
};

const requestAppContext = (context: any, data: any, baseUrl: string, url?: string) => {
  if (!context) {
    return null;
  }
  const workspaceId =
    data?.workspaceId ||
    data?.workspace_id ||
    data?.appContext?.workspaceId ||
    data?.appContext?.workspace_id ||
    context.workspace_id;
  const userId = context.app_user_id;
  if (baseUrl === 'drafting' || (url && url === '/setClauseFavorite')) {
    const workspaceKey =
      data?.workspaceId || data?.workspace_id || data?.appContext?.workspaceId || data?.appContext?.workspace_id
        ? 'workspace_id'
        : 'workspace';
    return { app_user_id: userId, [workspaceKey]: workspaceId };
  }
  return { userId, workspaceId };
};

export type QueryArg = AxiosRequestConfig & {
  workspaceId?: string;
  includeAppContext?: boolean;
};

export const axiosBaseQuery =
  <
    ResultType = unknown, // Generic type for the API response
    ErrorType = unknown, // Generic type for the error
  >(
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<QueryArg, ResultType, ErrorType> =>
  async ({ url, method, data, params, includeAppContext = true, workspaceId, ...rest }, api) => {
    try {
      const {
        global: { appContext: context },
      } = api.getState() as any;
      const requestData = method === 'GET' ? { ...(params || {}) } : { ...(data || {}) };
      const appContext = requestAppContext(context, requestData, baseUrl, url);
      if (appContext && requestData && includeAppContext) {
        requestData.appContext = appContext;
      }
      const result = await axiosClient<ResultType>({
        url: baseUrl + url,
        method,
        data: method !== 'GET' ? requestData : data,
        params: method === 'GET' ? requestData : params,
        ...rest,
      });
      return { data: result.data, api };
    } catch (error) {
      let axiosError = error as AxiosError<ErrorType>;
      axiosError.message = getErrorMessage(axiosError);
      return { error: axiosError as ErrorType, api };
    }
  };

export default axiosClient;
