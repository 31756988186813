import { createSlice } from '@reduxjs/toolkit';
import { AppContext } from '../../models/appContext';

type GobalState = {
  appContext: AppContext | null;
  ssoEnabled: boolean;
  ssoFallbackURL: string;
};

const initialState: GobalState = {
  appContext: null,
  ssoEnabled: true,
  ssoFallbackURL: '',
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAppContext: (state, action) => {
      state.appContext = action.payload;
    },
    setSSOEnabled: (state, action) => {
      state.ssoEnabled = action.payload;
    },
    setSSOFallbackURL: (state, action) => {
      state.ssoFallbackURL = action.payload;
    },
    logout: state => initialState,
  },
});

export const { setAppContext, setSSOEnabled, setSSOFallbackURL, logout } = globalSlice.actions;

export default globalSlice.reducer;
