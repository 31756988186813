import {
  Button,
  Caption1,
  Card,
  CardHeader,
  makeStyles,
  shorthands,
  Tag,
  Text,
  tokens,
} from '@fluentui/react-components';
import moment from 'moment';
import { SearchHistory } from '../../store/LocalDbStore';

export type SearchHistoryCardProps = {
  history: SearchHistory;
  onChangeSearch: (history: any) => void;
};

const useStyles = makeStyles({
  historyCard: {
    minHeight: '105px',
    backgroundColor: '#eeeeee',
    color: '#777777',
    marginBottom: '15px',
    ':hover': {
      backgroundColor: 'white',
      '& *': {
        color: 'black',
      },
    },
  },
  historyCardAction: {
    color: tokens.colorBrandBackground,
    textWrap: 'nowrap',
    ':hover': {
      backgroundColor: tokens.colorBrandBackground2,
      color: tokens.colorBrandBackground,
    },
  },
  tagNavy: {
    color: '#777777',
    ...shorthands.borderColor(tokens.colorPaletteRoyalBlueForeground2),
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
});

export default function SearchHistoryCard({ history, onChangeSearch }: SearchHistoryCardProps) {
  const styles = useStyles();

  return (
    <Card appearance="outline" className={styles.historyCard}>
      <CardHeader
        header={
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '15px' }}>
            <Tag size="small" appearance="outline" className={styles.tagNavy}>
              <b>Type:</b> {history.searchTypeName}
            </Tag>
            <Tag size="small" appearance="outline" className={styles.tagNavy}>
              <b>Workspace:</b> {history.workspaceName}
            </Tag>
          </div>
        }
      ></CardHeader>
      <Text wrap={false} weight="regular" style={{ textOverflow: 'ellipsis' }}>
        <b>Search Terms: </b>
        {history.searchText}
      </Text>

      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Caption1 italic>{moment(history.timestamps).calendar()}</Caption1>

        <div style={{ display: 'flex' }}>
          <Button
            appearance="subtle"
            className={styles.historyCardAction}
            onClick={() => onChangeSearch(history)}
            size="small"
          >
            Search Again
          </Button>
        </div>
      </div>
    </Card>
  );
}
