import React from 'react';
import { SuggestionAppPage } from '../enums';
import SuggestionResult from '../pages/SuggestionResult';
import SuggestionSearchForm from '../pages/SuggestionSearchForm';

type SuggestionAppPageType = Omit<SuggestionAppPage, SuggestionAppPage.SUGGESTION_FILTER>;

type ComponentType<T extends SuggestionAppPageType> = T extends SuggestionAppPage.SEARCH_SUGGESTION
  ? React.ElementType<typeof SuggestionSearchForm>
  : T extends SuggestionAppPage.SUGGESTION_RESULT
    ? React.ElementType<typeof SuggestionResult>
    : React.ElementType;

type GenericSuggestionAppType<
  C extends React.ElementType = ComponentType<
    SuggestionAppPage.SEARCH_SUGGESTION | SuggestionAppPage.SUGGESTION_RESULT
  >,
> = {
  as: C;
} & React.ComponentProps<C>;

export default function GenericSuggestionApp<T extends SuggestionAppPageType>({
  as,
  children,
  ...props
}: GenericSuggestionAppType<ComponentType<T>>) {
  const Component = as || 'div';

  return <Component {...props}>{children}</Component>;
}
