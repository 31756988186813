import {
  Button,
  MessageBar as FluentMessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarIntent,
  MessageBarTitle,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { IError } from '../../interfaces';

export type MessageBarProps = {
  intent: MessageBarIntent;
  layout?: 'multiline' | 'singleline' | 'auto';
  error?: IError;
  onDismiss?: () => void;
};

export default function MessageBar(props: MessageBarProps) {
  return (
    <FluentMessageBar shape="rounded" intent={props.intent} layout={props.layout ?? 'singleline'}>
      <MessageBarBody>
        <MessageBarTitle style={{ textTransform: 'capitalize' }}>{props.error?.title ?? props.intent}:</MessageBarTitle>
        {props.error?.message}
      </MessageBarBody>
      <MessageBarActions
        containerAction={
          props.onDismiss && <Button appearance="transparent" icon={<DismissRegular />} onClick={props.onDismiss} />
        }
      />
    </FluentMessageBar>
  );
}
