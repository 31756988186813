import {
  Body1,
  Card,
  CardHeader,
  InteractionTag,
  InteractionTagPrimary,
  makeStyles,
  shorthands,
  Subtitle2Stronger,
  Tag,
} from '@fluentui/react-components';
import { OpenRegular } from '@fluentui/react-icons';
import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { IClause, IDocument } from '../../interfaces';

export type ClauseDocumentInfoProps = {
  clause: IClause;
  onOpenDocument?: (document: IDocument) => void;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    ...shorthands.overflow('hidden', 'auto'),
  },
  fieldItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: '5px',
    rowGap: '5px',
    flexWrap: 'wrap',
    width: '100%',
  },
});

const RenderTagListing = React.memo(
  ({ tags, className }: { tags: Array<{ id: string; name: string }>; className: string }) => {
    return (
      <Body1 className={className}>
        {tags.map((tag: any) => (
          <Tag size="small" key={tag.id} appearance="outline">
            {tag.name}
          </Tag>
        ))}
      </Body1>
    );
  },
);

const onRenderMetadata = (idx: number, data: [string, Array<{ id: string; name: string }>], context: any) => {
  const [label, tags] = data;
  const { className } = context;
  return (
    <Card appearance="subtle" key={idx}>
      <CardHeader
        header={
          <Subtitle2Stronger style={{ flex: '0 0 auto', display: 'inline-block' }}>
            {pluralize(_.startCase(label))}
          </Subtitle2Stronger>
        }
      />
      <RenderTagListing tags={tags} className={className} />
    </Card>
  );
};

export default function ClauseDocumentInfo(props: ClauseDocumentInfoProps) {
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const mappedClauseTags = _.reduce(
    props.clause.documents ?? [],
    (prev, doc) => {
      _.forEach(_.keys(doc.tags), k => {
        if (prev[k]) {
          prev[k] = _.uniqBy(_.concat(prev[k], doc.tags![k]?.tags), 'id');
        } else {
          prev[k] = doc.tags![k]?.tags;
        }
      });
      return prev;
    },
    {} as Record<string, any>,
  );

  const dataset = Object.entries(
    _.transform(
      mappedClauseTags,
      (result, values, key) => {
        result[key] = _.sortBy(values, value => value.name.toLowerCase());
      },
      {} as Record<string, any>,
    ),
  );

  const styles = useStyles();

  return (
    <div className={styles.container}>
      {props.clause.documents?.[0] && (
        <Card appearance="subtle">
          <CardHeader
            header={
              <Subtitle2Stronger style={{ flex: '0 0 auto', display: 'inline-block' }}>Documents</Subtitle2Stronger>
            }
          />
          <div className={styles.fieldItem}>
            {props.clause.documents?.map(d => (
              <InteractionTag key={d.doc_id} size="small" appearance="brand">
                <InteractionTagPrimary
                  primaryText={
                    <span>
                      {d.doc_file_name} &nbsp; <OpenRegular />
                    </span>
                  }
                  onClick={() => props.onOpenDocument?.(d)}
                />
              </InteractionTag>
            ))}
          </div>
        </Card>
      )}
      <Virtuoso
        ref={virtuosoRef}
        data={dataset}
        context={{ className: styles.fieldItem }}
        style={{ margin: '0', width: '100%' }}
        followOutput={(isBottom: boolean) => (isBottom ? 'smooth' : false)}
        itemContent={onRenderMetadata}
      />
    </div>
  );
}
