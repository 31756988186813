import { createApi } from '@reduxjs/toolkit/query/react';
import { IClause } from '../../interfaces';
import { SearchSuggestionRequest, SimilarSuggestionSearch } from '../../models/requests';
import { UnusualTextPhrases } from '../../models/responses/suggestion';
import { similarSuggestionRequestBuilder, suggestionRequestBuilder } from '../transformers/requests';
import { dispatchUpdateTrigrams } from '../worker';
import { axiosBaseQuery } from './axiosConfig';

type FetchTrigramsForSuggestionsArgs = {
  appId: string;
  clauses: IClause[];
  query: SearchSuggestionRequest | SimilarSuggestionSearch;
};

export const trigramsApi = createApi({
  reducerPath: 'trigramsApi',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'v3',
  }),
  tagTypes: ['trigrams'],
  endpoints: build => ({
    getTrigramsForApp: build.query<Array<UnusualTextPhrases>, string>({
      queryFn: async _args => {
        return { data: [] };
      },
      keepUnusedDataFor: 3600,
    }),
    fetchTrigramsForSuggestions: build.query<void, FetchTrigramsForSuggestionsArgs>({
      queryFn: async args => {
        const { filter, rankingOptions, appContext } = suggestionRequestBuilder(args.query as SearchSuggestionRequest);
        filter.limit = 100;
        dispatchUpdateTrigrams(
          {
            appId: args.appId,
            clauses: args.clauses.map(s => ({ id: s.clause_id, text: s.clause_text })),
            filter,
            rankingOptions,
          },
          appContext,
        );
        return { error: null };
      },
    }),
    fetchTrigramsForSimilarClauses: build.query<void, FetchTrigramsForSuggestionsArgs>({
      queryFn: async args => {
        const { filter, rankingOptions, appContext } = similarSuggestionRequestBuilder(
          args.query as SimilarSuggestionSearch,
        );
        filter.limit = 100;
        dispatchUpdateTrigrams(
          {
            appId: args.appId,
            clauses: args.clauses.map(s => ({ id: s.clause_id, text: s.clause_text })),
            filter,
            rankingOptions,
          },
          appContext,
        );
        return { error: null };
      },
    }),
    updateTrigramsForApp: build.mutation<
      Array<UnusualTextPhrases>,
      { appId: string; unusualPhrases: Array<UnusualTextPhrases> }
    >({
      queryFn: async args => {
        return { data: args.unusualPhrases };
      },
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;
        dispatch(
          trigramsApi.util.updateQueryData('getTrigramsForApp', args.appId, savedData => {
            savedData.push(...data);
          }),
        );
      },
    }),
  }),
});
