import { combineReducers } from '@reduxjs/toolkit';
import {
  authApi,
  bookmarksApi,
  commentsApi,
  documentApi,
  favoritesApi,
  suggestionApi,
  tagsApi,
  trigramsApi,
  UsersApi,
  WordDocumentApi,
  WorkspaceApi,
} from '../services';
import appsReducer from './slices/appsSlice';
import globalReducer from './slices/globalSlice';

export const reducers = combineReducers({
  global: globalReducer,
  apps: appsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [WorkspaceApi.reducerPath]: WorkspaceApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [suggestionApi.reducerPath]: suggestionApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [bookmarksApi.reducerPath]: bookmarksApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [WordDocumentApi.reducerPath]: WordDocumentApi.reducer,
  [UsersApi.reducerPath]: UsersApi.reducer,
  [trigramsApi.reducerPath]: trigramsApi.reducer,
});

export const middlewares: any[] = [
  authApi.middleware,
  tagsApi.middleware,
  suggestionApi.middleware,
  documentApi.middleware,
  bookmarksApi.middleware,
  commentsApi.middleware,
  favoritesApi.middleware,
  WordDocumentApi.middleware,
  WorkspaceApi.middleware,
  UsersApi.middleware,
  trigramsApi.middleware,
];

export const whiteListReducers = [
  'global',
  'apps',
  // Add your persisted reducers here
];
