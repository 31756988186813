import { Button, Menu, MenuList, MenuPopover, MenuTrigger, useOverflowMenu } from '@fluentui/react-components';
import { ChevronDownRegular } from '@fluentui/react-icons';
import React from 'react';
import OverflowMenuItem from './OverflowMenuItem';
import useStyles from './styles';

type OverflowMenuProps = {
  onTabSelect?: (tabId: string) => void;
  onClose?: (tabId: string) => void;
  tabs: Array<{ id: string; name: string }>;
};

export default React.memo(({ tabs, onTabSelect, onClose }: OverflowMenuProps) => {
  const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();

  const styles = useStyles();

  const onOverflowTabSelected = (tabId: string) => {
    onTabSelect?.(tabId);
  };

  const onOverFlowTabClosed = (tabId: string) => {
    onClose?.(tabId);
  };

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu hasIcons>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          aria-label={`${overflowCount} more tabs`}
          className={styles.menuButton}
          icon={<ChevronDownRegular />}
          ref={ref}
          role="tab"
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuList className={styles.menu}>
          {tabs.map(tab => (
            <OverflowMenuItem
              key={tab.id}
              onClick={() => onOverflowTabSelected(tab.id)}
              onClose={() => onOverFlowTabClosed(tab.id)}
              tab={tab}
            />
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
});
