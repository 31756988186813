import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupAxiosRequestInterceptors } from '../services/api/axiosConfig';
import { middlewares, reducers, whiteListReducers } from './store';

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: whiteListReducers,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  reducer: persistedReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupAxiosRequestInterceptors(store.getState);

export const StorePersistor = persistStore(store);
