import { Field, Textarea, tokens } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppData } from '../../../hooks';
import { RootState } from '../../../store';
import parseHtmlContent from '../../../utils/parseHtmlContent';

type SuggestionSearchInputProps = {
  className?: string;
};

const SuggestionSearchInput = React.memo(({ className }: SuggestionSearchInputProps) => {
  const appId = useSelector((state: RootState) => state.apps.selectedApp);
  const [searchText, setSearchText] = useAppData<string>(appId, 'searchText');

  const onInputHandleResize: React.FormEventHandler<HTMLTextAreaElement> = ev => {
    const { style } = ev.currentTarget;
    style.height = style.minHeight = 'auto';
    style.minHeight = `${Math.min(ev.currentTarget.scrollHeight + 4, parseInt(style.maxHeight))}px`;
    style.height = `${ev.currentTarget.scrollHeight + 4}px`;
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
  };

  const onDrop = async (ev: any) => {
    const htmlContent = ev.dataTransfer.getData('text/html') || `<p>${ev.dataTransfer.getData('text/plain')}</p>`;
    const text = parseHtmlContent(htmlContent);
    if (text) {
      setSearchText(searchText + text);
    }
  };

  useEffect(() => {
    const textArea: any = document.querySelector('textarea');
    if (textArea) {
      onInputHandleResize({ currentTarget: textArea } as any);
    }
  }, [searchText]);

  return (
    <Field label="Search for language from your past works" onDragOver={onDragOver} onDrop={onDrop}>
      <Textarea
        onChange={(_ev, data) => setSearchText(data.value as string)}
        onInput={onInputHandleResize}
        placeholder="Insert keywords, a natural language description, or the text copied from your document"
        resize="none"
        style={{ minHeight: '112px', borderColor: tokens.colorNeutralStroke1 }}
        textarea={{ className }}
        value={searchText}
      />
    </Field>
  );
});

export default SuggestionSearchInput;
