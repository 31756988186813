import React from 'react';

type checklistIconProps = {
  type: 'add' | 'list';
} & React.SVGProps<SVGSVGElement>;

export default function ChecklistAppIcon({ type, ...props }: checklistIconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1362_22135)">
        <path
          d="M7.08333 0H32.9167C36.8333 0 40 3.24786 40 7.26496V32.735C40 36.7521 36.8333 40 32.9167 40H7.08333C3.16667 40 0 36.7521 0 32.735V7.26496C0 3.24786 3.16667 0 7.08333 0Z"
          fill={type === 'add' ? 'green' : '#B30B00'}
        />
        {type === 'add' && (
          <path
            transform="translate(9, 9)"
            d="M6.25 3A3.25 3.25 0 0 0 3 6.25v11.5C3 19.55 4.46 21 6.25 21h5.77c-.3-.46-.53-.97-.7-1.5H6.24c-.97 0-1.75-.78-1.75-1.75V6.25c0-.97.78-1.75 1.75-1.75h11.5c.97 0 1.75.78 1.75 1.75v5.06c.53.18 1.04.42 1.5.71V6.25C21 4.45 19.54 3 17.75 3H6.25Zm6.25 6.25c0-.41.34-.75.75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Zm-1.72-.47a.75.75 0 1 0-1.06-1.06L8.25 9.19l-.47-.47a.75.75 0 0 0-1.06 1.06l1 1c.3.3.77.3 1.06 0l2-2Zm0 4.44c.3.3.3.77 0 1.06l-2 2c-.3.3-.77.3-1.06 0l-1-1a.75.75 0 1 1 1.06-1.06l.47.47 1.47-1.47c.3-.3.77-.3 1.06 0ZM23 17.5a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0Zm-5 .5v2.5a.5.5 0 1 1-1 0V18h-2.5a.5.5 0 0 1 0-1H17v-2.5a.5.5 0 1 1 1 0V17h2.5a.5.5 0 0 1 0 1H18Z"
            fill="white"
          />
        )}
        {type === 'list' && (
          <path
            d="M12 29C11.45 29 10.9792 28.8042 10.5875 28.4125C10.1958 28.0208 10 27.55 10 27V13C10 12.45 10.1958 11.9792 10.5875 11.5875C10.9792 11.1958 11.45 11 12 11H28C28.55 11 29.0208 11.1958 29.4125 11.5875C29.8042 11.9792 30 12.45 30 13V27C30 27.55 29.8042 28.0208 29.4125 28.4125C29.0208 28.8042 28.55 29 28 29H12ZM12 27H28V13H12V27ZM13 25H18V23H13V25ZM22.55 23L27.5 18.05L26.075 16.625L22.55 20.175L21.125 18.75L19.725 20.175L22.55 23ZM13 21H18V19H13V21ZM13 17H18V15H13V17Z"
            fill="white"
          />
        )}
      </g>
      <defs>
        <clipPath id="clip0_1362_22135">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
