import {
  Button,
  Combobox,
  ComboboxProps,
  Field,
  makeStyles,
  shorthands,
  Spinner,
  Tag,
  TagGroup,
  Text,
  tokens,
  useComboboxFilter,
} from '@fluentui/react-components';
import { useStaticVirtualizerMeasure, Virtualizer } from '@fluentui/react-components/unstable';
import { DismissRegular } from '@fluentui/react-icons';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';

export type MetaDataSelectorProps = {
  label?: string | React.ReactElement;
  selected?: Array<string>;
  group_id?: string;
  tags?: Array<any>;
  isLoading?: boolean;
  onSelectedTagsChange?: (selectedTags: Array<string>) => void;
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius('5px'),
  },
  tagsGroup: {
    flexWrap: 'wrap',
    ...shorthands.gap('5px'),
    ...shorthands.padding('5px', '5px', 0),
  },
  selectorCombo: {
    ...shorthands.border(0),
  },
  capitalCaseText: {
    textTransform: 'capitalize',
  },
  option: {
    backgroundColor: 'white',
    ...shorthands.padding('5px'),
  },
  listbox: {
    maxHeight: '300px',
  },
});

export default function MetaDataSelector({ selected, tags, isLoading = false, ...props }: MetaDataSelectorProps) {
  const selectedTags = useMemo(() => {
    return selected ?? [];
  }, [selected]);
  const [query, setQuery] = useState<string>('');

  const options = useMemo(
    () =>
      tags?.map((tag: any) => ({
        children: tag.name,
        value: `${tag.tagId}`,
      })) ?? [],
    [tags],
  );

  const children = useComboboxFilter(query, options, {
    noOptionsMessage: 'No results match your search.',
    optionToText: option => option.children,
  });

  const firstTagRef = useRef<HTMLButtonElement>(null);
  const comboboxInputRef = useRef<HTMLInputElement>(null);

  const styles = useStyles();

  const itemHeight = 32;
  const numberOfItems = children.length;

  const { virtualizerLength, bufferItems, bufferSize, scrollRef } = useStaticVirtualizerMeasure({
    defaultItemSize: itemHeight,
    direction: 'vertical',
  });

  const removeItem = (value: string) => {
    const newSelectedTags = [...selectedTags].filter(tag => tag !== value);
    props.onSelectedTagsChange?.(newSelectedTags);
  };

  const onSelect: ComboboxProps['onOptionSelect'] = async (_event, data) => {
    props.onSelectedTagsChange?.(data.selectedOptions);
  };

  const onInputChange: ComboboxProps['onChange'] = debounce(event => {
    setQuery(event.target.value.trim());
  }, 300);

  return (
    <Field style={{ width: '100%' }} label={props.label ? props.label : 'Apply metadata and tags'}>
      <div className={styles.wrapper}>
        {selectedTags.length !== 0 && (
          <div
            style={{
              maxHeight: '45vh',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            <TagGroup className={styles.tagsGroup} aria-label="TagGroup with dismissible Tags">
              {selectedTags?.map((tagId, index) => {
                const tag = tags?.find(t => t.tagId === tagId);
                if (!tag) {
                  return null;
                }
                return (
                  <Tag key={tag.tagId} value={tag.tagId} ref={index === 0 ? firstTagRef : null}>
                    <Text wrap={false} style={{ textOverflow: 'ellipsis' }}>
                      {tag.name}
                    </Text>
                    <Button
                      appearance="subtle"
                      size="small"
                      icon={<DismissRegular />}
                      onClick={() => removeItem(tag.tagId)}
                    />
                  </Tag>
                );
              })}
            </TagGroup>
          </div>
        )}
        <Combobox
          className={styles.selectorCombo}
          multiselect={true}
          placeholder="Insert keywords and tags"
          selectedOptions={selectedTags}
          freeform={true}
          listbox={{ ref: scrollRef, className: styles.listbox }}
          positioning={'below-end'}
          onChange={onInputChange}
          onOptionSelect={onSelect}
          ref={comboboxInputRef}
          disabled={isLoading}
          expandIcon={isLoading ? <Spinner size="extra-tiny" /> : undefined}
        >
          <Virtualizer
            numItems={numberOfItems}
            virtualizerLength={virtualizerLength}
            bufferItems={bufferItems}
            bufferSize={bufferSize}
            itemSize={itemHeight}
          >
            {index => {
              return children[index];
            }}
          </Virtualizer>
        </Combobox>
      </div>
    </Field>
  );
}
