import _ from 'lodash';
import { QueryBuilderContext, SuggestionSearchMode } from '../enums';
import { IClause, IDocument, ISuggestionData } from '../interfaces';
import KeywordTransformer from './keywordTransformer';
import SearchQueryBuilder from './searchQueryBuilder';

export function handleOfficeSSOError(error: any) {
  switch (error.code) {
    case 13001:
      // No one is signed into Office. If the add-in cannot be effectively used when no one
      // is logged into Office, then the first call of getAccessToken should pass the
      // `allowSignInPrompt: true` option. Since this sample does that, you should not see this error
      console.error(
        'No one is signed into Office. But you can use many of the add-ins functions anyway. If you want to error in, press the Get OneDrive File Names button again.',
      );
      break;
    case 13002:
      // The user aborted the consent prompt. If the add-in cannot be effectively used when consent
      // has not been granted, then the first call of getAccessToken should pass the `allowConsentPrompt: true` option.
      console.error(
        'You can use many of the add-ins functions even though you have not granted consent. If you want to grant consent, press the Get OneDrive File Names button again.',
      );
      break;
    case 13006:
      // Only seen in Office on the web.
      console.error(
        'Office on the web is experiencing a problem. Please sign out of Office, close the browser, and then start again.',
      );
      break;
    case 13008:
      // Only seen in Office on the web.
      console.error('Office is still working on the last operation. When it completes, try this operation again.');
      break;
    case 13010:
      // Only seen in Office on the web.
      console.error("Follow the instructions to change your browser's zone configuration.");
      break;
    default:
      console.error(`${error.code}: ${error.message}`);
      break;
  }
}

export async function openDialog(
  dialogURL: string,
  context: React.MutableRefObject<Office.Dialog | undefined>,
  messageHandler: (args: any) => void,
) {
  Office.context.ui.displayDialogAsync(dialogURL, { height: 60, width: 30, asyncContext: context }, function (result) {
    const dialogRef = result.asyncContext as React.MutableRefObject<Office.Dialog>;
    if (result.status === Office.AsyncResultStatus.Failed) {
      console.error(`${result.error.code}: ${result.error.message}`);
    } else {
      dialogRef.current = result.value;
      dialogRef.current.addEventHandler(Office.EventType.DialogMessageReceived, messageHandler);
    }
  });
}

export function textToTsQueryText(text: string) {
  const keywordTransformer = new KeywordTransformer();
  return keywordTransformer.forwardTransform(text);
}

export function textToSanitizedKeywordsText(text: string) {
  const keywordTransformer = new KeywordTransformer();
  return keywordTransformer.sanitize(text);
}

export function buildSuggestionRequest(values: ISuggestionData, topics: Array<string> = [], pageCount: number = 1) {
  const disableExternalTextSearch = values.searchType === SuggestionSearchMode.KEYWORD_SEARCH;
  const queryBuilder = new SearchQueryBuilder(QueryBuilderContext.CLAUSE_SEARCH);

  const excludeClauses = [...(values.badSuggestions || []), ..._.map(values.suggestions, sug => sug.clause_id)];
  const filterKeywordsText = values.filters?.keywords ? textToSanitizedKeywordsText(values.filters.keywords) : '';
  const keywordText = `${values.keywordSearchText} ${filterKeywordsText}`.trim();
  const semanticText = `${values.searchText} ${values?.filters?.keywords || ''}`.trim();

  const conditions = queryBuilder.buildQuery({
    keywordSearchText: disableExternalTextSearch && textToTsQueryText(keywordText),
    searchText: !disableExternalTextSearch && semanticText.concat(topics?.join(' ')).trim(),
    badSuggestions: excludeClauses,
    tagIds: values.metadataTags,
    isBooleanSearch: disableExternalTextSearch,
  });

  const quoteReg = new RegExp(/"(.*?)"/, 'gi');
  const sanitizedSemanticText = !disableExternalTextSearch ? textToSanitizedKeywordsText(semanticText) : '';

  const quotedTexts = Array.from(
    (disableExternalTextSearch ? keywordText : sanitizedSemanticText).matchAll(quoteReg),
  ).map(value => value[1]);
  const cleanKeywords = (disableExternalTextSearch ? keywordText : sanitizedSemanticText)
    .replace(quoteReg, ' ')
    .replace(/[^A-Za-z0-9 $]/gi, ' ')
    .replace(/(AND|OR)/g, ' ')
    .replace(/ +/g, ' ')
    .trim()
    .split(' ');

  const rankingKeywords = _.uniq(quotedTexts.concat(cleanKeywords));

  const rankingOptions = {
    topics,
    keywords: rankingKeywords,
  };

  return {
    filter: {
      disableExternalTextSearch,
      limit: pageCount > 1 ? 100 * (pageCount - 1) : 50,
      conditions,
    },
    rankingOptions,
  };
}

export function extractSuggestionsDocs(suggestions: Array<IClause>) {
  if (!suggestions || _.isEmpty(suggestions)) {
    return {
      doc_ids: [] as Array<string | number>,
      suggestion_results: suggestions,
    };
  }
  const docIds = _.map(
    _.uniqBy(
      _.flatMap(suggestions, sug => sug.documents),
      'doc_id',
    ),
    'doc_id',
  );

  return {
    doc_ids: docIds as Array<string | number>,
    suggestion_results: suggestions,
  };
}

export function updateSuggestionsDocs(documents: Array<IDocument>, suggestions: Array<IClause>) {
  if (_.isEmpty(documents)) {
    return suggestions;
  }

  _.forEach(suggestions, suggestion => {
    suggestion.documents = _.intersectionWith(suggestion.documents, documents, (current: any, incoming: any) => {
      if (_.isEqual(current.doc_id, incoming.doc_id)) {
        _.merge(current, incoming);
        return true;
      }
      return false;
    });
  });

  return suggestions;
}

export function extractDocumentsTags(documents: Array<Record<string, any>>): Record<string, Array<string | number>> {
  const tags: Record<string, Array<string | number>> = {};
  _.forEach(documents, doc => {
    _.entriesIn(doc.tags).forEach(([label, option]: any) => {
      const tagIds = _.map(option.tags, tag => tag.id);
      tags[label] = _.uniq(_.concat(tags[label] ?? [], tagIds));
    });
  });
  return tags;
}
