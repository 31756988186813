/// <reference types="office-js" />

import parseHtmlContent from '../../utils/parseHtmlContent';
import IDocumentService from './IDocumentService';

export class WordDocumentService implements IDocumentService {
  getDocumentText: () => Promise<string> = async () =>
    Word.run(async context => {
      const { paragraphs } = context.document.body;
      context.load(paragraphs);
      await context.sync();
      return paragraphs.items.map((paragraph: Word.Paragraph) => paragraph.text).join('\n');
    });

  getSelectedText: () => Promise<string> = async () =>
    new Promise(resolve => {
      if (window.Office) {
        Office.context.document.getSelectedDataAsync(
          Office.CoercionType.Html,
          (asyncResult: Office.AsyncResult<unknown>) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              resolve(parseHtmlContent(asyncResult.value as string));
            }
          },
        );
      } else {
        resolve('');
      }
    });

  getParagraph: (someParameter: any) => Promise<string> = async () =>
    new Promise(resolve => {
      resolve('');
    });

  getDocumentTopics: () => Promise<string[]> = () =>
    new Promise(resolve => {
      let topics = [];
      if (window.Office) {
        topics = window.Office.context.document.settings.get('topics');
      }
      resolve(topics);
    });

  getDocumentId: () => Promise<string> = () =>
    new Promise(resolve => {
      let syntheiaId = '';
      if (window.Office) {
        syntheiaId = window.Office.context.document.settings.get('syntheia_id');
        if (!syntheiaId) {
          syntheiaId = Date.now().toString();
          window.Office.context.document.settings.set('syntheia_id', syntheiaId);
          window.Office.context.document.settings.saveAsync();
        }
        resolve(syntheiaId);
      }
    });

  getDocumentFileDocx: () => Promise<Uint8Array> = () =>
    new Promise((resolve, reject) => {
      if (!window.Office) {
        reject(new Error(`Office JS is defined.`));
      }

      window.Office.context.document.getFileAsync(
        Office.FileType.Compressed,
        { sliceSize: 65536, asyncContext: { resolve, reject } },
        async asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            const file = asyncResult.value;
            const documentFileData: Uint8Array = new Uint8Array(file.size);

            const { resolve, reject } = asyncResult.asyncContext;

            for (let sliceIndex = 0; sliceIndex < file.sliceCount; sliceIndex++) {
              try {
                await new Promise((sliceResolve, SliceReject) => {
                  file.getSliceAsync(sliceIndex, asyncSliceResult => {
                    if (asyncSliceResult.status === Office.AsyncResultStatus.Succeeded) {
                      const { data, index } = asyncSliceResult.value;
                      documentFileData.set(data, index + 1);
                      sliceResolve(true);
                    } else {
                      SliceReject(new Error(`An error occurred while getting word file. index ${sliceIndex}`));
                    }
                  });
                });
              } catch (err) {
                file.closeAsync();
                reject(err);
                break;
              }
            }

            file.closeAsync();

            resolve(documentFileData);
          } else {
            reject(asyncResult.error);
          }
        },
      );
    });

  setDocumentTopics: (topics: string[]) => Promise<void> = async (topics: string[]) => {
    if (window.Office) {
      window.Office.context.document.settings.set('topics', topics);
      window.Office.context.document.settings.saveAsync((result: Office.AsyncResult<void>) => {
        if (result.error) {
          console.error('Failed to save topics in document settings.', result.error);
        } else {
          window.Office.context.document.settings.refreshAsync();
        }
      });
    }
  };
}
