import _ from 'lodash';
import React from 'react';
import TabItem from './TabItem';

type TabItemsProps = {
  selectedTab: string;
  tabs: Array<{ id: string; name: string }>;
  onClose: (tabId: string) => void;
};

export default React.memo(({ tabs, selectedTab, onClose }: TabItemsProps) => {
  const priorities = React.useMemo(() => {
    const pivotIdx = tabs.findIndex(tab => tab.id.toString() === selectedTab.toString());
    const length = tabs.length;

    const priorityMap: Record<string, number> = _.transform(
      _.groupBy(tabs, 'id'),
      (result, value, key) => {
        result[key] = key.toString() === selectedTab.toString() ? 2 : 1;
      },
      {} as Record<string, number>,
    );

    if (pivotIdx === -1) return priorityMap;

    // If Selected Tab is the first Tab
    if (pivotIdx === 0) {
      tabs.forEach((tab, idx) => (priorityMap[tab.id] = length - idx));
    }
    // if Selected is the last Tab
    if (pivotIdx === tabs.length - 1) {
      tabs.forEach((tab, idx) => (priorityMap[tab.id] = idx + 1));
    }
    // If selected Item is in the middle, we use triagular priority
    if (pivotIdx > 0 && pivotIdx < tabs.length - 1) {
      const left = tabs.slice(0, pivotIdx);
      const right = tabs.slice(pivotIdx + 1);
      priorityMap[selectedTab] = length;

      const mapPriorities = (tab: { id: string; name: string }, idx: number) =>
        (priorityMap[tab.id] = Math.max(0, length - (idx + 1)));

      left.reverse();

      left.forEach(mapPriorities);
      right.forEach(mapPriorities);
    }
    return priorityMap;
  }, [selectedTab, tabs]);

  return (
    <>
      {tabs.map(tab => (
        <TabItem
          key={tab.id}
          tab={tab}
          isSelected={selectedTab === tab.id}
          onClose={onClose}
          priority={priorities[tab.id]}
        />
      ))}
    </>
  );
});
