import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { DEFAULT_APPLICATION_ID } from '../constants';
import { ApplicationType } from '../enums';
import { useAppDispatch, useAppSelector } from '../store';
import { appSelector, closeApp, createApp, selectApp } from '../store/slices/appsSlice';
import { createApplication } from '../utils';

export default function useContextApp() {
  const apps = useAppSelector(state => appSelector.selectAll(state));
  const contextApp = useAppSelector(state => appSelector.selectById(state, state.apps.selectedApp));

  const dispatch = useAppDispatch();

  const setContextSelectedApp = useCallback(
    (appId: string) => {
      dispatch(selectApp(appId));
    },
    [dispatch],
  );

  const createContextApp = useCallback(
    (type: ApplicationType, initData?: Record<string, any>) => {
      const appKey = `app_${_.uniqueId(Date.now().toString())}`;
      const app = createApplication(type, initData);

      dispatch(createApp({ id: appKey, name: app.name, type, ...app.data }));
    },
    [dispatch],
  );

  const closeContextApp = useCallback(
    (appId: string) => {
      dispatch(closeApp(appId));
    },
    [dispatch],
  );

  const ApplicationTabs = useMemo(() => {
    return _.filter(apps, app => app.id !== DEFAULT_APPLICATION_ID).map(({ id, name }) => ({ name, id }));
  }, [apps]);

  return {
    contextApp,
    ApplicationTabs,
    setContextSelectedApp,
    createContextApp,
    closeContextApp,
  };
}
