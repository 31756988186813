import { createApi } from '@reduxjs/toolkit/query/react';
import { isEmpty } from 'lodash';
import { QueryBuilderContext } from '../../enums';
import { DocumentText, DocumentTextBlock } from '../../interfaces';
import { SearchQueryBuilder } from '../../utils';
import axios, { axiosBaseQuery } from './axiosConfig';

export const searchDocuments = (values: Record<string, any>) => {
  return axios.post('/v3/documents/search', values);
};

export const getDocumentTopics = (documentText: string) => {
  return axios.post('/drafting/getTopics', { document_text: documentText });
};

export const downloadDocument = (doc_id: string | number, values?: Record<string, any>) => {
  return axios.get(`/drafting/downloadDocument/`, {
    params: { doc_id, ...values },
  });
};

export const uploadDocument = (values: Record<string, any>) => {
  return axios.post('/v3/documents', values);
};

export const downloadDocumentV3 = (docId: string, type: string = 'pdf') => {
  return axios.get(`v3/documents/downloads/${docId}`, {
    params: { type },
    responseType: 'blob',
  });
};

const transformDocumentText = (document: DocumentText): DocumentText => {
  document.nodes = document.nodes?.filter((node: DocumentTextBlock) => !isEmpty(node)) ?? [];
  return document;
};

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'v3/documents',
  }),
  tagTypes: ['Documents'],
  endpoints: build => ({
    getDocumentText: build.query<DocumentText, { id: string; workspaceId: string }>({
      query: ({ id, workspaceId }) => ({
        url: `/${id}/text`,
        method: 'GET',
        params: { flatten: true, appContext: { workspaceId } },
      }),
      transformResponse: (response: DocumentText) => transformDocumentText(response),
    }),
    searchDocuments: build.query<any, any>({
      queryFn: async (args: any, { getState, dispatch }, extraOptions, baseQuery) => {
        const queryBuilder = new SearchQueryBuilder(QueryBuilderContext.DOCUMENT_SEARCH);
        const conditions = queryBuilder.buildQuery(args);

        const requestObject = {
          limit: args.docIds?.length || 100,
          conditions,
          appContext: {
            workspaceId: args.workspaceId,
          },
        };
        const { data, error } = await baseQuery({
          url: '/search',
          method: 'POST',
          data: requestObject,
        });
        if (!!error) return { error };
        return { data };
      },
    }),
  }),
});
