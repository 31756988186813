import { Divider, makeStyles, shorthands, tokens } from '@fluentui/react-components';

import SuggestionTypeSwitch from '../../components/SuggestionTypeSwitch';
import { SuggestionSearchMode } from '../../enums';
import { useAppData } from '../../hooks';
import { useAppSelector } from '../../store';
import SuggestionMetadataSelector from './components/SuggestionMetadataSelector';
import SuggestionSearchActions from './components/SuggestionSearchActions';
import SuggestionSearchHistory from './components/SuggestionSearchHistory';
import SuggestionSearchInput from './components/SuggestionSearchInput';

export type SuggestionSearchFormProps = {
  appId: string;
  searchText?: string;
  searchMode?: SuggestionSearchMode;
  selectedMetadataTags?: Array<{ id: number; name: string }>;
  selectedWorkspaceId?: number;
  onSearchSubmit?: Function;
};

const useStyles = makeStyles({
  formContainer: {
    position: 'relative',
    display: 'flex',
    rowGap: '12px',
    flexDirection: 'column',
    flexGrow: 1,
    ...shorthands.padding('10px', '10px'),
    ...shorthands.overflow('hidden'),
    backgroundColor: tokens.colorNeutralBackground2,
  },
  textInputArea: {
    maxHeight: '146px',
    minWidth: '100%',
  },
  formActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    rowGap: '12px',
    columnGap: '12px',
    flexWrap: 'nowrap',
    justifyContent: 'stretch',
    width: '100%',
    ...shorthands.padding('16px', 0),
  },
});

export default function SuggestionSearchForm({ appId, ...props }: SuggestionSearchFormProps) {
  const userId = useAppSelector(state => state.global?.appContext?.app_user_id as string);
  const [searchType, setSearchType] = useAppData<SuggestionSearchMode>(appId, 'searchType');

  const styles = useStyles();

  const onSearchTypeChange = (type: SuggestionSearchMode) => {
    setSearchType(type);
  };

  return (
    <div className={styles.formContainer}>
      <Divider style={{ maxHeight: '20px' }}>New Search</Divider>
      <SuggestionSearchInput className={styles.textInputArea} />
      <SuggestionTypeSwitch type={searchType} onChange={onSearchTypeChange} enableTooltip={true} />
      <SuggestionMetadataSelector appId={appId} />
      <SuggestionSearchActions className={styles.formActionContainer} appId={appId} userId={userId} />
      <Divider style={{ paddingTop: '30px', paddingBottom: '10px' }}>Recent Search History</Divider>
      <SuggestionSearchHistory appId={appId} userId={userId} />
    </div>
  );
}
