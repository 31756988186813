import {
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  mergeClasses,
  shorthands,
  Subtitle2Stronger,
  TableCellLayout,
  TableColumnDefinition,
  tokens,
  useFluent,
  useScrollbarWidth,
} from '@fluentui/react-components';
import { DocumentText24Regular, OpenRegular } from '@fluentui/react-icons';
import React from 'react';
import Loading from '../../components/Loading';
import { AnomaliesPage } from '../../enums';
import { useAppData } from '../../hooks';
import { IDocument } from '../../interfaces';
import { documentApi } from '../../services';
import { AppComponentProps } from '../../types/AppComponentProps';

export type AnomaliesFormProps = AppComponentProps;

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    height: '100%',
    alignItems: 'stretch',
    ...shorthands.padding('10px', '16px'),
  },
  formRow: {
    width: '100%',
    ...shorthands.flex(0, 0, 'auto'),
  },
  formActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    ...shorthands.gap('10px'),
  },
  formActionBtn: {
    fontWeight: 'bold',
    color: 'white',
  },
  formActionActive: {
    backgroundColor: '#CC3333',
    '&:hover': {
      backgroundColor: 'rgba(193, 51, 55, 0.8)',
    },
    '&:active': {
      backgroundColor: 'rgba(193, 51, 55, 0.8)',
    },
  },
  documentListGrid: {
    ...shorthands.flex(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    minHeight: 'min-content',
    ...shorthands.padding('10px', 0),
    ...shorthands.overflow('hidden', 'hidden'),
  },
  documentTitle: {
    display: 'block',
    width: '90%',
    ...shorthands.overflow('hidden'),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const AnomaliesForm = ({ appId }: AnomaliesFormProps) => {
  const [searchText] = useAppData<string>(appId, 'searchText');
  const [workspaceId] = useAppData<string>(appId, 'workspaceId');
  const [, setCurrentPage] = useAppData<AnomaliesPage>(appId, 'currentPage');
  const [selectedDocuments, setSelectedDocuments] = useAppData<Array<string>>(appId, 'selectedDocuments');

  const { targetDocument } = useFluent();
  const scrollbarWidth = useScrollbarWidth({ targetDocument });

  const { data: documents, isLoading } = documentApi.useSearchDocumentsQuery(
    { title: searchText, workspaceId: workspaceId },
    {
      // selectFromResult: ({ data, ...rest }) => {
      //   if (data?.documents) {
      //     // const documentsData = mapResponseDocument(
      //     //   data.documents
      //     // ) as Array<IDocument>;
      //     return {
      //       ...rest,
      //       data: documentsData,
      //     };
      //   }
      //   return {
      //     ...rest,
      //     data: [],
      //   };
      // },
      refetchOnMountOrArgChange: true,
    },
  );

  const styles = useStyles();

  const onDocumentSelectionChange = (_ev: any, data: any) => {
    const selections = Array.from(data.selectedItems as Set<number | string>) as Array<string>;
    setSelectedDocuments(selections);
  };

  const onRunAnomalies: React.MouseEventHandler = (_ev: React.MouseEvent) => {
    if (!selectedDocuments.length) {
      return;
    }
    setCurrentPage(AnomaliesPage.ANOMALIES_REPORT);
  };

  const DocumentColumnDefinition: TableColumnDefinition<IDocument>[] = [
    createTableColumn<IDocument>({
      columnId: 'documents',
      compare: (a, b) => {
        return `${a.doc_id}`.localeCompare(`${b.doc_id}`);
      },
      renderHeaderCell: () => {
        return (
          <Subtitle2Stronger wrap={false} truncate>
            Document Results
          </Subtitle2Stronger>
        );
      },
      renderCell: item => {
        return (
          <TableCellLayout
            truncate
            media={<DocumentText24Regular color={tokens.colorBrandBackground} />}
            style={{ flex: '0 1 auto' }}
          >
            {item.doc_file_name}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<IDocument>({
      columnId: 'actions',
      compare: (a, b) => {
        return `${a.doc_id}`.localeCompare(`${b.doc_id}`);
      },
      renderHeaderCell: () => {
        return <></>;
      },
      renderCell: item => {
        return (
          <Button aria-label={`Open Document ${item.doc_file_name}`} icon={<OpenRegular />} appearance="transparent" />
        );
      },
    }),
  ];

  return (
    <div className={styles.formContainer}>
      <div className={mergeClasses(styles.formRow, styles.documentListGrid)}>
        {isLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyItems: 'center',
              width: '100%',
            }}
          >
            <Loading label="Searching for document..." labelPosition="below" size="medium" />
          </div>
        )}
        {!isLoading && (
          <DataGrid
            items={documents}
            columns={DocumentColumnDefinition}
            sortable
            focusMode="cell"
            selectionMode="multiselect"
            getRowId={(item: IDocument) => item.doc_id}
            onSelectionChange={onDocumentSelectionChange}
            style={{ flexGrow: 1, maxHeight: 'calc(100% - 30px)' }}
          >
            <DataGridHeader style={{ paddingRight: scrollbarWidth }}>
              <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
                {({ renderHeaderCell, columnId }) => (
                  <DataGridHeaderCell style={{ flexGrow: columnId === 'documents' ? 1 : 0.1 }}>
                    {renderHeaderCell()}
                  </DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            <DataGridBody<IDocument> style={{ overflowY: 'auto', maxHeight: '100%' }}>
              {({ item, rowId }) => (
                <DataGridRow<IDocument> key={rowId} selectionCell={{ 'aria-label': 'Select row' }}>
                  {({ renderCell, columnId }) => (
                    <DataGridCell style={{ flexGrow: columnId === 'documents' ? 1 : 0.1 }}>
                      {renderCell(item)}
                    </DataGridCell>
                  )}
                </DataGridRow>
              )}
            </DataGridBody>
          </DataGrid>
        )}
      </div>
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'center',
            width: '100%',
          }}
        >
          <Button appearance="primary" style={{ width: '100%' }} onClick={onRunAnomalies}>
            Run anomalies report
          </Button>
        </div>
      )}
    </div>
  );
};

export default AnomaliesForm;
