import _ from 'lodash';
import { DEFAULT_RANKING_OPTIONS } from '../constants';
import { AnomaliesPage, ApplicationType, SuggestionAppPage, SuggestionSearchMode } from '../enums';
import { IAnomaliesData, IApplication, IClauseInfoData, ISuggestionData } from '../interfaces';

function createBlankApplication(type: ApplicationType): IApplication {
  switch (type) {
    case ApplicationType.HOME_APP: {
      return {
        type,
        name: 'Home',
      } as IApplication;
    }
    case ApplicationType.SUGGESTION_APP: {
      return {
        type,
        name: 'Search Drafting',
        data: {
          searchText: '',
          searchType: SuggestionSearchMode.SEMANTIC_SEARCH,
          filters: {
            keywords: '',
          },
          metadataTags: [],
          redlined: false,
          hightlightUnusualText: false,
          isTrigramLoaded: false,
          keyword: '',
          suggestions: [],
          badSuggestions: [],
          goodSuggestions: [],
          workspaceId: '',
          isFilterOpened: false,
          isRankingOpened: false,
          footnoteItems: ['relevancy', 'documents'],
          rankingOptions: DEFAULT_RANKING_OPTIONS,
          currentPage: SuggestionAppPage.SEARCH_SUGGESTION,
          isLoading: false,
        } as ISuggestionData,
      } as IApplication;
    }
    case ApplicationType.BROWSE_APP: {
      return {
        type,
        name: 'Browse',
      } as IApplication;
    }
    case ApplicationType.DOCUMENT_LIBRARY_APP: {
      return {
        type,
        name: 'Documents',
      } as IApplication;
    }
    case ApplicationType.CHECKLIST_APP: {
      return {
        type,
        name: 'Checklist',
      } as IApplication;
    }
    case ApplicationType.ANOMALY_DETECTION_APP: {
      return {
        type,
        name: 'Anomalies',
        data: {
          currentPage: AnomaliesPage.ANOMALIES_HOME,
          searchText: '',
        } as IAnomaliesData,
      } as IApplication;
    }
    case ApplicationType.CLAUSE_INFO_APP: {
      return {
        type,
        name: 'More Info',
        data: {} as IClauseInfoData,
      } as IApplication;
    }
    case ApplicationType.CONTENT_MANAGEMENT_APP: {
      return {
        type,
        name: 'Add Content',
        data: {},
      };
    }
    case ApplicationType.SETTINGS_APP: {
      return {
        type,
        name: 'Settings',
        data: {},
      } as IApplication;
    }
    default:
      throw new Error(`Error: Invalid or Unsupported application type.`);
  }
}

export function createApplication(type: ApplicationType, initialData?: Record<string, any>): IApplication {
  const application = createBlankApplication(type);
  if (!!initialData) {
    application.name = initialData.name ?? application.name;
    application.data = _.merge({}, application.data, _.omit(initialData, ['name']));
  }
  return application;
}
