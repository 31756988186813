import {
  Button,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  shorthands,
  Title2,
  tokens,
} from '@fluentui/react-components';
import { Person24Regular, Settings24Regular, SignOut24Regular } from '@fluentui/react-icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SuggestionTypeSwitch from '../../components/SuggestionTypeSwitch';
import { DEFAULT_APPLICATION_ID } from '../../constants';
import { ApplicationType, SuggestionAppPage, SuggestionSearchMode } from '../../enums';
import { useAppData, useContextApp, useLocalDBStore } from '../../hooks';
import { authApi } from '../../services';
import { RootState } from '../../store';
import { AppComponentProps } from '../../types/AppComponentProps';
import KeywordTransformer from '../../utils/keywordTransformer';
import ApplicationGrid from './components/ApplicationGrid';
import DropboxTextArea from './components/DropboxTextArea';
import WorkspaceMenuSelector from './components/WorkspaceMenuSelector';

export type HomeAppProps = AppComponentProps & {
  apps: Array<Record<string, any>>;
  fewerAppCount?: number;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '34px',
    flexGrow: 1,
    height: '100%',
    alignItems: 'stretch',
    ...shorthands.overflow('auto', 'hidden'),
    backgroundColor: tokens.colorNeutralBackground2,
    position: 'relative',
  },
  fullAppView: {
    paddingTop: '5%',
    ...shorthands.overflow('hidden', 'auto'),
  },
  searchContainer: {
    ...shorthands.padding('5%', '12px', 0),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '34px',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexGrow: 1,
    minWidth: '50%',
  },
  hideSearchContainer: {
    display: 'none',
  },

  title: {
    color: tokens.colorNeutralForeground2,
    fontWeight: tokens.fontWeightRegular,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '17px',
    rowGap: '17px',
    flexWrap: 'wrap',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    minWidth: '142px',
  },
  appListContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '34px',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    ...shorthands.padding(0, '12px', '5%'),
  },
  appGridTitle: {
    fontWeight: tokens.fontWeightRegular,
    color: tokens.colorNeutralForeground2,
    fontSize: tokens.fontSizeBase500,
    ...shorthands.padding(0, '5px'),
  },

  settingsContainer: {
    position: 'absolute',
    bottom: 'clamp(10px, 20px, 30px)',
    right: 'clamp(10px, 20px, 30px)',
  },
});

export const HomeApp = (props: HomeAppProps) => {
  const [logout] = authApi.useLogoutMutation();
  const [searchType, setSearchType] = useState<SuggestionSearchMode>(SuggestionSearchMode.SEMANTIC_SEARCH);
  const defaultWorkspaceId: string = useSelector(
    (state: RootState) => state.global?.appContext?.workspace_id as string,
  );

  const [workspaceId, setWorkspaceId] = useAppData<string>(
    DEFAULT_APPLICATION_ID,
    'workspaceId',
    `${defaultWorkspaceId}`,
  );

  const [fullAppView, setFullAppView] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const { createContextApp } = useContextApp();

  const { SaveSearchHistory } = useLocalDBStore();

  const styles = useStyles();

  const onMoreSearchOptionClicked: React.MouseEventHandler = _.debounce((_ev: React.MouseEvent) => {
    createContextApp(ApplicationType.SUGGESTION_APP, {
      searchType,
      searchText,
      workspaceId,
    });
  }, 500);

  const onQuickSearchClicked: React.MouseEventHandler = _.debounce((_ev: React.MouseEvent) => {
    let keywordText = '';
    if (searchType === SuggestionSearchMode.KEYWORD_SEARCH) {
      const transformer = new KeywordTransformer();
      keywordText = transformer.sanitize(searchText.trim());
    }

    const appData = {
      searchType,
      searchText,
      keywordSearchText: keywordText,
      name: `Results - ${searchText}`,
      workspaceId,
      currentPage: SuggestionAppPage.SUGGESTION_RESULT,
      from: SuggestionAppPage.SEARCH_SUGGESTION,
    };

    createContextApp(ApplicationType.SUGGESTION_APP, appData);

    SaveSearchHistory(appData as any);
  }, 500);

  const handleOpenApp = (type: ApplicationType) => {
    createContextApp(type);
  };

  const handleSearchTypeChange = (type: SuggestionSearchMode) => {
    setSearchType(type);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={styles.container}>
      <div className={mergeClasses(styles.searchContainer, fullAppView ? styles.hideSearchContainer : '')}>
        <Title2 className={styles.title}>Search for Provisions</Title2>
        <DropboxTextArea
          autoFocus={true}
          style={{
            borderColor: tokens.colorNeutralStroke1,
            boxSizing: 'content-box',
            minHeight: '80px',
            width: '100%',
          }}
          resize="none"
          value={searchText}
          onChange={setSearchText}
        />
        <SuggestionTypeSwitch type={searchType} onChange={handleSearchTypeChange} />
        <WorkspaceMenuSelector value={workspaceId} onChange={setWorkspaceId} />
        <div className={styles.actions}>
          <Button appearance="primary" className={styles.action} disabled={!searchText} onClick={onQuickSearchClicked}>
            Quick Search
          </Button>
          <Button appearance="secondary" className={styles.action} onClick={onMoreSearchOptionClicked}>
            More Search Options
          </Button>
        </div>
      </div>
      <div
        className={mergeClasses(styles.appListContainer, fullAppView ? styles.fullAppView : '')}
        style={{ visibility: 'hidden' }}
      >
        <Divider>
          <span className={styles.appGridTitle}>More Apps</span>
        </Divider>
        <ApplicationGrid
          apps={props.apps}
          isFullView={fullAppView}
          onViewChange={viewState => setFullAppView(viewState)}
          fewerAppCount={props.fewerAppCount}
          onOpenApp={handleOpenApp}
        />
      </div>
      <div className={styles.settingsContainer}>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button appearance="primary" shape="circular" icon={<Person24Regular />}></Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuItem icon={<Settings24Regular />} onClick={() => handleOpenApp(ApplicationType.SETTINGS_APP)}>
                Settings
              </MenuItem>
              <MenuItem icon={<SignOut24Regular />} onClick={handleLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};

export default HomeApp;
