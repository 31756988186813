import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { createApi } from '@reduxjs/toolkit/query/react';
import { BookmarkObject, DeleteBookmarkRequest, GetBookmarkRequest, SaveBookmarkRequest } from '../../models/requests';
import { CachedBookmarks } from '../../models/responses/bookmarks';
import { transformBookmarksResponse } from '../transformers/responses';
import { axiosBaseQuery } from './axiosConfig';

const fetchUserBookmarksQuery = (args: GetBookmarkRequest) => ({ url: '/', method: 'GET', params: args });
const saveBookmarkQuery = (args: SaveBookmarkRequest) => ({ url: '/', method: 'POST', data: args });
const deleteBookmarkQuery = (args: DeleteBookmarkRequest) => ({
  url: `/${args.id}`,
  method: 'DELETE',
  data: args,
});

const bookmarkCacheUpdate = ({ relatedId, relatedObjectType }: BookmarkObject, action: 'ADD' | 'REMOVE') => {
  const keyMap = { CLAUSE: 'clauses', DOCUMENT: 'documents', COMMENT: 'comments' };
  const objectKey = keyMap[relatedObjectType] as 'clauses' | 'documents' | 'comments';

  return (savedData: MaybeDrafted<CachedBookmarks>) => {
    if (action === 'ADD') {
      savedData[objectKey].push(relatedId);
    } else if (action === 'REMOVE') {
      const idx = savedData[objectKey].indexOf(relatedId);
      if (idx !== -1) {
        savedData[objectKey].splice(idx, 1);
      }
    }
  };
};

export const bookmarksApi = createApi({
  reducerPath: 'bookmarks',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'v3/bookmarks',
  }),
  endpoints: build => ({
    fetchUserBookmarks: build.query<CachedBookmarks, GetBookmarkRequest>({
      query: fetchUserBookmarksQuery,
      transformResponse: transformBookmarksResponse,
    }),
    saveBookmark: build.mutation<any, SaveBookmarkRequest>({
      query: saveBookmarkQuery,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const optimisticUpdate = dispatch(
          bookmarksApi.util.updateQueryData(
            'fetchUserBookmarks',
            { appContext: args.appContext },
            bookmarkCacheUpdate(args.bookmark, 'ADD'),
          ),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          console.error(err);
          optimisticUpdate.undo();
        }
      },
    }),
    deleteBookmark: build.mutation<any, DeleteBookmarkRequest>({
      query: deleteBookmarkQuery,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const optimisticDelete = dispatch(
          bookmarksApi.util.updateQueryData(
            'fetchUserBookmarks',
            { appContext: args.appContext },
            bookmarkCacheUpdate(args.bookmark, 'REMOVE'),
          ),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          console.error(err);
          optimisticDelete.undo();
        }
      },
    }),
  }),
});
