import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useCallback, useMemo } from 'react';
import AppTabBar from '../../../libs/components/AppTabBar';
import { DEFAULT_APPLICATION_ID, SUPPORTED_APPS } from '../../../libs/constants';
import { AppsComponents } from '../../../libs/constants/ComponentList';
import { useContextApp } from '../../../libs/hooks';
import GenericAppComponent from '../../../libs/meta/GenericAppComponent';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    rowGap: '5px',
    height: '100%',
    width: '100%',
    backgroundColor: tokens.colorNeutralBackground2,
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    backgroundColor: tokens.colorBrandBackground2,
    // height: '44px',
    ...shorthands.padding('5px', 0, 0, 0),
  },
  appContainer: {
    ...shorthands.flex(0, 0, 'auto'),
    flexGrow: 1,
    width: '100%',
  },
  flexCentered: {
    height: '100%',
    alignSelf: 'center',
  },
});

export default function TaskpaneApp(props: React.ComponentProps<any>) {
  const { contextApp, setContextSelectedApp, ApplicationTabs, closeContextApp } = useContextApp();

  const styles = useStyles();

  const onHomeButtonClicked = () => {
    setContextSelectedApp(DEFAULT_APPLICATION_ID);
  };

  const onCloseApp = useCallback(
    (appId: string) => {
      closeContextApp(appId);
    },
    [closeContextApp],
  );

  const component = useMemo(() => {
    return AppsComponents[contextApp.type];
  }, [contextApp.type]);

  return (
    <div className={styles.container}>
      <div className={styles.tabContainer}>
        <AppTabBar
          tabs={ApplicationTabs}
          onHomeButtonClicked={onHomeButtonClicked}
          selectedTabId={contextApp.id}
          onCloseApp={onCloseApp}
          onSelectedAppChange={(tabId: string) => setContextSelectedApp(tabId)}
        />
      </div>
      <div className={styles.appContainer}>
        <GenericAppComponent
          as={component}
          appId={contextApp.id}
          onClose={onCloseApp}
          apps={SUPPORTED_APPS}
          fewerAppCount={4}
        />
      </div>
    </div>
  );
}
