import { Subtitle2Stronger } from '@fluentui/react-components';
import React from 'react';

type EmptyPlaceholderProps = {
  text: string;
  icon?: React.ReactNode;
};

export default React.memo(({ text, icon }: EmptyPlaceholderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        opacity: 0.3,
        width: '100%',
      }}
    >
      {icon}
      <Subtitle2Stronger>{text}</Subtitle2Stronger>
    </div>
  );
});
