export default class KeywordTransformer {
  private cleanTextForTsQuery(text: string): string {
    const specialCharsRegex = new RegExp(/\s*[^a-z0-9]+\*|&+|\|+|!+|(\<\-\>)+\s*/gi, 'gi');
    return text
      .replace(specialCharsRegex, ' ')
      .replace(/\s{2,}/g, ' ')
      .trim();
  }

  private isParenthesisBalanced(text: string, i: number): boolean {
    let balances: Array<string> = [];
    for (let j = i; j < text.length; j++) {
      if (text[j] === '(') {
        balances.push('(');
      } else if (text[j] === ')') {
        balances.pop();
        if (balances.length === 0) {
          break;
        }
      }
    }

    if (balances.length) throw new Error('Missing closing paranthesis');

    return true;
  }

  private matchQuote(text: string, i: number): number {
    const balances = ['"'];
    let j = i + 1;
    for (; j < text.length; j++) {
      if (text[j] === '"') {
        balances.pop();
        if (balances.length === 0) {
          break;
        }
      }
    }

    if (balances.length) throw new Error('Missing closing quotation');

    return j;
  }

  forwardTransform(inText: string) {
    const text = this.cleanTextForTsQuery(inText);

    let transformedText = '';
    for (let i = 0; i < text.length; ) {
      switch (text[i]) {
        case '-': {
          transformedText += '!';
          i++;
          break;
        }
        case '(': {
          this.isParenthesisBalanced(text, i);
          transformedText += text[i];
          i++;
          break;
        }
        case '"': {
          const j = this.matchQuote(text, i);
          const partials = text.substring(i + 1, j);
          transformedText += partials.split(' ').join(' <-> ');
          i = j + 1;
          break;
        }
        case ' ': {
          transformedText += ' & ';
          i++;
          break;
        }
        case '*': {
          transformedText += ':*';
          i++;
          break;
        }
        case 'A': {
          if (text.substring(i - 1, i + 4) === ' AND ') {
            i += 4;
          } else {
            transformedText += text[i];
            i++;
          }
          break;
        }
        case 'O': {
          if (text.substring(i - 1, i + 3) === ' OR ') {
            transformedText = transformedText.substring(0, transformedText.length - 3) + ' | ';
            i += 3;
          } else {
            transformedText += text[i];
            i++;
          }
          break;
        }
        default: {
          transformedText += text[i];
          i++;
          break;
        }
      }
    }
    return transformedText;
  }

  sanitize(text: string) {
    let sanitizeText = '';

    const words = text.split(' ').map((word, idx, arr) => {
      const length = arr.slice(0, idx).join(' ').length;
      return { word, index: length };
    });

    words.forEach(({ word }) => {
      sanitizeText += ' ' + word;
    });

    return sanitizeText;
  }
}
