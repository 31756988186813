import { Button, tokens } from '@fluentui/react-components';
import { Home24Regular } from '@fluentui/react-icons';
import TabsContainer from './TabsContainer';
import useStyles from './styles';

export type AppTabbarProps = {
  onSelectedAppChange: (tabId: string) => void;
  onHomeButtonClicked: () => void;
  onCloseApp: (appId: string) => void;
  selectedTabId: string;
  tabs: Array<{ id: string; name: string }>;
};

export default function AppTabbar({
  tabs,
  selectedTabId,
  onSelectedAppChange,
  onHomeButtonClicked,
  onCloseApp,
}: AppTabbarProps) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Button
        appearance="transparent"
        icon={<Home24Regular color={tokens.colorBrandForeground1} />}
        onClick={onHomeButtonClicked}
      ></Button>
      <div className={styles.overflowContainer}>
        <TabsContainer
          tabs={tabs}
          selectedTab={selectedTabId}
          onCloseClicked={onCloseApp}
          onTabSelectionChange={onSelectedAppChange}
        />
      </div>
    </div>
  );
}
