import { FluentProvider, useThemeClassName } from '@fluentui/react-components';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Store, { StorePersistor } from './libs/store';
import { SyntheiaBrandWebLight } from './libs/themes';
import reportWebVitals from './reportWebVitals';
import App from './web/App';

function UseGobalTheming() {
  const classes = useThemeClassName();

  React.useEffect(() => {
    const classList = classes.split(' ');
    document.body.classList.add(...classList);

    return () => document.body.classList.remove(...classList);
  }, [classes]);

  return null;
}

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={StorePersistor}>
        <FluentProvider theme={SyntheiaBrandWebLight}>
          <UseGobalTheming />
          <BrowserRouter basename="/">
            <App />
          </BrowserRouter>
        </FluentProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
