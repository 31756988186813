import { CommentMultiple32Regular } from '@fluentui/react-icons';
import React, { useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import EmptyPlaceholder from '../../../components/EmptyPlaceholder';
import { LoadingContainer } from '../../../components/Loading';
import { Comment } from '../../../models/responses/comment';
import { commentsApi } from '../../../services';
import CommentCard from './CommentCard';

type CommentsViewerProps = {
  ownerId: string;
  workspaceId: string;
};

const onRenderComment = (idx: number, comment: Comment, context: any) => {
  const { ownerId, workspaceId } = context;

  return (
    <div key={idx} style={{ paddingRight: '5px', paddingLeft: '1px', paddingTop: '1px', paddingBottom: '15px' }}>
      <CommentCard
        appearance="filled"
        comment={comment}
        key={comment.commentId}
        relatedId={ownerId}
        workspaceId={workspaceId}
      />
    </div>
  );
};

export default React.memo(({ ownerId, workspaceId }: CommentsViewerProps) => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const { data: comments = [], isLoading } = commentsApi.useGetCommentsQuery({
    relatedId: ownerId,
    appContext: { workspaceId: workspaceId },
  });

  return (
    <LoadingContainer isLoading={isLoading} size="large" label="Loading..." labelPosition="below">
      <Virtuoso
        ref={virtuosoRef}
        data={comments}
        context={{ ownerId, workspaceId }}
        className=""
        style={{ margin: '0 10px' }}
        followOutput={(isBottom: boolean) => (isBottom ? 'smooth' : false)}
        marginHeight={5}
        itemContent={onRenderComment}
        components={{
          EmptyPlaceholder: () => <EmptyPlaceholder text="No Comments" icon={<CommentMultiple32Regular />} />,
        }}
      />
    </LoadingContainer>
  );
});
