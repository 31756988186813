import { IDocument } from '../../../interfaces';
import { DocumentResponse } from '../../../models/responses/document';

const mapResponseDocument = <T>(documents: T): IDocument[] | IDocument => {
  const mapToFE = (doc: any) => {
    return {
      doc_id: doc.documentId,
      doc_file_name: doc.title,
      doc_title: doc.title,
      id: +doc.documentId,
      doc_date: doc.date,
      is_endorsed: !!doc.endorsed,
      page_count: +doc.pageCount,
      like_status: doc.userFlags?.likeStatus,
      is_processing: !!doc.processing,
      is_favorite: !!doc.userFlags?.favorite,
      processing_status: !!doc.processing ? 'processing' : 'completed',
      tags: doc.tags,
    } as IDocument;
  };
  if (documents instanceof Array) {
    if (!documents.length) {
      return [] as IDocument[];
    }
    return documents.map(mapToFE);
  } else {
    return mapToFE(documents);
  }
};

const documentTransformer = <T, V>(documents: T): V => {
  return mapResponseDocument(documents) as V;
};

export const transformDocumentsResponse = (documents: DocumentResponse[]) => {
  return documentTransformer<DocumentResponse[], IDocument[]>(documents);
};

export const transformDocumentResponse = (documents: DocumentResponse) => {
  return documentTransformer<DocumentResponse, IDocument>(documents);
};
