import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { authApi } from '../../services';

type SettingsAppProps = {
  appId: string;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    rowGap: '1px',
    height: '100%',
    alignItems: 'stretch',
    ...shorthands.padding('12px'),
    ...shorthands.overflow('auto', 'hidden'),
  },
});

const SettingsApp = (props: SettingsAppProps) => {
  const [logout] = authApi.useLogoutMutation();
  const styles = useStyles();
  const onClickLogout = () => {
    logout();
  };

  return (
    <div className={styles.container}>
      <Button appearance="primary" onClick={onClickLogout}>
        Logout
      </Button>
    </div>
  );
};

export default SettingsApp;
