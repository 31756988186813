import { Button, Card } from '@fluentui/react-components';
import React, { useState } from 'react';
import { LoadingContainer } from '../../../components/Loading';
import { RelatedObjectType } from '../../../enums';
import { commentsApi } from '../../../services';
import { useStyles } from '../styles';
import RichCommentTextArea from './RichCommentTextArea';

type CommentEditorProps = {
  ownerId: string;
  workspaceId: string;
};

export default React.memo(({ ownerId, workspaceId }: CommentEditorProps) => {
  const [commentText, setCommentText] = useState<string>('');
  const [mentions, setMentions] = useState<Array<{ id: string; text: string; type: string }>>([]);

  const styles = useStyles();

  const [saveComment, { isLoading }] = commentsApi.useSaveCommentMutation();

  const onSaveComment = async () => {
    try {
      await saveComment({
        relatedId: ownerId,
        relatedObjectType: RelatedObjectType.CLAUSE,
        text: commentText,
        mentions,
        appContext: { workspaceId: workspaceId },
      }).unwrap();
      setCommentText('');
      setMentions([]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.commentEditor}>
      <Card>
        <RichCommentTextArea
          isLoading={isLoading}
          value={commentText}
          onChange={setCommentText}
          onMention={(id, text, type) => setMentions([...mentions, { id, text, type }])}
        />
        <LoadingContainer isLoading={isLoading}>
          <div className={styles.commentSubmit}>
            {commentText && (
              <Button appearance="primary" style={{ flex: 1 }} onClick={onSaveComment}>
                Save Comment
              </Button>
            )}
          </div>
        </LoadingContainer>
      </Card>
    </div>
  );
});
