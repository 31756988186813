export enum ApplicationType {
  HOME_APP = 1,
  SUGGESTION_APP,
  BROWSE_APP,
  DOCUMENT_LIBRARY_APP,
  CHECKLIST_APP,
  ANOMALY_DETECTION_APP,
  CLAUSE_INFO_APP,
  CONTENT_MANAGEMENT_APP,
  SETTINGS_APP,
}

export enum SuggestionAppPage {
  SEARCH_SUGGESTION = 1,
  SUGGESTION_RESULT,
  SUGGESTION_FILTER,
}

export enum SuggestionSearchMode {
  SEMANTIC_SEARCH = 1,
  KEYWORD_SEARCH,
}

export enum ClauseCardMode {
  SUGGESTION = 1,
  BROWSE,
  DETAIL,
}

export enum AnomaliesPage {
  ANOMALIES_HOME = 1,
  ANOMALIES_FORM,
  ANOMALIES_REPORT,
}

export enum DialoMode {
  AUHT_SSO = 'auth_sso',
  DOCUMENT_VIEW = 'document_view',
}

export enum RuntimeContext {
  WEB,
  OFFICE,
}

export enum QueryBuilderContext {
  CLAUSE_SEARCH = 1,
  DOCUMENT_SEARCH,
  ACTIVITY_SEARCH,
}

export enum FilterOperators {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  ANY = 'ANY',
  ALL = 'ALL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  ARRAY_OVERLAPS = 'ARRAY_OVERLAPS',
  ARRAY_CONTAINS = 'ARRAY_CONTAINS',
}

export enum USER_ROLE {
  USER = 'user',
  CONTENT_ADMIN = 'content admin',
  SYSTEM_ADMIN = 'system admin',
  SYSTEM = 'system',
}

export enum QueryFnToken {
  FULL_TEXT = 'DOCUMENT_TEXT',
  SELECTED_TEXT = 'DOCUMENT_SELECTED_TEXT',
  TOPICS = 'DOCUMENT_TOPICS',
  SYNTHEIA_ID = 'DOCUMENT_SYNTHEIA_ID',
  FILE_DOCX = 'DOCUMENT_FILE_DOCX',
}

export enum WorkerMessageType {
  UNUSUAL_TEXT_TRIGRAM_COUNTS = 'unusual-text-trigram-counts-work',
  DOCX_TO_JSON = 'docx-to-json',
}

export enum RelatedObjectType {
  CLAUSE = 'CLAUSE',
  DOCUMENT = 'DOCUMENT',
  COMMENT = 'COMMENT',
  CHECKLIST = 'CHECKLIST',
  REPORT = 'REPORT',
  HEATMAP = 'HEATMAP',
  WORKSPACE = 'WORKSPACE',
  TAG = 'TAG',
  TAG_CATEGORY = 'TAG_CATEGORY',
  USER = 'USER',
}
export enum UserObjectLikeStatus {
  LIKE = 'LIKE',
  NEUTRAL = 'NEUTRAL',
  DISLIKE = 'DISLIKE',
}
