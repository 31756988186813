import { mergeClasses, OverflowItem, Tab } from '@fluentui/react-components';
import { Dismiss16Regular } from '@fluentui/react-icons';
import React from 'react';
import useStyles from './styles';

type TabItemProps = {
  tab: { id: string; name: string };
  isSelected: boolean;
  priority?: number;
  onClose: (id: string) => void;
};

export default React.memo(({ tab, isSelected, priority, onClose }: TabItemProps) => {
  const styles = useStyles();

  return (
    <OverflowItem id={tab.id} priority={priority}>
      <Tab
        className={mergeClasses(styles.tab, styles.tabPseudo, isSelected ? styles.selectedTab : '')}
        value={tab.id}
        icon={<Dismiss16Regular onClick={_ev => onClose(tab.id)} />}
      >
        <span className={styles.tabItem} title={tab.name}>
          {tab.name}
        </span>
      </Tab>
    </OverflowItem>
  );
});
