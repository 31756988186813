import CommentEditor from './components/CommentEditor';
import CommentsViewer from './components/CommentsViewer';
import { useStyles } from './styles';

type CommentAppProps = {
  appId: string;
  ownerId: string;
  workspaceId?: string;
};

export default function CommentApp(props: CommentAppProps) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <CommentsViewer ownerId={props.ownerId} workspaceId={props.workspaceId!} />
      <CommentEditor ownerId={props.ownerId} workspaceId={props.workspaceId!} />
    </div>
  );
}
