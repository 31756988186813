import { makeStyles, shorthands } from '@fluentui/react-components';
import { useMemo } from 'react';
import MessageBar from '../../components/MessageBar';
import { AnomaliesPageComponents } from '../../constants/AnomaliesPagesComponents';
import { AnomaliesPage } from '../../enums';
import { useAppData } from '../../hooks';
import { IAnomaliesData } from '../../interfaces';
import GenericAnomaliesApp from '../../meta/GenericAnomaliesApp';
import { AppComponentProps } from '../../types/AppComponentProps';

export type AnomaliesAppProps = AppComponentProps & {
  onClose: (appId: string) => void;
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    rowGap: '1px',
    justifyContent: 'center',
    ...shorthands.overflow('auto', 'hidden'),
  },
});

const AnomaliesApp = ({ appId, onClose }: AnomaliesAppProps) => {
  const [appError] = useAppData<IAnomaliesData>(appId, 'appError');
  const [currentPage] = useAppData<AnomaliesPage>(appId, 'currentPage');

  const styles = useStyles();

  const component = useMemo(() => AnomaliesPageComponents[currentPage], [currentPage]);

  return (
    <div className={styles.container}>
      {appError && <MessageBar intent="error" layout="singleline" onDismiss={() => {}} />}
      <GenericAnomaliesApp as={component} appId={appId}>
        <div>Unsupported request</div>
      </GenericAnomaliesApp>
    </div>
  );
};

export default AnomaliesApp;
