import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  Tree,
  TreeItem,
  TreeItemLayout,
} from '@fluentui/react-components';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { AppComponentProps } from '../../types/AppComponentProps';

export type AnomaliesReportProps = AppComponentProps;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    height: '100%',
    ...shorthands.overflow('hidden'),
    alignItems: 'flex-start',
    alignContent: 'space-between',
  },
  panelTitle: {
    color: '#323130',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '21px',
    wordWrap: 'break-word',
  },
  treeContainer: {
    flexGrow: 1,
    width: '100%',
  },
  actionContainer: {
    ...shorthands.borderTop('1px', 'solid', '#EDEBE9'),
    width: 'calc(100% - 30px)',
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap('10px', '20px'),
    ...shorthands.padding('15px'),
  },
  expectedClose: {
    backgroundColor: '#E1FFE0',
  },
  expectedFar: {
    backgroundColor: '#FFFAE0',
  },
  unexpected: {
    backgroundColor: '#FFE0E6',
  },
  missing: {
    backgroundColor: '#D1D1D1',
  },
  formActionActive: {
    backgroundColor: '#CC3333',
    '&:hover': {
      backgroundColor: 'rgba(193, 51, 55, 0.8)',
    },
    '&:active': {
      backgroundColor: 'rgba(193, 51, 55, 0.8)',
    },
  },
});

const clauseMoreActionRender = () => {
  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button aria-label="More options" appearance="subtle" icon={<MoreHorizontal20Regular />} />
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItem>Some Action</MenuItem>
          <MenuItem>Another Action</MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export const AnomaliesReport = ({ appId }: AnomaliesReportProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.treeContainer}>
        <Accordion multiple={true} defaultOpenItems={[1, 2, 3]}>
          <AccordionItem value={1}>
            <AccordionHeader as="h4">
              <span className={styles.panelTitle}>Summary</span>
            </AccordionHeader>
            <AccordionPanel style={{ paddingLeft: '20px' }}>
              <p>
                There are <strong>xxx anomalies</strong> in this document when assessed against other{' '}
                <strong>[document type]s</strong> in <strong>[workspace name]</strong>.
              </p>
              <p>
                This document appears to contain xxx uncommon and unexpected clauses and it appears to be{' '}
                <strong>missing xxx commonly found clauses.</strong>
              </p>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value={2}>
            <AccordionHeader as="h4">
              <span className={styles.panelTitle}>Document Report</span>
            </AccordionHeader>
            <AccordionPanel>
              <Tree aria-label="Default" style={{ rowGap: 0 }}>
                <TreeItem itemType="branch" className={styles.expectedClose}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Clause 1 text...
                  </TreeItemLayout>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.expectedFar}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Clause 2 text...
                  </TreeItemLayout>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.expectedFar}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Clause 3 text...
                  </TreeItemLayout>
                  <Tree>
                    <TreeItem itemType="branch" className={styles.expectedClose}>
                      <TreeItemLayout
                        actions={{
                          children: clauseMoreActionRender(),
                          visible: true,
                        }}
                      >
                        Clause 3a text...
                      </TreeItemLayout>
                    </TreeItem>
                    <TreeItem itemType="branch" className={styles.expectedFar}>
                      <TreeItemLayout
                        actions={{
                          children: clauseMoreActionRender(),
                          visible: true,
                        }}
                      >
                        Clause 3b text...
                      </TreeItemLayout>
                    </TreeItem>
                  </Tree>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.unexpected}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Clause 4 text...
                  </TreeItemLayout>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.unexpected}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Clause 5 text...
                  </TreeItemLayout>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.expectedClose}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Clause 6 text...
                  </TreeItemLayout>
                </TreeItem>
              </Tree>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value={3}>
            <AccordionHeader as="h4">
              <span className={styles.panelTitle}>Possible Missing Clauses</span>
            </AccordionHeader>
            <AccordionPanel>
              <Tree aria-label="Missing" style={{ rowGap: 0 }}>
                <TreeItem itemType="branch" className={styles.missing}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Missing Clause A text...
                  </TreeItemLayout>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.missing}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Missing Clause B text...
                  </TreeItemLayout>
                </TreeItem>
                <TreeItem itemType="branch" className={styles.missing}>
                  <TreeItemLayout
                    actions={{
                      children: clauseMoreActionRender(),
                      visible: true,
                    }}
                  >
                    Missing Clause C text...
                  </TreeItemLayout>
                </TreeItem>
              </Tree>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className={styles.actionContainer}>
        <Button appearance="outline" style={{ flexGrow: 1 }}>
          Clear and Run New Report
        </Button>
        <Button appearance="primary" style={{ flexGrow: 1 }} className={styles.formActionActive}>
          Export
        </Button>
      </div>
    </div>
  );
};

export default AnomaliesReport;
