import { Text } from '@fluentui/react-components';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import Highlighter, { Chunk } from 'react-highlight-words';

export type ClauseKeywordHighlighterProps = {
  text: string;
  keywords: Array<string>;
  secondaryKeywords?: Array<string>;
  className: string;
  hasTextOverflow?: (isOverflow: boolean) => void;
};

const ClauseKeywordHighlighter: React.FC<ClauseKeywordHighlighterProps> = React.memo(props => {
  const clauseTextRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const isOverflow = clauseTextRef.current?.scrollHeight! > clauseTextRef.current?.clientHeight!;
    props.hasTextOverflow?.(!!isOverflow);
  }, [props]);

  const expandHighlightToWholeWord = ({
    searchWords,
    textToHighlight,
  }: {
    searchWords: (string | RegExp)[];
    textToHighlight: string;
  }): Chunk[] => {
    const chunks: Chunk[] = [];
    searchWords.forEach(word => {
      const pattern = typeof word === 'string' ? word : word.source;
      const regex = new RegExp(`(${_.escapeRegExp(pattern)})`, 'gi');
      let match: RegExpExecArray | null;
      while ((match = regex.exec(textToHighlight))) {
        chunks.push({
          start: match.index,
          end: match.index + match[0].length,
        });
      }
    });
    return chunks;
  };

  return (
    <Text truncate ref={clauseTextRef} className={props.className}>
      <Highlighter
        searchWords={_.concat(props.keywords, props.secondaryKeywords ?? [])}
        findChunks={expandHighlightToWholeWord}
        autoEscape={true}
        textToHighlight={props.text}
        highlightTag={({ children, highlightIndex }: { children: React.ReactNode; highlightIndex: number }) => (
          <strong
            key={highlightIndex}
            style={
              props.secondaryKeywords?.includes(children ? children.toString().toLowerCase() : '')
                ? { color: 'green' }
                : {}
            }
          >
            {children}
          </strong>
        )}
      />
    </Text>
  );
});

export default ClauseKeywordHighlighter;
