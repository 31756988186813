import {
  Avatar,
  Listbox,
  Option,
  OptionOnSelectData,
  Persona,
  Popover,
  PopoverSurface,
  PositioningImperativeRef,
  SelectionEvents,
  useComboboxFilter,
} from '@fluentui/react-components';
import { BriefcaseRegular, PersonRegular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { UsersApi, WorkspaceApi } from '../../../services';

type MentionableItemComponentProps = {
  token: string;
  searchText: string;
  positioningRef: React.RefObject<PositioningImperativeRef>;
  onSelect: (id: string, text: string, type: string) => void;
  open: boolean;
  onOpenChange: (openState: boolean) => void;
};

export default React.memo(
  ({ token, searchText, onSelect, positioningRef, open, onOpenChange }: MentionableItemComponentProps) => {
    const { data: workspaces = [] } = WorkspaceApi.useGetWorkspacesQuery();
    const { data: users = [] } = UsersApi.useGetUsersQuery();

    const options = React.useMemo(() => {
      if (token === '@') {
        return users.map(({ userId, givenName, familyName }) => ({
          avatar: <Avatar icon={<PersonRegular />} shape="square" aria-label="account" />,
          children: `${givenName} ${familyName}`,
          value: userId,
        }));
      }

      if (token === '#') {
        return workspaces.map(({ name, workspaceId }) => ({
          avatar: <Avatar icon={<BriefcaseRegular />} shape="square" aria-label="workspace" />,
          children: name,
          value: workspaceId,
        }));
      }
      return [];
    }, [token]);

    const filteredOptions = useComboboxFilter(searchText, options, {
      filter: (optionText: string, query: string) => optionText.toLowerCase().indexOf(query.toLowerCase()) !== -1,
      noOptionsMessage: 'No matches',
      optionToText: option => option.children,
      renderOption: ({ children, value, avatar }) => (
        <Option value={value} text={children} checkIcon={null} key={`${token}_${value}`}>
          <Persona name={children} size="extra-small" textAlignment="center" avatar={avatar} />
        </Option>
      ),
    });

    useEffect(() => {
      if (!filteredOptions.length) {
        onOpenChange(false);
      }
    }, [filteredOptions]);

    const onOptionSelect = (_ev: SelectionEvents, data: OptionOnSelectData) => {
      if (data.optionValue && data.optionText) {
        onSelect(data.optionValue, data.optionText, token);
        onOpenChange(false);
      }
    };

    return (
      <Popover
        appearance="brand"
        open={open}
        positioning={{ positioningRef }}
        closeOnScroll={false}
        inertTrapFocus={true}
      >
        <PopoverSurface style={{ padding: 0, borderRadius: '0' }}>
          <Listbox style={{ maxHeight: '300px' }} multiselect={false} onOptionSelect={onOptionSelect}>
            {filteredOptions}
          </Listbox>
        </PopoverSurface>
      </Popover>
    );
  },
);
