import _ from 'lodash';
import React, { useEffect, useRef } from 'react';

export interface ClauseTrigramHighlighterProps {
  text: string;
  highlightText: string;
  className?: string;
  hasTextOverflow?: (isOverflow: boolean) => void;
}

const arePropsEqual = (prevProps: ClauseTrigramHighlighterProps, nextProps: ClauseTrigramHighlighterProps) =>
  _.isEqual(prevProps, nextProps);

const ClauseTrigramHighlighter = React.memo(function ClauseTrigramHighlighter(props: ClauseTrigramHighlighterProps) {
  const clauseTextRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const isOverflow = clauseTextRef?.current?.scrollHeight! > clauseTextRef?.current?.clientHeight!;
    props.hasTextOverflow?.(!!isOverflow);
  }, [props]);

  return (
    <p
      ref={clauseTextRef}
      style={{ margin: 0 }}
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.highlightText || props.text }}
    />
  );
}, arePropsEqual);

export default ClauseTrigramHighlighter;
