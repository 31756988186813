import React from 'react';
import WorkspaceSelector from '../../../components/WorkspaceSelector';
import { useAppData } from '../../../hooks';
import { WorkspaceApi } from '../../../services';

type SuggestionWorkspaceSelector = {
  appId: string;
  userId: string;
};

const SuggestionWorkspaceSelector = React.memo(({ appId, userId }: SuggestionWorkspaceSelector) => {
  const [workspaceId, setWorkspaceId] = useAppData<string>(appId, 'workspaceId');

  const {
    data: workspaces = [],
    isLoading,
    isFetching,
    refetch,
  } = WorkspaceApi.useGetWorkspacesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      const allowedWorkspaces = data?.filter(
        w => w.active && (w.authorizedUserIds.includes('-1') || w.authorizedUserIds.includes(userId)),
      );
      return { data: allowedWorkspaces, ...rest };
    },
  });

  return (
    <WorkspaceSelector
      workspaces={workspaces}
      isLoading={isFetching || isLoading}
      selectedWorkspace={workspaceId}
      onSelectWorkspace={(selectedOption: string) => setWorkspaceId(selectedOption)}
      refreshWorkspaces={() => refetch()}
    />
  );
});

export default SuggestionWorkspaceSelector;
