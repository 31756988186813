import React from 'react';

export default function SSOIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2048 2048"
      height={props.height ?? '24px'}
      width={props.width ?? '24px'}
    >
      <path
        d="M0 268l768-107v735H0V268zM1920 0v896H896V143L1920 0zM896 1024h1024v896L896 1777v-753zm-896 0h768v735L0 1652v-628z"
        fill={props.color ?? '#0b53c0'}
      ></path>
    </svg>
  );
}
