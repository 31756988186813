import { Button, mergeClasses, Textarea, TextareaOnChangeData } from '@fluentui/react-components';
import _ from 'lodash';
import { useCallback, useRef, useState } from 'react';
import ClauseCard from '../../../libs/components/clause-card/ClauseCard';
import ClauseDocumentInfo from '../../../libs/components/ClauseDocumentInfo';
import { ApplicationType, ClauseCardMode } from '../../../libs/enums';
import CommentApp from '../../apps/CommentApp';
import DocumentReader from '../../components/DocumentReader';
import { useAppData, useContextApp } from '../../hooks';
import { IClause, IClauseInfoData, IDocument } from '../../interfaces';
import { bookmarksApi, favoritesApi, suggestionApi, trigramsApi } from '../../services';
import { useAppSelector } from '../../store';
import SelectableTabList from './components/SelectableTabList';
import SimilarClauseRenderer from './components/SimilarClauseRenderer';
import useStyles from './styles';

export type ClauseInfoAppProps = {
  appId: string;
};

export default function ClauseInfoApp({ appId }: ClauseInfoAppProps) {
  const { createContextApp } = useContextApp();

  const [updatedText, setUpdatedText] = useAppData<string>(appId, 'updatedText');
  const [selectedTab, setSelectedTab] = useAppData<string>(appId, 'selectedTab');
  const [clause] = useAppData<IClause>(appId, 'clause');
  const [similarClauseRedline, setSimilarClauseRedline] = useAppData<boolean>(appId, 'similarClauseRedline');
  const userId = useAppSelector(state => state.global.appContext?.app_user_id as string);

  const [workspaceId] = useAppData<string>(appId, 'workspaceId');

  const [toggleTextEditor, setToggleTextEditor] = useState<boolean>(false);
  const [openDocumentViewer, setOpenDocumentViewer] = useState<boolean>(false);

  const documentViewerButtonRef = useRef<HTMLButtonElement>(null);
  const mountNodeRef = useRef<HTMLDivElement>(null);
  const documentViewerRef = useRef<{ clause?: IClause; document: IDocument }>();

  const styles = useStyles();

  const {
    data: similarClausesData = [],
    isFetching: isSimilarClauseLoading,
    refetch: refetchSimilarClauses,
  } = suggestionApi.useSearchSimilarSuggestionsQuery({
    appId,
    searchText: updatedText,
    userId,
    workspaceId,
    excludeIds: [clause.clause_id ?? ''],
  });

  const { data: unusualPhrases = {} } = trigramsApi.useGetTrigramsForAppQuery(appId, {
    selectFromResult: ({ data = [], ...rest }) => {
      const ids = similarClausesData.map(s => s.clause_id);
      const suggestionsUnusualPhrases = data.filter(u => ids.includes(u.id));
      return {
        ...rest,
        data: _.keyBy(suggestionsUnusualPhrases, 'id'),
      };
    },
    skip: isSimilarClauseLoading || !similarClausesData.length,
  });

  trigramsApi.useFetchTrigramsForSimilarClausesQuery(
    {
      appId,
      clauses: similarClausesData,
      query: {
        appId,
        searchText: updatedText,
        userId,
        workspaceId,
        excludeIds: [clause.clause_id ?? ''],
      },
    },
    { skip: similarClausesData.length <= 0, refetchOnReconnect: true },
  );

  const [asyncBookmarkClause] = bookmarksApi.useSaveBookmarkMutation();
  const [asyncFavoriteClause] = favoritesApi.useSetClauseFavoriteMutation();

  const onClauseUpdateTextChange = _.debounce((_ev, data: TextareaOnChangeData) => {
    setUpdatedText(data.value as string);
  }, 200);

  const onClauseTextChangeReset = () => {
    setUpdatedText(clause.clause_text);
    setToggleTextEditor(false);
  };

  const onUpdateSimilarClauses = () => {
    setToggleTextEditor(false);
    refetchSimilarClauses();
  };

  const onClauseDetailClicked = (clause: IClause) => {
    createContextApp(ApplicationType.CLAUSE_INFO_APP, {
      name: `Info - ${clause.clause_id}`,
      clause: clause,
      updatedText: clause.clause_text,
      selectedTab: 'documentInfo',
      workspaceId: workspaceId,
      similarClauseRedline: false,
      comments: [],
      similarClauses: [],
    } as IClauseInfoData);
  };

  const onOpenClauseDocument = useCallback((document: IDocument, viewClause?: IClause) => {
    documentViewerRef.current = { document, clause: viewClause };
    setOpenDocumentViewer(true);
    documentViewerButtonRef.current?.click();
  }, []);

  const onClauseBookmark = async (clause: IClause) => {
    try {
      await asyncBookmarkClause({
        bookmark: {
          relatedId: clause.clause_id,
          relatedObjectType: 'CLAUSE',
          bookmark: 'bookmark',
        },
        appContext: { workspaceId },
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const onClauseFavorite = async (clause: IClause) => {
    try {
      await asyncFavoriteClause({
        id: clause.clause_id,
        isFavorite: true,
        appContext: { workspaceId },
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const hasTextChanged = updatedText !== clause.clause_text;

  return (
    <div className={styles.container}>
      <div className={mergeClasses(styles.overlay, !openDocumentViewer ? styles.displayNone : '')}></div>
      <DocumentReader
        mountNode={mountNodeRef.current}
        document={documentViewerRef.current?.document!}
        textBlockId={documentViewerRef.current?.clause?.clause_id}
        textBlock={documentViewerRef.current?.clause?.clause_text}
        trigger={<button style={{ visibility: 'hidden' }} ref={documentViewerButtonRef}></button>}
        onClose={() => setOpenDocumentViewer(false)}
      />
      <div className={styles.mainClauseContainer}>
        {!toggleTextEditor && (
          <ClauseCard
            mode={ClauseCardMode.DETAIL}
            clause={{ ...clause, clause_text: updatedText }}
            searchText={updatedText}
            onDoubleClick={() => setToggleTextEditor(true)}
          />
        )}

        {toggleTextEditor && (
          <Textarea
            style={{ minHeight: '150px' }}
            resize="vertical"
            defaultValue={updatedText || clause.clause_text}
            onChange={onClauseUpdateTextChange}
          />
        )}

        <div className={mergeClasses(styles.actions, hasTextChanged ? '' : styles.displayNone)}>
          <Button appearance="primary" onClick={onClauseTextChangeReset}>
            Reset Changes
          </Button>
          <Button appearance="primary" onClick={onUpdateSimilarClauses}>
            Confirm and Refresh
          </Button>
        </div>
      </div>
      <SelectableTabList value={selectedTab} onChange={setSelectedTab} />
      <div className={styles.tabContent}>
        {selectedTab === 'documentInfo' && (
          <ClauseDocumentInfo clause={clause} onOpenDocument={document => onOpenClauseDocument(document, clause)} />
        )}
        {selectedTab === 'similarClauses' && (
          <SimilarClauseRenderer
            isLoading={isSimilarClauseLoading}
            similarClauses={similarClausesData}
            originalText={updatedText}
            shouldRedline={similarClauseRedline}
            unusualPhrases={unusualPhrases}
            onSimilarClauseDetail={onClauseDetailClicked}
            onSimilarClauseRedline={enabled => setSimilarClauseRedline(enabled)}
            onOpenDocumentViewer={onOpenClauseDocument}
            onBookmarkClause={onClauseBookmark}
            onClauseFavorite={onClauseFavorite}
          />
        )}
        {selectedTab === 'comments' && (
          <CommentApp appId={appId} ownerId={clause.clause_id} workspaceId={workspaceId} />
        )}
      </div>
    </div>
  );
}
