import _ from 'lodash';
import { useCallback } from 'react';
import { SuggestionSearchMode } from '../enums';
import { ISuggestionData } from '../interfaces';
import { WorkspaceApi } from '../services';
import { useAppSelector } from '../store';
import { localDbStore } from '../store/LocalDbStore';

type SaveSearchHistoryProps = Pick<
  ISuggestionData,
  'workspaceId' | 'searchText' | 'searchType' | 'keywordSearchText' | 'metadataTags'
>;

export default function useLocalDBStore() {
  const userId = useAppSelector(state => state.global.appContext?.app_user_id as string);
  const { data: workspaces } = WorkspaceApi.useGetWorkspacesQuery();

  const getSearchTypeName = (type: SuggestionSearchMode): string => {
    if (type === SuggestionSearchMode.KEYWORD_SEARCH) {
      return 'Boolean Search (Beta)';
    }
    if (type === SuggestionSearchMode.SEMANTIC_SEARCH) {
      return 'Semantic Search';
    }
    return '';
  };

  const updateOrCreateSearchHistory = async (data: SaveSearchHistoryProps) => {
    try {
      let searchTypeName = getSearchTypeName(data.searchType);

      const workspaceName = workspaces?.find(w => w.workspaceId === data.workspaceId)?.name ?? '';

      const value = {
        userId,
        workspaceId: data.workspaceId,
        workspaceName: workspaceName,
        searchText: data.searchText,
        keywordSearchText: data.keywordSearchText ?? '',
        tags: data.metadataTags,
        searchType: data.searchType,
        searchTypeName: searchTypeName,
      };

      const prev = await localDbStore.histories
        .filter(obj => {
          return _.isEqual(_.pick(obj, ['workspaceId', 'searchText', 'userId', 'searchType']), {
            ..._.pick(data, ['workspaceId', 'searchText', 'searchType']),
            userId,
          });
        })
        .first();
      if (prev) {
        return await localDbStore.histories.update(prev, value);
      }
      return await localDbStore.histories.add({
        ...value,
        timestamps: new Date().toISOString(),
      });
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const SaveSearchHistory = useCallback(
    async (data: SaveSearchHistoryProps) => {
      return await updateOrCreateSearchHistory(data);
    },
    [userId, workspaces],
  );

  return { SaveSearchHistory };
}
