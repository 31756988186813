import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '0',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: tokens.colorBrandBackground2,
  },
  overflowContainer: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('0', 0, 0, '5px'),
    zIndex: 0,
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    minWidth: '150px',
    width: '100%',
  },
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
    width: 'fit-content',
  },
  menuButton: {
    alignSelf: 'center',
  },
  tabPseudo: {
    '::after': {
      backgroundColor: 'transparent',
    },
    '::before': {
      backgroundColor: 'transparent',
    },
  },
  tab: {
    ...shorthands.borderRadius('15px', '15px', 0, 0),
    ...shorthands.padding('8px', '10px'),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: tokens.colorBrandBackground2Hover,
    },
    '&:active': {
      backgroundColor: tokens.colorBrandBackground2Hover,
      '::after': {
        display: 'none',
      },
    },
  },
  selectedTab: {
    boxShadow: tokens.shadow2,
    '&:hover': {
      backgroundColor: 'white',
      '::after': {
        display: 'none',
      },
      '::before': {
        display: 'none',
      },
    },
    '&:active': {
      backgroundColor: tokens.colorBrandBackground2Hover,
    },
    backgroundColor: 'white',
  },
  tabItem: {
    ...shorthands.overflow('hidden'),
    display: 'block',
    maxWidth: '100px',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
