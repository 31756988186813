import {
  Button,
  Divider,
  Input,
  makeStyles,
  mergeClasses,
  shorthands,
  Subtitle2Stronger,
  Title2,
  tokens,
} from '@fluentui/react-components';
import { DocumentCopy48Regular, Search20Regular } from '@fluentui/react-icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AnomaliesPage, ApplicationType } from '../../enums';
import { useContextApp } from '../../hooks';
import { RootState } from '../../store';
import { AppComponentProps } from '../../types/AppComponentProps';

export type AnomaliesHomeProps = AppComponentProps;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '34px',
    flexGrow: 1,
    height: '100%',
    alignItems: 'stretch',
    ...shorthands.overflow('auto', 'hidden'),
    backgroundColor: tokens.colorNeutralBackground2,
    position: 'relative',
  },

  searchContainer: {
    ...shorthands.padding('5%', '12px', 0),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '34px',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexGrow: 0.3,
    minWidth: '50%',
  },
  searchInput: {
    minWidth: '100%',
  },
  inputContainer: {
    minWidth: '66%',
    '@media screen and (max-width: 400px)': { minWidth: '80%' },
  },
  title: {
    color: tokens.colorNeutralForeground2,
    fontWeight: tokens.fontWeightRegular,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '17px',
    rowGap: '17px',
    flexWrap: 'nowrap',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    minWidth: '142px',
  },
  historyContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '34px',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    ...shorthands.padding(0, '12px', '5%'),
  },
  animationIn: {
    ...shorthands.transition('all', tokens.durationNormal, tokens.durationFast, tokens.curveDecelerateMid),
  },
});

export const AnomaliesHome = ({ appId }: AnomaliesHomeProps) => {
  const defaultWorkspace = useSelector((state: RootState) => state.global.appContext?.workspace_id);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | undefined>(defaultWorkspace as string);
  const [searchText, setSearchText] = useState<string>('');
  const anomaliesHistories: any = [];
  const { createContextApp } = useContextApp();

  const styles = useStyles();

  const onStartNewReport: React.MouseEventHandler = _.debounce((_ev: React.MouseEvent) => {
    createContextApp(ApplicationType.ANOMALY_DETECTION_APP, {
      name: 'New Report',
      currentPage: AnomaliesPage.ANOMALIES_FORM,
      searchText,
      selectedDocuments: [],
      workspaceId: selectedWorkspaceId,
    });
  }, 500);

  const isValid = selectedWorkspaceId && searchText;

  return (
    <div className={styles.container}>
      <div className={mergeClasses(styles.searchContainer)}>
        <Title2 className={styles.title}>Anomalies Report</Title2>
        <div className={mergeClasses(styles.inputContainer, styles.animationIn)}>
          <Input
            className={styles.searchInput}
            contentBefore={<Search20Regular />}
            onChange={(_ev, data) => setSearchText(data.value as string)}
            placeholder="Select a document type for comparison..."
          />
        </div>
        <div className={styles.actions}>
          <Button appearance="primary" className={styles.action} disabled={!isValid} onClick={onStartNewReport}>
            Start new report
          </Button>
          {/* <WorkspaceSelector
            selectedWorkspace={selectedWorkspaceId}
            onSelectWorkspace={(selectedWorkspace: string) => setSelectedWorkspaceId(selectedWorkspace)}
          /> */}
        </div>
      </div>
      <div className={mergeClasses(styles.historyContainer)}>
        <Divider style={{ maxHeight: '20px' }}>Load Previous Report</Divider>
        {_.isEmpty(anomaliesHistories) && (
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyItems: 'center',
              alignItems: 'center',
              opacity: 0.3,
              width: '100%',
            }}
          >
            <DocumentCopy48Regular />
            <Subtitle2Stronger>No Previous report found.</Subtitle2Stronger>
          </div>
        )}
        {!_.isEmpty(anomaliesHistories) && anomaliesHistories?.map((anomalyhistory: any) => <>{anomalyhistory.id}</>)}
      </div>
    </div>
  );
};

export default AnomaliesHome;
