import { createApi } from '@reduxjs/toolkit/query/react';
import { QueryFnToken } from '../../enums';
import axiosClient from '../api/axiosConfig';
import { DocumentBaseQueryFn, QueryArg } from './DocumentBaseQuery';
import { WordDocumentService } from './WordDocumentService';

const getDocumentTopicsAsync = async (payload: any) => {
  try {
    const response = await axiosClient.post(
      'drafting/getTopics',
      { document_text: payload.documentText, appContext: payload.appContext },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const WordDocumentApi = createApi({
  reducerPath: 'WordDocumentApi',
  baseQuery: DocumentBaseQueryFn(),
  tagTypes: ['WordDocument'],
  endpoints: build => ({
    getDocumentSyntheiaId: build.query<string, void>({
      query: () => ({ baseQuery: QueryFnToken.SYNTHEIA_ID }),
    }),
    fetchDocumentTopics: build.query<string[], void>({
      query: () => ({ baseQuery: QueryFnToken.TOPICS }) as QueryArg,
    }),
    fetchDocumentFullText: build.query<string, void>({
      query: () => ({ baseQuery: QueryFnToken.FULL_TEXT }) as QueryArg,
    }),
    fetchDocumentSelectedText: build.query<string, void>({
      query: () => ({ baseQuery: QueryFnToken.SELECTED_TEXT }) as QueryArg,
    }),
    downloadDocumentFile: build.query<Uint8Array, void>({
      query: () => ({ baseQuery: QueryFnToken.FILE_DOCX }) as QueryArg,
    }),
    updateDocumentTopics: build.query<void, void>({
      queryFn: async (args: void, { dispatch, getState }) => {
        let documentTextQuery: any;
        try {
          const {
            global: { appContext: context },
          } = getState() as any;

          documentTextQuery = dispatch(
            WordDocumentApi.endpoints.fetchDocumentFullText.initiate(undefined, { forceRefetch: true }),
          );

          const docText = await documentTextQuery.unwrap();
          if (!docText) return { error: null };

          const topics: string[] = await getDocumentTopicsAsync({
            documentText: docText,
            appContext: {
              app_user_id: context.app_user_id,
              workspace_id: context.workspace_id,
            },
          });
          const DocumentService = new WordDocumentService();
          await DocumentService.setDocumentTopics(topics);
          return { error: null };
        } catch (err) {
          return { error: err };
        } finally {
          documentTextQuery.unsubscribe();
        }
      },
    }),
  }),
});
