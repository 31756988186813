import { ComponentType } from 'react';
import { AnomaliesApp, ClauseInfoApp, HomeApp, SettingsApp, SuggestionApp } from '../apps';
import { ApplicationType } from '../enums';

export const AppsComponents: Record<ApplicationType, ComponentType<any>> = {
  [ApplicationType.HOME_APP]: HomeApp,
  [ApplicationType.SUGGESTION_APP]: SuggestionApp,
  [ApplicationType.ANOMALY_DETECTION_APP]: AnomaliesApp,
  [ApplicationType.CLAUSE_INFO_APP]: ClauseInfoApp,
  [ApplicationType.SETTINGS_APP]: SettingsApp,
  [ApplicationType.DOCUMENT_LIBRARY_APP]: HomeApp,
  [ApplicationType.CONTENT_MANAGEMENT_APP]: HomeApp,
  [ApplicationType.BROWSE_APP]: HomeApp,
  [ApplicationType.CHECKLIST_APP]: HomeApp,
};
