import _ from 'lodash';
import { IClause } from '../../../interfaces';
import KeywordTransformer from '../../../utils/keywordTransformer';
import {
  boostByBookmarked,
  boostByEndorsement,
  boostByFavorite,
  boostByKeywordMatch,
  boostByPrevalence,
  boostByRecency,
} from '../../../utils/ranking';

type FeatureFlags = {
  bookmarks: Array<string>;
  userFavorites: Array<string | number>;
};

const rankByOptions = (options: any, topics?: Array<string>, featureFlags?: FeatureFlags) => {
  const bookmarkedIds: Array<string> = featureFlags?.bookmarks || [];
  const userFavorites: Array<string | number> = featureFlags?.userFavorites as Array<string | number>;

  return (suggestion: IClause) => {
    const enabledOptions = _.omitBy(options, ({ enabled }) => !enabled);
    if (_.isEmpty(enabledOptions)) {
      return suggestion;
    }
    const rankedSuggestion = { ...suggestion };
    // Reset Similarity before boosting
    rankedSuggestion.rankedSimilarity = suggestion.similarity;

    // Topics Booster
    const topicMaxWeight = _.get(enabledOptions, 'topic_match.value') as number;
    if (topicMaxWeight && !_.isEmpty(topics)) {
      rankedSuggestion.rankedSimilarity = boostByKeywordMatch(
        rankedSuggestion.clause_text,
        rankedSuggestion.rankedSimilarity! as number,
        topics,
        topicMaxWeight,
      );
    }
    // Recency Booster
    const recencyMaxWeight = _.get(enabledOptions, 'recency.value') as number;
    const rankedSuggestionDate = (rankedSuggestion.date || rankedSuggestion.created) as string;
    if (recencyMaxWeight && rankedSuggestionDate) {
      rankedSuggestion.rankedSimilarity = boostByRecency(
        rankedSuggestionDate,
        rankedSuggestion.rankedSimilarity! as number,
        recencyMaxWeight,
      );
    }

    // Prevalence Booster
    const prevalenceMaxWeight = _.get(enabledOptions, 'prevalence.value') as number;
    if (prevalenceMaxWeight && rankedSuggestion.documents) {
      rankedSuggestion.rankedSimilarity = boostByPrevalence(
        rankedSuggestion.documents.length,
        rankedSuggestion.rankedSimilarity! as number,
        prevalenceMaxWeight,
      );
    }

    // Endorsement Booster
    const endorsementMaxWeight = _.get(enabledOptions, 'endorsed.value') as number;
    if (endorsementMaxWeight && !!rankedSuggestion.is_endorsed) {
      rankedSuggestion.rankedSimilarity = boostByEndorsement(
        rankedSuggestion.rankedSimilarity! as number,
        endorsementMaxWeight,
      );
    }

    // Favorite Booster
    const favoritedMaxWeight = _.get(enabledOptions, 'favorited.value') as number;
    if (favoritedMaxWeight && userFavorites.includes(rankedSuggestion.clause_id)) {
      rankedSuggestion.rankedSimilarity = boostByFavorite(
        rankedSuggestion.rankedSimilarity! as number,
        favoritedMaxWeight,
      );
    }

    // Bookmark Booster
    const bookmarkedMaxWeight = _.get(enabledOptions, 'bookmarked.value') as number;
    if (bookmarkedMaxWeight && bookmarkedIds.includes(rankedSuggestion.clause_id)) {
      rankedSuggestion.rankedSimilarity = boostByBookmarked(
        rankedSuggestion.rankedSimilarity! as number,
        bookmarkedMaxWeight,
      );
    }
    return rankedSuggestion as IClause;
  };
};

export const filteredAndRankedByOptions = (
  suggestions: Array<IClause>,
  rankingOptions: any,
  badSuggestions: Array<string | number>,
  topics?: Array<string>,
  featureFlags?: FeatureFlags,
): Array<IClause> => {
  const applyRankings = rankByOptions(_.omit(rankingOptions, 'similarity_threshold'), topics, featureFlags);

  let chainedSuggestions = _.chain(suggestions);
  // Exclude User removed suggestions
  if (!_.isEmpty(badSuggestions)) {
    chainedSuggestions = chainedSuggestions.filter(sug => !_.includes(badSuggestions, sug.clause_id));
  }

  // Boost the ranking score and order by score
  const enabledRankingOptions = _.omitBy(rankingOptions, ({ enabled }) => !enabled);
  if (!_.isEmpty(enabledRankingOptions)) {
    const similarityThreshold = _.get(enabledRankingOptions, 'similarity_threshold.value') as number;
    const cuttOff = (similarityThreshold || 0) / 100;
    chainedSuggestions = chainedSuggestions
      .filter((sug: IClause) => (sug.similarity as number) >= cuttOff)
      .map(applyRankings)
      .orderBy(['rankedSimilarity'], ['desc']);
  } else {
    chainedSuggestions = chainedSuggestions.orderBy(['similarity'], ['desc']);
  }

  return chainedSuggestions.value();
};

export const getKeywords = (text: string) => {
  if (!text) return [];
  const transformer = new KeywordTransformer();
  const cleanText = text
    .replace(/[^A-Za-z0-9 $]/g, ' ')
    .replace(/ +/g, ' ')
    .trim();
  const keywords = transformer.sanitize(cleanText);
  return keywords.split(' ').filter((v: any) => !!v);
};
