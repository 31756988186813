import { useCallback } from 'react';
import { UnusualTextPhrases } from '../models/responses/suggestion';
import { trigramsApi } from '../services';
import { useAppDispatch } from '../store';

export default function useWorkerMessageHandlers() {
  const dispatch = useAppDispatch();

  const handleUnusualPhrases = useCallback(
    ({ appId, unusualPhrases }: { appId: string; unusualPhrases: Array<UnusualTextPhrases> }) => {
      dispatch(
        trigramsApi.endpoints.updateTrigramsForApp.initiate(
          {
            appId,
            unusualPhrases,
          },
          { track: false },
        ),
      );
    },
    [dispatch],
  );

  const handleDocxToJson = useCallback((_data: any) => {}, [dispatch]);

  return { handleUnusualPhrases, handleDocxToJson };
}
