import { PositioningImperativeRef, Textarea, tokens, useRestoreFocusTarget } from '@fluentui/react-components';
import React, { useRef } from 'react';
import MentionableItemComponent from './MentionableItemComponent';

type RichCommentEditorProps = {
  isLoading?: boolean;
  value: string;
  onChange: (value: string) => void;
  onMention: (id: string, text: string, type: string) => void;
};

const calculateMentionPosition = (textareaElement: HTMLTextAreaElement | null) => {
  if (!textareaElement) {
    return 0;
  }
  const parseValue = (v: string) => (v.endsWith('px') ? parseInt(v.slice(0, -2), 10) : 0);

  const textareaStyles = window.getComputedStyle(textareaElement);
  const font = `${textareaStyles.fontSize} ${textareaStyles.fontFamily}`;
  const paddingLeft = parseValue(textareaStyles.paddingLeft);
  const paddingRight = parseValue(textareaStyles.paddingRight);
  const textareaWidth = textareaElement.getBoundingClientRect().width - paddingLeft - paddingRight;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font;

  const words = textareaElement.value.split(' ');
  let lineCount = 0;
  let currentLine = '';
  let lines: string[] = [];
  let cursorPosition = 0;
  for (const word of words) {
    const wordWidth = context.measureText(word + ' ').width;
    const lineWidth = context.measureText(currentLine).width;

    if (lineWidth + wordWidth > textareaWidth) {
      lineCount++;
      lines.push(currentLine);
      cursorPosition = lines[lines.length - 1].length * 6;
      currentLine = word + ' ';
    } else {
      currentLine += word + ' ';
    }
  }
  if (currentLine.trim() !== '') {
    lineCount++;
    lines.push(currentLine);
    cursorPosition = lines[lines.length - 1].length * 6;
  }
  return cursorPosition;
};

export default React.memo(({ isLoading, value, onChange, onMention, ...props }: RichCommentEditorProps) => {
  const [openMention, setOpenMetion] = React.useState<boolean>(false);
  const [mentionToken, setMentionToken] = React.useState<string>('');
  const [mentionStart, setMentionStart] = React.useState<number | undefined>(undefined);
  const [searchText, setSearchText] = React.useState<string>('');
  const mentionPositioningRef = React.useRef<PositioningImperativeRef>(null);
  const mentionContainerRef = React.useRef<HTMLDivElement>(null);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    ev: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (ev.key === '@' || ev.key === '#') {
      setMentionToken(ev.key);
      setOpenMetion(true);
      setMentionStart(ev.currentTarget.selectionStart);
    } else if (openMention && ev.key !== ' ') {
      if (ev.currentTarget.value.length < (mentionStart ?? 0)) {
        setOpenMetion(false);
      } else {
        setSearchText(ev.currentTarget.value.substring(mentionStart ?? 0));
        setOpenMetion(true);
      }
    } else {
      setSearchText('');
      setOpenMetion(false);
      setMentionStart(undefined);
    }
  };

  React.useEffect(() => {
    if (mentionContainerRef.current) {
      mentionPositioningRef.current?.setTarget(mentionContainerRef.current);
    }
  }, [mentionContainerRef, mentionPositioningRef]);

  const onMentionSelect = (id: string, text: string, type: string) => {
    const postext = value.slice(mentionStart);
    onChange((value.slice(0, mentionStart) + text + postext).trim());
    onMention(id, text, type);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Textarea
        {...restoreFocusTargetAttribute}
        disabled={isLoading}
        placeholder="Write your comment @Mention #Workspace..."
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        style={{ width: '100%', borderColor: tokens.colorNeutralStroke1 }}
        textarea={{ ref: textAreaRef }}
        onKeyUp={handleKeyUp}
        value={value}
        onChange={(_, data) => onChange(data.value)}
        autoFocus
      />
      <div
        ref={mentionContainerRef}
        style={{
          position: 'absolute',
          top: '0px',
          left: `${calculateMentionPosition(textAreaRef.current) % (textAreaRef.current?.offsetWidth || 1000)}px`,
        }}
      ></div>
      <MentionableItemComponent
        token={mentionToken}
        searchText={searchText}
        positioningRef={mentionPositioningRef}
        open={openMention}
        onOpenChange={setOpenMetion}
        onSelect={onMentionSelect}
      />
    </div>
  );
});
