import {
  Button,
  Card,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  PortalProps,
  shorthands,
  Tag,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  Tooltip,
  typographyStyles,
} from '@fluentui/react-components';
import {
  ArrowCircleDown24Regular,
  ArrowCircleUp24Regular,
  BookmarkRegular,
  bundleIcon,
  CheckmarkCircle24Filled,
  CheckmarkCircle24Regular,
  ClipboardRegular,
  DismissCircle24Filled,
  DismissCircle24Regular,
  DocumentTextRegular,
  Info24Regular,
  InsertRegular,
  MoreCircle24Regular,
  StarEmphasisRegular,
} from '@fluentui/react-icons';
import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';
import { ClauseCardMode } from '../../enums';
import { useToastNotification } from '../../hooks';
import { IClause, IDocument } from '../../interfaces';
import { UnusualTextPhrases } from '../../models/responses/suggestion';
import ClauseDiffHighlighter from './ClauseDiffHighlighter';
import ClauseKeywordHighlighter from './ClauseKeywordHighlighter';
import ClauseTrigramHighlighter from './ClauseTrigramHighlighter';

export type ClauseCardProps = {
  mode: ClauseCardMode;
  searchText: string;
  isGoodSuggestion?: boolean | undefined;
  isBookmarked?: boolean;
  isFavorite?: boolean;
  isKeywordSearch?: boolean;
  clause: IClause;
  footNoteItems?: Array<string>;
  unusualPhrases?: UnusualTextPhrases;
  readlineEnabled?: boolean;
  unusualTextHighlightEnabled?: boolean;
  searchKeywords?: Array<string>;
  filterKeywords?: Array<string>;
  onAcceptClicked?: Function;
  onDeclineClicked?: Function;
  onClauseDetailClicked?: Function;
  onDoubleClick?: Function;
  onBookmarkClick?: (clause: IClause) => Promise<any>;
  onAddFavoriteClick?: (clause: IClause) => Promise<any>;
  onOpenDocument?: (document: IDocument, clause?: IClause) => void;
};

const useStyles = makeStyles({
  clauseCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    width: '100%',
  },
  clauseCard: {
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  clauseAccepted: {
    backgroundColor: 'rgba(30, 168, 44, 0.1)',
    ':hover': {
      backgroundColor: 'rgba(30, 168, 44, 0.1)',
    },
  },
  clauseEndorsed: {
    ...shorthands.border('1px', 'solid', '#33CCCC'),
  },
  clauseDeclined: {
    backgroundColor: 'rgba(164, 12, 12, 0.1)',
  },
  clauseCardContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '12px',
  },
  claueCardSideActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
  },
  clauseTextContainer: {
    ...shorthands.overflow('hidden'),
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
  },
  footNote: {
    ...typographyStyles.caption1,
  },
  clauseTextReader: {
    WebkitLineClamp: 7,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    ...shorthands.overflow('hidden'),
  },
  footNotePreferences: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    flexWrap: 'nowrap',
  },
  ellipsisText: {
    ...shorthands.overflow('hidden'),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '88%',
  },
  ellipsisTextWithOthers: {
    ...shorthands.overflow('hidden'),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '63%',
  },
});

const ClauseAcceptedIcon = bundleIcon(CheckmarkCircle24Filled, CheckmarkCircle24Regular);
const ClauseDeclinedIcon = bundleIcon(DismissCircle24Filled, DismissCircle24Regular);

export type ClauseTextRenderProps = {
  clause: IClause;
  searchText: string;
  primaryKeywords?: Array<string>;
  secondaryKeywords?: Array<string>;
  shouldRedline: boolean;
  shouldHighlightUnusualText: boolean;
  highlightText?: string;
  shouldHighlightKeywords: boolean;
  isKeywordSearch?: boolean;
  className: string;
  hasTextOverflow?: (isOverflow: boolean) => void;
};

const ClauseTextRender = function (props: ClauseTextRenderProps) {
  if (props.shouldRedline) {
    return (
      <ClauseDiffHighlighter
        key={`${props.clause.clause_id}_redlined`}
        originalText={props.searchText}
        text={props.clause.clause_text}
        improveReadability={true}
        className={props.className}
        hasTextOverflow={props.hasTextOverflow}
      />
    );
  }

  if (props.shouldHighlightUnusualText) {
    return (
      <ClauseTrigramHighlighter
        key={`${props.clause.clause_id}_unusual_text_highlight`}
        text={props.clause.clause_text}
        highlightText={props.highlightText || ''}
        className={props.className}
        hasTextOverflow={props.hasTextOverflow}
      />
    );
  }

  return (
    <ClauseKeywordHighlighter
      text={props.clause.clause_text}
      keywords={props.shouldHighlightKeywords || props.isKeywordSearch ? props.primaryKeywords ?? [] : []}
      secondaryKeywords={props.secondaryKeywords ?? []}
      className={props.className}
      key={`${props.clause.clause_id}_keyword_highlight`}
      hasTextOverflow={props.hasTextOverflow}
    />
  );
};

export default function ClauseCard(props: ClauseCardProps & Pick<PortalProps, 'mountNode'>) {
  const [accepted, setAccepted] = useState<boolean>(() => {
    return !!props.isGoodSuggestion;
  });
  const [expanded, setExpanded] = useState<boolean>(false);

  const [hasTextOverflow, setHasTextOverflow] = useState<boolean>(false);

  const { toasterId, notify, dismissNotification } = useToastNotification();

  const styles = useStyles();

  const onMarkClauseAsAccepted: React.MouseEventHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (props.onAcceptClicked && !accepted) {
      props.onAcceptClicked(props.clause);
    }
    setAccepted(true);
  };

  const onMarkClauseAsDeclined: React.MouseEventHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (props.onDeclineClicked) {
      props.onDeclineClicked(props.clause);
    }
    setAccepted(false);
  };

  const onMoreInfoClicked: React.MouseEventHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (props.onClauseDetailClicked) {
      props.onClauseDetailClicked(props.clause);
    }
  };

  const onExpandClicked: React.MouseEventHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    setExpanded(!expanded);
  };

  const handleClauseDrag: React.DragEventHandler = (ev: React.DragEvent) => {
    ev.dataTransfer.setData('text/plain', props.clause.clause_text);
  };

  const ToastMessage = useCallback(
    (message: string) => {
      return (
        <Toast>
          <ToastTitle
            action={
              <ToastTrigger>
                <Link onClick={() => dismissNotification()}>Dismiss</Link>
              </ToastTrigger>
            }
          >
            {message}
          </ToastTitle>
        </Toast>
      );
    },
    [dismissNotification],
  );

  const onCopyToClipboard = useCallback(() => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(props.clause.clause_text);
      notify(ToastMessage('Clause copied to clipboard successfully.'), 'success');
    } else {
      notify(ToastMessage('Unsupported browser for clipboard copy.'), 'error');
    }
  }, [notify, ToastMessage, props.clause.clause_text]);

  const onAddToBookmark = useCallback(() => {
    props
      .onBookmarkClick?.(props.clause)
      .then(() => notify(ToastMessage('Clause bookmarked successfully.'), 'success'))
      .catch((err: any) => notify(ToastMessage(`Error bookmarking. ${err.message}`), 'error'));
  }, [notify, ToastMessage, props]);

  const onAddToFavorite = useCallback(() => {
    props
      .onAddFavoriteClick?.(props.clause)
      .then(() => notify(ToastMessage('Clause added to favorites successfully.'), 'success'))
      .catch((err: any) => notify(ToastMessage(`Error adding to favorite. ${err.message}`), 'error'));
  }, [notify, ToastMessage, props]);

  const FootNoteContent = useCallback(() => {
    let content = [];
    if (props.footNoteItems?.includes('relevancy')) {
      content.push(
        <div key={`footnote_relevance_${props.clause.clause_id}`}>
          <span className={styles.footNote}>
            {((props.clause.rankedSimilarity as number) * 100).toFixed(2) || 'XX'}%{' '}
            {props.mode === ClauseCardMode.BROWSE ? 'similar' : 'relevant'}
          </span>
        </div>,
      );
    }

    if (props.footNoteItems?.includes('documents')) {
      const length = _.defaultTo(props.clause.documents?.length, 0);
      let documentFootNote;
      if (props.clause.documents && length) {
        documentFootNote = (
          <div key={`footnote_docTitle_${props.clause.clause_id}`}>
            <span className={styles.footNote}>
              Found in{' '}
              <Link
                className={length - 1 > 0 ? styles.ellipsisTextWithOthers : styles.ellipsisText}
                appearance="subtle"
                onClick={() => props.onOpenDocument?.(props.clause.documents?.[0]!, props.clause)}
              >
                <strong>{props.clause.documents[0].doc_title}</strong>
              </Link>
            </span>
            {length - 1 > 0 && (
              <span key={`footnote_docTitle_${props.clause.clause_id}_${length}`} className={styles.footNote}>
                {' '}
                and {length - 1} other {pluralize('document', length - 1)}.
              </span>
            )}
          </div>
        );
      } else {
        documentFootNote = (
          <div key={`footnote_noDocs_${props.clause.clause_id}`}>
            <span className={styles.footNote}>No document reference found.</span>
          </div>
        );
      }
      content.push(documentFootNote);
    }

    if (props.footNoteItems?.includes('preferences')) {
      if (props.isBookmarked || props.isFavorite || props.clause.is_endorsed) {
        content.push(
          <div key={'footnote_preferences'} className={styles.footNotePreferences}>
            {props.isBookmarked && (
              <Tag size="extra-small" style={{ color: 'red' }} icon={<BookmarkRegular />}>
                Bookmarked
              </Tag>
            )}
            {props.isFavorite && (
              <Tag size="extra-small" style={{ color: '#f2c661' }} icon={<StarEmphasisRegular />}>
                Favorite
              </Tag>
            )}
            {props.clause.is_endorsed && (
              <Tag size="extra-small" style={{ color: 'purple' }} icon={<StarEmphasisRegular />}>
                Endorsed
              </Tag>
            )}
          </div>,
        );
      }
    }

    return content;
  }, [props, styles.footNote, styles.footNotePreferences]);

  return (
    <div className={styles.clauseCardContainer} style={{ marginBottom: '10px', marginRight: '15px' }}>
      <Toaster toasterId={toasterId} position={window.Office ? 'bottom-start' : 'bottom-end'}></Toaster>
      <Card
        className={mergeClasses(
          styles.clauseCard,
          accepted ? styles.clauseAccepted : '',
          props.clause.is_endorsed ? styles.clauseEndorsed : '',
        )}
        onDoubleClick={() => props.onDoubleClick?.()}
      >
        <div className={styles.clauseCardContentWrapper}>
          {/* action items */}
          <div className={styles.claueCardSideActionContainer}>
            {props.mode === ClauseCardMode.SUGGESTION && (
              <>
                <Tooltip content="Like" relationship="label" positioning="after">
                  <Button
                    size="small"
                    appearance="transparent"
                    icon={<ClauseAcceptedIcon color="#1EA82C" filled={accepted} />}
                    onClick={onMarkClauseAsAccepted}
                  />
                </Tooltip>
                <Tooltip content="Dislike" relationship="label" positioning="after">
                  <Button
                    size="small"
                    appearance="transparent"
                    icon={<ClauseDeclinedIcon color="#A40C0C" />}
                    onClick={onMarkClauseAsDeclined}
                  />
                </Tooltip>
              </>
            )}
            {props.mode !== ClauseCardMode.DETAIL && (
              <>
                <Tooltip content="More Information" relationship="label" positioning="after">
                  <Button
                    size="small"
                    appearance="transparent"
                    icon={<Info24Regular color="#242424" />}
                    onClick={onMoreInfoClicked}
                  />
                </Tooltip>
                <Menu positioning={'after-bottom'}>
                  <MenuTrigger disableButtonEnhancement>
                    <Tooltip content="Options" relationship="label" positioning="after">
                      <Button size="small" appearance="transparent" icon={<MoreCircle24Regular color="#242424" />} />
                    </Tooltip>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem icon={<InsertRegular />} disabled>
                        Smart insert
                      </MenuItem>
                      <MenuItem
                        icon={<DocumentTextRegular />}
                        onClick={() => props.onOpenDocument?.(props.clause.documents?.[0]!, props.clause)}
                      >
                        View Document
                      </MenuItem>
                      <MenuItem icon={<ClipboardRegular />} onClick={onCopyToClipboard}>
                        Copy text to clipboard
                      </MenuItem>
                      <MenuItem icon={<BookmarkRegular />} onClick={onAddToBookmark}>
                        Add to bookmark
                      </MenuItem>
                      <MenuItem icon={<StarEmphasisRegular />} onClick={onAddToFavorite}>
                        Add to favorite
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </>
            )}
            {(hasTextOverflow || expanded) && (
              <Tooltip content="Expand/Collapse" relationship="label" positioning="after">
                <Button
                  style={{ marginTop: 'auto' }}
                  size="small"
                  appearance="transparent"
                  icon={
                    expanded ? <ArrowCircleUp24Regular color="#2F80ED" /> : <ArrowCircleDown24Regular color="#2F80ED" />
                  }
                  onClick={onExpandClicked}
                />
              </Tooltip>
            )}
          </div>
          {/* Clause text area */}
          <div draggable onDragStart={handleClauseDrag} className={mergeClasses(styles.clauseTextContainer)}>
            <ClauseTextRender
              clause={props.clause}
              searchText={props.searchText}
              primaryKeywords={props.searchKeywords}
              secondaryKeywords={props.filterKeywords}
              shouldRedline={props.readlineEnabled!}
              shouldHighlightUnusualText={props.unusualTextHighlightEnabled!}
              highlightText={props.unusualPhrases?.highlightedText || ''}
              shouldHighlightKeywords={props.footNoteItems?.includes('bold_keywords')!}
              isKeywordSearch={props.isKeywordSearch}
              hasTextOverflow={isTextOverlow => setHasTextOverflow(isTextOverlow || expanded)}
              className={mergeClasses(expanded ? '' : styles.clauseTextReader)}
            />
          </div>
        </div>
      </Card>
      {/* Foot info */}
      {props.mode !== ClauseCardMode.DETAIL && (
        <div key={`footnote_container_${props.clause.clause_id}`}>{FootNoteContent()}</div>
      )}
    </div>
  );
}
