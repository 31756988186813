import React from 'react';
import AnomaliesApp from '../apps/AnomaliesApp';
import ClauseInfoApp from '../apps/ClauseInfoApp';
import HomeApp from '../apps/HomeApp';
import SettingsApp from '../apps/SettingsApp';
import SuggestionApp from '../apps/SuggestionApp';
import { ApplicationType } from '../enums';

type ApplicationComponentType = { [P in keyof ApplicationType]: ApplicationType[P] };

type ApplicationTypeToken =
  | ApplicationType.ANOMALY_DETECTION_APP
  | ApplicationType.CLAUSE_INFO_APP
  | ApplicationType.HOME_APP
  | ApplicationType.SETTINGS_APP
  | ApplicationType.SUGGESTION_APP;

type ComponentType<T extends ApplicationComponentType> = T extends ApplicationType.ANOMALY_DETECTION_APP
  ? React.ElementType<typeof AnomaliesApp>
  : T extends ApplicationType.CLAUSE_INFO_APP
    ? React.ElementType<typeof ClauseInfoApp>
    : T extends ApplicationType.HOME_APP
      ? React.ElementType<typeof SettingsApp>
      : T extends ApplicationType.SETTINGS_APP
        ? React.ElementType<typeof HomeApp>
        : T extends ApplicationType.SUGGESTION_APP
          ? React.ElementType<typeof SuggestionApp>
          : React.ElementType;

type GenericAppComponentType<C extends React.ElementType = ComponentType<ApplicationTypeToken>> = {
  as: C;
} & React.ComponentProps<C>;

export default function GenericAppComponent<T extends ApplicationComponentType>({
  as,
  children,
  ...props
}: GenericAppComponentType<ComponentType<T>>) {
  const Component = as || 'div';

  return <Component {...props}>{children}</Component>;
}
