import { createApi } from '@reduxjs/toolkit/query/react';
import _, { clone, cloneDeep } from 'lodash';
import { logout, setAppContext, setSSOEnabled, setSSOFallbackURL } from '../../store/slices/globalSlice';
import { axiosBaseQuery } from './axiosConfig';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { resetAppSlice } from '../../store/slices/appsSlice';
import { bookmarksApi } from './bookmarks';
import { commentsApi } from './comment';
import { documentApi } from './documents';
import { favoritesApi } from './favorites';
import { suggestionApi } from './suggestion';
import { tagsApi } from './tags';

export type UserCredential = {
  username: string;
  password: string;
  code: string;
  hostname: string;
};

const updateAppContext = (appContext: any, dispatch: ThunkDispatch<any, any, any>) => {
  const transformedAppContext = transformAppContext(appContext);
  if (transformedAppContext.isLoggedIn) {
    dispatch(setAppContext(transformedAppContext));
  } else {
    dispatch(setAppContext(null));
  }
};

const transformAppContext = (appContext: any): any => {
  const context = cloneDeep(appContext);
  if (context?.workspaces) {
    context.workspaces = _.orderBy(context.workspaces, [w => w.name.toLowerCase()], ['asc']);
  }
  return context;
};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'drafting',
  }),
  tagTypes: ['Auth'],
  endpoints: build => ({
    getAppContext: build.query<any, void>({
      query: () => ({
        url: '/getAppContext',
        method: 'POST',
        includeAppContext: false,
      }),
      transformResponse: (response: any) => response.appContext,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAppContext(data, dispatch);
      },
    }),
    getAzureStatus: build.query<any, void>({
      queryFn: async (_args: void, { dispatch }, _extraOptions, baseQuery) => {
        const { data: azStatusData, error: azSatatusError } = await baseQuery({
          url: '/azure/status',
          method: 'GET',
          includeAppContext: false,
        });
        const enabled = !azSatatusError && !!azStatusData?.enabled;
        dispatch(setSSOEnabled(enabled));
        if (enabled) {
          const isOfficeAddin = window.location.pathname.includes('/word');
          const hostname = `${window.location.protocol}//${window.location.host}${
            isOfficeAddin ? '/word/dialog.html' : ''
          }`;
          const { data: azUrlData, error: azUrlError } = await baseQuery({
            url: `/azure/url?hostname=${hostname}`,
            method: 'GET',
          });
          const azUrl = !!azUrlError ? '' : azUrlData?.url ?? '';
          dispatch(setSSOFallbackURL(azUrl));
        }
        return { data: { ssoEnabled: enabled } };
      },
    }),
    login: build.mutation<any, Pick<UserCredential, 'username' | 'password'>>({
      query: args => ({
        url: '/login',
        method: 'POST',
        data: args,
        includeAppContext: false,
      }),
      transformResponse: (response: any) => response.appContext,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAppContext(data, dispatch);
      },
    }),
    loginAzureADToken: build.mutation<any, string>({
      query: args => ({
        url: '/azure/addinAzLogin',
        method: 'POST',
        data: {},
        headers: {
          Authorization: args,
        },
        includeAppContext: false,
      }),
      transformResponse: (response: any) => response.appContext,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAppContext(data, dispatch);
      },
    }),
    loginAzureAD: build.mutation<any, Pick<UserCredential, 'code' | 'hostname'>>({
      query: args => ({
        url: '/azure/login',
        method: 'GET',
        params: args,
        includeAppContext: false,
      }),
      transformResponse: (response: any) => response.appContext,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        updateAppContext(data, dispatch);
      },
    }),
    logout: build.mutation<any, void>({
      query: () => ({ url: '/logout', method: 'POST' }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        dispatch(logout());
        dispatch(resetAppSlice());
        dispatch(bookmarksApi.util.resetApiState());
        dispatch(commentsApi.util.resetApiState());
        dispatch(documentApi.util.resetApiState());
        dispatch(favoritesApi.util.resetApiState());
        dispatch(suggestionApi.util.resetApiState());
        dispatch(tagsApi.util.resetApiState());
      },
    }),
  }),
});
