import {
  DialogSurfaceProps,
  makeStyles,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  shorthands,
  toMountNodeProps,
} from '@fluentui/react-components';
import { useState } from 'react';
import DocumentReaderApp from '../../apps/DocumentReaderApp';
import { IDocument } from '../../interfaces';

export type DocumentReaderProps = {
  document: IDocument;
  textBlockId?: string | number;
  textBlock?: string;
  surfaceClassName?: string;
  trigger: JSX.Element;
  onClose?: () => void;
};

const useStyles = makeStyles({
  surface: {
    maxHeight: '90vh',
    '@media screen and (max-width: 600px)': { maxWidth: '90vw', width: '90vw' },
    '@media screen and (min-width: 601px) and (max-width: 10000px)': {
      maxWidth: '430px',
      width: '430px',
    },
    overflowY: 'hidden',
    overflowX: 'hidden',
    ...shorthands.padding('5px', '10px'),
  },
});

export default function DocumentReader(props: DocumentReaderProps & Pick<DialogSurfaceProps, 'mountNode'>) {
  const [open, setOpen] = useState<boolean>(true);
  const styles = useStyles();

  const moutNodeProps = toMountNodeProps(props.mountNode);
  moutNodeProps.className = mergeClasses(styles.surface, props.surfaceClassName);

  const onOpenChange = (state: boolean) => {
    setOpen(state);
    if (!state) {
      props.onClose?.();
    }
  };

  return (
    <Popover open={open && !!props.document} onOpenChange={(_ev, data) => onOpenChange(data.open)}>
      <PopoverTrigger disableButtonEnhancement>{props.trigger}</PopoverTrigger>
      <PopoverSurface className={mergeClasses(styles.surface, props.surfaceClassName)}>
        <DocumentReaderApp
          doc={props.document}
          textBlockId={props.textBlockId}
          textBlock={props.textBlock}
          onClose={() => onOpenChange(false)}
        />
      </PopoverSurface>
    </Popover>
  );
}
