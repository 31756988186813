import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import {
  DeleteCommentRequest,
  GetCommentsRequest,
  SaveCommentRequest,
  UpdateCommentRequest,
} from '../../models/requests/comment';
import { Comment, GetCommentsResponse, RawCommentEntityMap, SaveCommentResponse } from '../../models/responses/comment';
import { axiosBaseQuery } from './axiosConfig';

const saveCommentPreProcessing = (args: SaveCommentRequest) => {
  const { mentions } = args;
  let mentionsMap: RawCommentEntityMap = { entityMap: {}, workspaceMap: {} };
  let htmlText = args.text;

  _.uniqWith(mentions, (a, b) => a.id === b.id && a.type === b.type).forEach(
    ({ id, text, type }: { id: string; text: string; type: string }) => {
      const mentionedText = type ? `${type}${text}` : null;
      const isStillMentioned = mentionedText && args.text.indexOf(mentionedText) !== -1;
      if (isStillMentioned) {
        if (type === '@') {
          mentionsMap.entityMap[id] = { data: { mention: { id, text } } };
        } else if (type === '#') {
          mentionsMap.workspaceMap[id] = { data: { mention: { id, text } } };
        }
        htmlText = htmlText.replaceAll(
          mentionedText,
          `<a data-mention-type="${type}" data-mention-id="${id}" data-mention-text="${text}">${text}</a>`,
        );
      }
    },
  );

  htmlText = `<p>${htmlText}</p>`;

  return { raw: { entityMap: mentionsMap.entityMap }, htmlText };
};

const getCommentsQuery = (args: GetCommentsRequest) => ({
  url: '/search',
  method: 'POST',
  data: { filter: { relatedId: args.relatedId }, appContext: args.appContext },
});

const saveCommentQuery = (args: SaveCommentRequest) => {
  const { raw, htmlText } = saveCommentPreProcessing(args);

  return {
    url: '/',
    method: 'POST',
    data: {
      comment: {
        relatedId: args.relatedId,
        relatedObjectType: args.relatedObjectType,
        text: htmlText,
      },
      raw,
      appContext: args.appContext,
    },
  };
};

const updateCommentQuery = (args: UpdateCommentRequest) => {
  const { raw, htmlText } = saveCommentPreProcessing(_.omit(args, ['id']) as SaveCommentRequest);
  return {
    url: `/${args.id}`,
    method: 'PUT',
    data: {
      id: args.id,
      comment: {
        commentId: args.id,
        text: htmlText,
      },
      raw,
      appContext: args.appContext,
    },
  };
};

const deleteCommentQuery = (args: DeleteCommentRequest) => {
  return { url: `/${args.id}`, method: 'DELETE', data: { id: args.id, appContext: args.appContext } };
};

export const commentsApi = createApi({
  reducerPath: 'comments',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'v3/comments',
  }),
  tagTypes: ['Comments'],
  endpoints: build => ({
    getComments: build.query<Comment[], GetCommentsRequest>({
      query: getCommentsQuery,
      transformResponse: ({ comments }: GetCommentsResponse) => comments || [],
    }),
    saveComment: build.mutation<SaveCommentResponse, SaveCommentRequest>({
      query: saveCommentQuery,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const {
          data: { comment },
        } = await queryFulfilled;
        dispatch(
          commentsApi.util.updateQueryData(
            'getComments',
            {
              relatedId: args.relatedId,
              appContext: args.appContext,
            } as GetCommentsRequest,
            saveData => {
              if (saveData.length <= 0) {
                saveData.push(comment);
              } else {
                saveData.unshift(comment);
              }
            },
          ),
        );
      },
    }),
    updateComment: build.mutation<SaveCommentResponse, UpdateCommentRequest>({
      query: updateCommentQuery,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { comment },
          } = await queryFulfilled;
          if (!_.isEmpty(comment)) {
            dispatch(
              commentsApi.util.updateQueryData(
                'getComments',
                {
                  relatedId: args.relatedId,
                  appContext: args.appContext,
                } as GetCommentsRequest,
                savedData => {
                  const idx = savedData.findIndex(val => val.commentId.toString() === args.id.toString());
                  savedData[idx] = comment;
                },
              ),
            );
          }
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteComment: build.mutation<any, DeleteCommentRequest>({
      query: deleteCommentQuery,
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            commentsApi.util.updateQueryData(
              'getComments',
              {
                relatedId: args.relatedId,
                appContext: args.appContext,
              } as GetCommentsRequest,
              savedData => {
                savedData.splice(
                  savedData.findIndex(val => val.commentId.toString() === args.id.toString()),
                  1,
                );
              },
            ),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});
