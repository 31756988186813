import {
  Avatar,
  Body1,
  Button,
  Caption1,
  Card,
  CardHeader,
  makeStyles,
  shorthands,
  tokens,
} from '@fluentui/react-components';
import { Delete20Regular, Dismiss20Regular, Edit20Regular, Save20Regular } from '@fluentui/react-icons';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { LoadingContainer } from '../../../../components/Loading';
import { RelatedObjectType } from '../../../../enums';
import { Comment } from '../../../../models/responses/comment';
import { commentsApi } from '../../../../services';
import RichCommentTextArea from '../RichCommentTextArea';
import { createReactElement } from './helper';

export type CommentProps = {
  comment: Comment;
  relatedId: string;
  workspaceId: string;
  appearance: 'filled' | 'filled-alternative' | 'outline' | 'subtle' | undefined;
};

type CommentActionsProps = {
  isEditable: boolean;
  isDeletable: boolean;
  isEditing: boolean;
  isLoading?: boolean;
  className?: string;
  loaderClassName?: string;
  onToggleEdit: () => void;
  onSaveClick: () => void;
  onDeleteClick: () => void;
};

type CommentHtmlRendererProps = {
  node: Node;
  className?: string;
  mentionClassName?: string;
};

const useStyles = makeStyles({
  mention: {
    ...shorthands.textDecoration('none'),
    fontWeight: 'bold',
    '&[data-mention-type="@"]': {
      color: tokens.colorBrandForeground2,
    },
    '&[data-mention-type="#"]': {
      color: tokens.colorPaletteRoyalBlueForeground2,
    },
    '&[data-hyperlink]': {
      color: tokens.colorBrandForegroundLink,
    },
  },
  commentWrapper: {
    '& p': {
      ...shorthands.margin(0),
    },
  },
  fullWidth: {
    width: '100%',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    flexWrap: 'nowrap',
  },
  actionLoader: {
    width: 'auto',
  },
});

const CommentActions = React.memo(
  ({
    isEditable,
    isDeletable,
    isEditing,
    isLoading,
    className,
    loaderClassName,
    onToggleEdit,
    onSaveClick,
    onDeleteClick,
  }: CommentActionsProps) => {
    return (
      <LoadingContainer isLoading={!!isLoading} size="extra-tiny" className={loaderClassName}>
        <div className={className}>
          {isEditable && !isEditing && (
            <Button
              appearance="transparent"
              size="small"
              icon={<Edit20Regular />}
              aria-label="Edit"
              onClick={onToggleEdit}
            />
          )}
          {isDeletable && !isEditing && (
            <Button
              appearance="transparent"
              size="small"
              icon={<Delete20Regular />}
              aria-label="Delete"
              onClick={onDeleteClick}
            />
          )}
          {isEditing && (
            <>
              <Button
                appearance="transparent"
                size="small"
                icon={<Save20Regular />}
                aria-label="save"
                onClick={onSaveClick}
              />
              <Button
                appearance="transparent"
                size="small"
                icon={<Dismiss20Regular />}
                aria-label="Cancel"
                onClick={onToggleEdit}
              />
            </>
          )}
        </div>
      </LoadingContainer>
    );
  },
);

const CommentHtmlRenderer = React.memo(({ node, className, mentionClassName }: CommentHtmlRendererProps) => {
  return <div className={className}>{createReactElement(node)}</div>;
});

export default function CommentCard({ comment, appearance, relatedId, workspaceId }: CommentProps) {
  const styles = useStyles();
  // For legacy comments support
  const htmlContent = /<\/?[a-z][\s\S]*>/i.test(comment.text) ? comment.text : comment.URL;
  const defaultMentions: { id: string; text: string; type: string }[] = [];
  const parser = new DOMParser();
  const parsedHTML = parser.parseFromString(htmlContent, 'text/html');
  parsedHTML.body.querySelectorAll('a').forEach(a => {
    const mentionType: string = a.dataset.mentionType as string;
    const mentionText: string = a.dataset.mentionText as string;
    const mentionId: string = a.dataset.mentionId as string;
    const isHyperLink = a.dataset.hyperlink;
    if (!isHyperLink) {
      a.href = '#';
      a.innerHTML = `${mentionType}${mentionText}`;
      defaultMentions.push({ text: mentionText, type: mentionType, id: mentionId });
    }
    a.className = styles.mention;
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editableText, setEditableText] = useState<string>(() => parsedHTML.body.textContent as string);
  const [mentions, setMentions] = useState<Array<{ id: string; text: string; type: string }>>(() => defaultMentions);

  const [saveComment, { isLoading: isUpdating }] = commentsApi.useUpdateCommentMutation();
  const [deleteComment, { isLoading: isDeleting }] = commentsApi.useDeleteCommentMutation();

  const handleDeleteComment = (id: string) => {
    deleteComment({ id, relatedId, appContext: { workspaceId: workspaceId } });
  };

  const handleUpdateComment = async () => {
    try {
      await saveComment({
        id: comment.commentId,
        mentions,
        relatedObjectType: RelatedObjectType.CLAUSE,
        text: editableText,
        relatedId,
        appContext: { workspaceId: workspaceId },
      }).unwrap();
      setIsEditing(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnToggleEdit = useCallback(
    (value: boolean) => {
      if (!value) {
        setEditableText(parsedHTML.body.textContent as string);
      }
      setIsEditing(value);
    },
    [setEditableText, setIsEditing, parsedHTML.body],
  );

  const username = `${comment.user.givenName} ${comment.user.familyName}`;

  return (
    <Card appearance={appearance} className={styles.fullWidth}>
      <CardHeader
        image={<Avatar name={username} color="colorful" />}
        header={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            <Body1>
              <b>{username}</b>
            </Body1>
            <CommentActions
              isDeletable={comment.isDeletable}
              isEditable={comment.isEditable}
              isLoading={isUpdating || isDeleting}
              isEditing={isEditing}
              className={styles.actionContainer}
              loaderClassName={styles.actionLoader}
              onToggleEdit={() => handleOnToggleEdit(!isEditing)}
              onDeleteClick={() => handleDeleteComment(comment.commentId)}
              onSaveClick={handleUpdateComment}
            />
          </div>
        }
        description={<Caption1>{moment(comment.time).format('LLLL')}</Caption1>}
      ></CardHeader>
      {!isEditing && (
        <CommentHtmlRenderer
          className={styles.commentWrapper}
          mentionClassName={styles.mention}
          node={parsedHTML.body.firstChild!}
        />
      )}
      {isEditing && (
        <RichCommentTextArea
          onChange={setEditableText}
          onMention={(id, text, type) => setMentions([...mentions, { id, text, type }])}
          value={editableText}
        />
      )}
    </Card>
  );
}
