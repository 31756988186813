import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { AppsListDetail20Regular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import AppListItem from '../../../components/AppListItem';
import { ApplicationType } from '../../../enums';

type ApplicationGridProps = {
  apps: Array<Record<string, any>>;
  isFullView: boolean;
  fewerAppCount?: number;
  onViewChange: (viewState: boolean) => void;
  onOpenApp: (type: ApplicationType) => void;
};

const useStyles = makeStyles({
  appGrid: {
    display: 'grid',
    alignSelf: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(20%, 1fr))',
    width: '80%',
    gridAutoRows: 'minmax(auto, auto)',
    ...shorthands.gap('20px'),
  },
});

const ApplicationGrid = React.memo(
  ({ apps, isFullView, fewerAppCount, onViewChange, onOpenApp }: ApplicationGridProps) => {
    const visibleApps = useMemo(() => {
      if (isFullView) {
        return apps;
      }
      return apps.slice(0, fewerAppCount ?? 4);
    }, [apps, isFullView, fewerAppCount]);

    const styles = useStyles();

    return (
      <>
        <div className={styles.appGrid}>
          {visibleApps.map((app, idx) => (
            <AppListItem
              key={`${app.name}_${idx}`}
              inactive={!app.active}
              name={app.shortName}
              type={app.type}
              description={app.description}
              onClick={() => onOpenApp(app.type)}
            />
          ))}
        </div>
        <div style={{ alignSelf: 'center' }}>
          <Button icon={<AppsListDetail20Regular />} iconPosition="before" onClick={() => onViewChange(!isFullView)}>
            {isFullView ? 'Show Fewer Apps' : 'Show All Apps'}
          </Button>
        </div>
      </>
    );
  },
);

export default ApplicationGrid;
