import _ from 'lodash';
import moment from 'moment';

export function boostByKeywordMatch(
  clauseText: string,
  baseSimilarity: number,
  topics: Array<string> = [],
  maxWeight: number = 1.0,
): number {
  if (!topics || _.isEmpty(topics)) {
    return baseSimilarity;
  }
  let multiplier: number = 0;
  const lowerClauseText = clauseText.toLowerCase();
  topics.forEach(topic => {
    if (lowerClauseText.indexOf(topic.toLowerCase()) !== -1) {
      multiplier += 0.01;
    }
  });
  const booster: number = Math.min(0.1, multiplier * maxWeight);

  return Math.min(baseSimilarity * (1.0 + booster), 1.0);
}

export function boostByRecency(clauseDate: string, baseSimilarity: number, maxWeight: number = 0.0): number {
  const dateToMoment = moment(clauseDate);
  const diffMonths = moment().diff(dateToMoment, 'months');

  let multiplier: number = 0.0;
  if (diffMonths <= 3) {
    multiplier = 0.1;
  } else if (diffMonths <= 6) {
    multiplier = 0.07;
  } else if (diffMonths <= 12) {
    multiplier = 0.04;
  }

  return Math.min(baseSimilarity * (1.0 + multiplier * maxWeight), 1.0);
}

export function boostByPrevalence(documentCount: number, baseSimilarity: number, maxWeight: number = 1.0): number {
  if (documentCount <= 1) {
    return baseSimilarity;
  }
  let multiplier: number = Math.min(0.01 * documentCount, 0.2);
  return Math.min(baseSimilarity * (1.0 + multiplier * maxWeight), 1.0);
}

export function boostByEndorsement(baseSimilarity: number, maxWeight: number = 1.0) {
  return Math.min(baseSimilarity * (1.0 + 0.1 * maxWeight), 1.0);
}

export function boostByFavorite(baseSimilarity: number, maxWeight: number = 1.0) {
  return Math.min(baseSimilarity * (1.0 + 0.1 * maxWeight), 1.0);
}

export function boostByBookmarked(baseSimilarity: number, maxWeight: number = 1.0) {
  return Math.min(baseSimilarity * (1.0 + 0.1 * maxWeight), 1.0);
}
