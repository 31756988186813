import { Label } from '@fluentui/react-components';
import _ from 'lodash';
import MetadataSelector from '../MetadataSelector';

export type GroupedMetadataSelectorProps = {
  labels: Array<string>;
  groupedTags: Record<string, Array<any>>;
  selectedOptions: Record<string, Array<any>>;
  onChange: (selectedOptions: Record<string, Array<any>>) => void;
};

export default function GroupedMetadataSelector({
  labels,
  groupedTags,
  selectedOptions,
  onChange,
  ...props
}: GroupedMetadataSelectorProps) {
  const onGroupSelectionChange = (label: string, options: Array<string>) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [label]: options,
    };
    onChange(newSelectedOptions);
  };

  if (_.isEmpty(labels)) {
    return <></>;
  }

  return (
    <>
      {labels.map((label: string) => {
        return (
          <MetadataSelector
            group_id={_.capitalize(label)}
            selected={selectedOptions[label]}
            tags={groupedTags[label]}
            key={label}
            label={<Label weight="semibold">{_.capitalize(label)}</Label>}
            onSelectedTagsChange={(selectedTags: Array<string>) => onGroupSelectionChange(label, selectedTags)}
          />
        );
      })}
    </>
  );
}
