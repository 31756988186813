import { Overflow, TabList } from '@fluentui/react-components';
import React from 'react';
import OverflowMenu from './OverflowMenu';
import TabItems from './TabItems';

type TabsContainerProps = {
  tabs: Array<{ id: string; name: string }>;
  selectedTab: string;
  onTabSelectionChange: (id: string) => void;
  onCloseClicked: (id: string) => void;
};

export default React.memo(({ tabs, selectedTab, onTabSelectionChange, onCloseClicked }: TabsContainerProps) => {
  return (
    <Overflow padding={0} overflowDirection="start">
      <TabList
        size="small"
        style={{ columnGap: '1px', backgroundColor: 'transparent' }}
        defaultSelectedValue={selectedTab}
        selectedValue={selectedTab}
        onTabSelect={(_ev, data) => onTabSelectionChange(data.value as string)}
      >
        <>
          <OverflowMenu tabs={tabs} onTabSelect={onTabSelectionChange} onClose={onCloseClicked} />
          <TabItems tabs={tabs} selectedTab={selectedTab} onClose={onCloseClicked} />
        </>
      </TabList>
    </Overflow>
  );
});
