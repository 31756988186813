import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { QueryFnToken } from '../../enums';
import IDocumentService from './IDocumentService';
import { WordDocumentService } from './WordDocumentService';

type DocumentHandleFn = (args?: any) => Promise<string | string[] | Uint8Array>;

type DocumentBasedQuery = Record<QueryFnToken, DocumentHandleFn>;

export type QueryArg = {
  baseQuery: QueryFnToken;
} & Record<string, any>;

export type MutationArg = {};

export const DocumentBaseQueryFn = <ResultType = unknown, ErrorType = unknown>(): BaseQueryFn<
  QueryArg,
  ResultType,
  ErrorType
> => {
  const DocumentService: IDocumentService = new WordDocumentService();

  const BaseQueryFns: DocumentBasedQuery = {
    [QueryFnToken.FULL_TEXT]: DocumentService.getDocumentText,
    [QueryFnToken.SELECTED_TEXT]: DocumentService.getSelectedText,
    [QueryFnToken.TOPICS]: DocumentService.getDocumentTopics,
    [QueryFnToken.SYNTHEIA_ID]: DocumentService.getDocumentId,
    [QueryFnToken.FILE_DOCX]: DocumentService.getDocumentFileDocx,
  };

  return async ({ baseQuery }, api) => {
    try {
      const QueryFn = BaseQueryFns[baseQuery];
      if (!QueryFn) {
        throw new Error('Unsupported Document Operation.');
      }
      const data = await QueryFn();
      return { data: data as ResultType };
    } catch (err) {
      return { error: err as ErrorType };
    }
  };
};
