import React from 'react';

export default function BrowseAppIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 5.25C0 2.35051 2.3505 0 5.25 0H34.75C37.6495 0 40 2.3505 40 5.25V34.75C40 37.6495 37.6495 40 34.75 40H5.25C2.35051 40 0 37.6495 0 34.75V5.25Z"
        fill="#FFD02F"
      />
      <path
        d="M10 25L13.75 15H15.9L19.65 25H17.6L16.75 22.6H12.9L12.1 25H10ZM13.5 20.9H16.1L14.9 17.15H14.75L13.5 20.9ZM21.7 25V23.1L26.75 16.8H21.9V15H28.95V16.9L23.95 23.2H29V25H21.7ZM17 13L20 10L23 13H17ZM20 30L17 27H23L20 30Z"
        fill="black"
      />
    </svg>
  );
}
