import { WorkerMessageType } from '../../../enums';
import { useWorkerMessageHandlers } from '../../../hooks';
import { UnusualTextPhrases } from '../../../models/responses/suggestion';

type MessageData = {
  type: WorkerMessageType;
  error: any;
  data: any;
};

export const HandleWorkerMessage = () => {
  const { handleUnusualPhrases, handleDocxToJson } = useWorkerMessageHandlers();

  return (message: MessageEvent<MessageData>) => {
    try {
      const { type, error, data } = message.data;
      if (!!error) throw error;
      switch (type) {
        case WorkerMessageType.UNUSUAL_TEXT_TRIGRAM_COUNTS: {
          handleUnusualPhrases(data as { appId: string; unusualPhrases: Array<UnusualTextPhrases> });
          break;
        }
        case WorkerMessageType.DOCX_TO_JSON: {
          handleDocxToJson(data);
          break;
        }
        default: {
          console.error('Unsupported worker message type');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
};
