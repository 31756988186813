import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { axiosBaseQuery } from './axiosConfig';

export type TagCategory = {
  tagCategoryId: string;
  name: string;
  system: boolean;
  active: boolean;
  tags: Partial<Tag>[];
};

export type Tag = {
  tagId: string;
  tagCategoryId: string;
  name: string;
  system: boolean;
  active: boolean;
  relatedId: string | null;
  restrictedToUserIds: string[];
  sameAsTagIds: string[];
  parentTagId: string | null;
  modelStorageName: string | null;
  externalId: string | null;
};

type APIResponse = {
  tagCategories: TagCategory[];
  tags: Tag[];
  groupedTags: Record<string, Array<Tag>>;
  labels: Array<string>;
};

export const tagsApi = createApi({
  reducerPath: 'metadataTags',
  baseQuery: axiosBaseQuery<APIResponse>({
    baseUrl: 'v3/tags',
  }),
  tagTypes: ['Tags'],
  endpoints: build => ({
    fetchTags: build.query<APIResponse, void>({
      queryFn: async (args, { getState, dispatch }, extraOptions, baseQuery) => {
        const { data, error } = await baseQuery({
          url: '',
          method: 'GET',
          params: { appContext: {} },
        });
        if (!!error) return { error };
        const tags = data?.tags || [];
        const tagCategories =
          data?.tagCategories.map((tagCategory: TagCategory) => ({
            ...tagCategory,
            tags: tags.filter(tag => tag.tagCategoryId === tagCategory.tagCategoryId),
          })) || [];

        const categories = data?.tagCategories ?? [];
        const groupedTags = _.groupBy(data?.tags ?? [], tag => {
          const category = _.find(categories, {
            tagCategoryId: tag.tagCategoryId,
          });
          return category?.name;
        });

        const labels = _.filter(
          _.map(categories, ct => ct.name),
          (label: string) => groupedTags[label]?.length,
        ) as Array<string>;

        return {
          data: {
            tags: _.sortBy(tags, t => t.name.toLowerCase()),
            tagCategories,
            groupedTags,
            labels,
          },
        };
      },
      keepUnusedDataFor: 900,
      providesTags: ['Tags'],
    }),
  }),
});
