import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import MetaDataSelector from '../../../components/MetadataSelector';
import { useAppData } from '../../../hooks';
import { tagsApi } from '../../../services';

type SuggestionMetadataSelectorProps = {
  appId: string;
};

const SuggestionMetadataSelector = React.memo(({ appId }: SuggestionMetadataSelectorProps) => {
  const [metadataTags, setMetadataTags] = useAppData<Array<string>>(appId, 'metadataTags');
  const [filters, setFilters] = useAppData<Record<string, any>>(appId, 'filters', {});

  const { data: tagsData, isLoading } = tagsApi.useFetchTagsQuery();

  const tags = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return tagsData?.tags || [];
  }, [isLoading, tagsData]);

  useEffect(() => {
    const selectedTagsFilter = _.groupBy(_.pick(_.keyBy(tagsData?.tags, 'tagId'), metadataTags), 'tagType');
    _.forOwn(selectedTagsFilter, (value, key) => _.update(selectedTagsFilter, key, () => _.map(value, 'tagId')));
    setFilters({
      keywords: filters.keywords ?? '',
      ...selectedTagsFilter,
    });
  }, [metadataTags]);

  const onSelectedMetadataTagsChange = (tags: Array<string>) => {
    setMetadataTags(tags);
  };

  return (
    <MetaDataSelector
      isLoading={isLoading}
      tags={tags}
      selected={metadataTags}
      onSelectedTagsChange={onSelectedMetadataTagsChange}
    />
  );
});

export default SuggestionMetadataSelector;
