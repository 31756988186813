import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { IClause } from '../../interfaces';
import { SearchSuggestionRequest, SimilarSuggestionSearch } from '../../models/requests';
import { boostByKeywordMatch } from '../../utils/ranking';
import { WordDocumentService } from '../document/WordDocumentService';
import { similarSuggestionRequestBuilder, suggestionRequestBuilder } from '../transformers/requests';
import { transformSuggestionsResponse } from '../transformers/responses';

import { updateApp } from '../../store/slices/appsSlice';
import { axiosBaseQuery } from './axiosConfig';

const searchSuggestionQuery = (data: SearchSuggestionRequest) => {
  const requestBody = suggestionRequestBuilder(data);
  return { url: '/clauses/suggestions/search', method: 'POST', data: requestBody };
};

const loadMoreSuggestionQuery = (data: { orignalQuery: SearchSuggestionRequest; pageCount: number }) => {
  const requestBody = suggestionRequestBuilder(data.orignalQuery);
  requestBody.filter.limit = data.pageCount > 1 ? data.pageCount * 100 : 100;
  return { url: '/clauses/suggestions/search', method: 'POST', data: requestBody };
};

const similarSuggestionSearchQuery = (data: SimilarSuggestionSearch) => {
  const requestBody = similarSuggestionRequestBuilder(data);
  return { url: '/clauses/suggestions/search', method: 'POST', data: requestBody };
};

export const suggestionApi = createApi({
  reducerPath: 'suggestionApi',
  baseQuery: axiosBaseQuery<any>({
    baseUrl: 'v3',
  }),
  tagTypes: ['Suggestions'],
  endpoints: build => ({
    searchSuggestions: build.query<IClause[], SearchSuggestionRequest>({
      query: args => searchSuggestionQuery(args),
      transformResponse: ({ suggestions = [] }) => transformSuggestionsResponse(suggestions),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          dispatch(updateApp({ id: args.appId, field: 'isLoading', valueOrUpdater: true }));
          let topics: Array<string> = [];
          if (window.Office) {
            topics = await new WordDocumentService().getDocumentTopics();
          }
          const { data } = await queryFulfilled;
          const suggestions =
            !topics || _.isEmpty(topics) || !_.isArray(topics)
              ? data
              : _.map(
                  data,
                  sug =>
                    ({
                      ...sug,
                      similarity: boostByKeywordMatch(sug.clause_text, sug.similarity as number, topics),
                    }) as IClause,
                );

          dispatch(updateApp({ id: args.appId, field: 'suggestions', valueOrUpdater: suggestions }));
          dispatch(updateApp({ id: args.appId, field: 'moreAvailable', valueOrUpdater: suggestions.length > 0 }));
        } catch (err) {
          console.error(err);
        } finally {
          dispatch(updateApp({ id: args.appId, field: 'isLoading', valueOrUpdater: false }));
        }
      },
    }),
    loadMoreSuggestions: build.mutation<IClause[], { orignalQuery: SearchSuggestionRequest; pageCount: number }>({
      query: args => loadMoreSuggestionQuery(args),
      transformResponse: ({ suggestions = [] }) => transformSuggestionsResponse(suggestions),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          dispatch(updateApp({ id: args.orignalQuery.appId, field: 'isLoadMore', valueOrUpdater: true }));
          const { data } = await queryFulfilled;
          dispatch(
            updateApp({
              id: args.orignalQuery.appId,
              field: 'suggestions',
              valueOrUpdater: (prev: IClause[]) => prev.concat(data),
            }),
          );
        } catch (err) {
          console.error(err);
        } finally {
          dispatch(updateApp({ id: args.orignalQuery.appId, field: 'isLoadMore', valueOrUpdater: false }));
        }
      },
    }),
    searchSimilarSuggestions: build.query<IClause[], SimilarSuggestionSearch>({
      query: args => similarSuggestionSearchQuery(args),
      transformResponse: ({ suggestions = [] }) => transformSuggestionsResponse(suggestions),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateApp({ id: args.appId, field: 'similarSuggestions', valueOrUpdater: data }));
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});
