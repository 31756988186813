import { makeStyles, shorthands } from '@fluentui/react-components';
import { useDeferredValue, useMemo, useState } from 'react';
import { LoadingContainer } from '../../components/Loading';
import { useAppData } from '../../hooks';
import { IDocument } from '../../interfaces';
import { documentApi } from '../../services';
import { useAppSelector } from '../../store';
import { DocumentReaderList } from './components/DocumentReaderList';
import { DocumentReaderToolbar } from './components/DocumentReaderToolbar';
import { escapeRegExp } from 'lodash';

export type DocumentReaderAppProps = {
  doc: IDocument;
  textBlockId?: string | number;
  textBlock?: string;
  onClose?: () => void;
};

const useStyles = makeStyles({
  DocumentReaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    rowGap: '12px',
    ...shorthands.overflow('hidden', 'hidden'),
    position: 'relative',
    boxSizing: 'border-box',
  },
  listContainer: {
    width: '100%',
    height: '75vh',
  },
  loadingContainer: {},
});

export default function DocumentReaderApp({ doc, onClose, textBlock, textBlockId }: DocumentReaderAppProps) {
  const [searchText, setSearchText] = useState<string>('');
  const searchQuery = useDeferredValue(searchText);
  const [activeMark, setActiveMark] = useState(0);

  const selectedAppId: string = useAppSelector(state => state.apps.selectedApp);
  const [workspaceId] = useAppData<string>(selectedAppId, 'workspaceId');
  const styles = useStyles();
  const { data: documentText, isLoading } = documentApi.useGetDocumentTextQuery(
    { id: doc.doc_id, workspaceId },
    { skip: !workspaceId },
  );

  const filteredSearchList: number[] = useMemo(() => {
    const indices: number[] = [];
    if (searchText && documentText) {
      documentText.nodes.forEach(({ text }, index) => {
        const regex = new RegExp(escapeRegExp(searchText), 'gi'); // 'gi' for global and case-insensitive searchText
        const matches = Array.from(text.matchAll(regex));
        indices.push(...matches.map(match => index));
      });
    }
    return indices;
  }, [documentText, textBlockId, searchText]);

  return (
    <div className={styles.DocumentReaderContainer}>
      <DocumentReaderToolbar
        doc={doc}
        searchText={searchText}
        filteredSearchLength={filteredSearchList.length}
        setSearchText={setSearchText}
        onClose={onClose}
        activeMark={activeMark}
        setActiveMark={setActiveMark}
      />
      <div className={styles.listContainer}>
        <LoadingContainer isLoading={isLoading} size="medium" label={'Downloading document ...'} labelPosition="below">
          <DocumentReaderList
            documentNodes={documentText?.nodes || []}
            filteredSearchList={filteredSearchList}
            searchText={searchQuery}
            textBlockId={textBlockId?.toString()}
            activeMark={activeMark}
          />
        </LoadingContainer>
      </div>
    </div>
  );
}
