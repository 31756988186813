import { Button, MenuItem, MenuSplitGroup, useIsOverflowItemVisible } from '@fluentui/react-components';
import { Dismiss16Regular } from '@fluentui/react-icons';
import React from 'react';

type OverflowMenuItemProps = {
  tab: Record<string, any>;
  onClose: React.MouseEventHandler;
  onClick: React.MouseEventHandler;
};

export default React.memo(({ tab, onClick, onClose }: OverflowMenuItemProps) => {
  const isVisible = useIsOverflowItemVisible(tab.id);

  if (isVisible) {
    return null;
  }

  return (
    <MenuSplitGroup key={tab.id} as="div">
      <Button appearance="subtle" icon={<Dismiss16Regular />} onClick={onClose} />

      <MenuItem onClick={onClick} icon={null}>
        <span>{tab.name}</span>
      </MenuItem>
    </MenuSplitGroup>
  );
});
