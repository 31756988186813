import _ from 'lodash';
import { FilterOperators, QueryBuilderContext } from '../enums';
import { textToTsQueryText } from './helpers';

type QueryConjuction = 'AND' | 'OR';
type QueryRule = { field: string; operator: string; value: any };
type NodeRules = Array<{ rule: QueryRule } | { group: { conjunction: QueryConjuction; nodes: NodeRules } }>;
type FilterQuery = { conjunction: QueryConjuction; nodes: NodeRules; not?: boolean | undefined };

export default class SearchQueryBuilder {
  private queryBuilderContext: QueryBuilderContext;

  constructor(context: QueryBuilderContext) {
    this.queryBuilderContext = context;
  }

  private buildClauseQuery(values: Record<string, any>): FilterQuery {
    const { keywordSearchText, searchText, tagsIds, excludeClauses, isBooleanSearch } = values;
    const nodes: NodeRules = [];
    if (isBooleanSearch) {
      nodes.push({
        rule: {
          field: 'keywords',
          operator: FilterOperators.EQUAL,
          value: textToTsQueryText(keywordSearchText.trim()),
        } as QueryRule,
      });
    } else {
      nodes.push({ rule: { field: 'primaryText', operator: FilterOperators.EQUAL, value: searchText } as QueryRule });
    }

    if (excludeClauses instanceof Array && excludeClauses.length) {
      const badSugGroups: NodeRules = [];
      for (let excludeId of excludeClauses) {
        badSugGroups.push({
          rule: {
            field: 'clauseId',
            operator: FilterOperators.NOT_EQUAL,
            value: excludeId,
          },
        });
      }
      nodes.push({
        group: {
          conjunction: 'AND',
          nodes: badSugGroups,
        },
      });
    }

    if (tagsIds && tagsIds instanceof Array && tagsIds.length) {
      nodes.push({ rule: { field: 'tagIds', operator: FilterOperators.ARRAY_OVERLAPS, value: tagsIds } as QueryRule });
    }

    return { conjunction: 'AND', nodes };
  }

  private buildDocumentQuery(values: Record<string, any>): FilterQuery {
    const { docIds, title } = values;
    const nodes: NodeRules = [];

    if (docIds instanceof Array && docIds.length) {
      const docIdsGroup: NodeRules = [];
      docIds.forEach((docId: string) => {
        docIdsGroup.push({
          rule: {
            field: 'documentId',
            operator: FilterOperators.EQUAL,
            value: docId,
          },
        });
      });

      if (docIdsGroup && docIdsGroup.length) {
        nodes.push({
          group: {
            conjunction: 'OR',
            nodes: docIdsGroup,
          },
        });
      }
    }

    if (docIds instanceof String || docIds instanceof Number) {
      nodes.push({
        rule: {
          field: 'documentId',
          operator: FilterOperators.EQUAL,
          value: docIds as string,
        },
      });
    }

    if (title && !_.isEmpty(title)) {
      nodes.push({
        rule: {
          field: 'title',
          operator: FilterOperators.CONTAINS,
          value: title as string,
        },
      });
    }

    return { conjunction: 'AND', nodes };
  }

  private buildActivityQuery(values: Record<string, any>): FilterQuery {
    return { conjunction: 'AND', nodes: [] };
  }

  getQueryField<T>(value: FilterQuery, constraint: [string, string], field: string): T {
    const result = value.nodes.find(v => _.get(v, constraint[0]) === constraint[1]);
    return _.get(result, field) as T;
  }

  buildQuery(values: Record<string, any>): FilterQuery {
    switch (this.queryBuilderContext) {
      case QueryBuilderContext.CLAUSE_SEARCH: {
        return this.buildClauseQuery(values);
      }
      case QueryBuilderContext.DOCUMENT_SEARCH: {
        return this.buildDocumentQuery(values);
      }
      case QueryBuilderContext.ACTIVITY_SEARCH: {
        return this.buildActivityQuery(values);
      }
      default: {
        return { conjunction: 'AND', nodes: [] };
      }
    }
  }
}
