import { makeStyles, shorthands } from '@fluentui/react-components';
import MessageBar from '../../components/MessageBar';
import { SuggestionAppPage } from '../../enums';
import { useAppData } from '../../hooks';
import { ISuggestionData } from '../../interfaces';
import GenericSuggestionApp from '../../meta/GenericSuggestionApp';
import SuggestionResult from '../../pages/SuggestionResult';
import SuggestionSearchForm from '../../pages/SuggestionSearchForm';

export type SuggestionAppProps = {
  appId: string;
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    rowGap: '1px',
    justifyContent: 'center',
    ...shorthands.overflow('auto', 'hidden'),
  },
});

export default function SuggestionApp({ appId }: SuggestionAppProps) {
  const [appError] = useAppData<ISuggestionData>(appId, 'appError');
  const [currentPage] = useAppData<SuggestionAppPage>(appId, 'currentPage');

  const styles = useStyles();

  const component =
    currentPage === SuggestionAppPage.SEARCH_SUGGESTION
      ? SuggestionSearchForm
      : currentPage === SuggestionAppPage.SUGGESTION_RESULT
        ? SuggestionResult
        : null;

  return (
    <div className={styles.container}>
      {appError && (
        <MessageBar
          intent="error"
          layout="singleline"
          // TODO: LOOK AT IT BAKARY
          //   error={appError}
          onDismiss={() => {}}
        />
      )}
      <GenericSuggestionApp as={component} appId={appId}>
        <div>Unsupported request.</div>
      </GenericSuggestionApp>
    </div>
  );
}
