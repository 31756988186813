import { IClause } from '../../../interfaces';
import { DocumentResponse } from '../../../models/responses/document';
import { SuggestionResponse } from '../../../models/responses/suggestion';
import { transformDocumentsResponse } from './document';

const mapResponseSuggestions = <T>(suggesitons: T[] | T): IClause[] | IClause => {
  const mapToFE = (sug: any) => {
    return {
      clause_id: sug.clauseId,
      textBlockId: sug.textBlockId,
      clause_text: sug.text,
      is_endorsed: !!sug.endorsed,
      title: sug.title,
      similarity: sug.similarity,
      rankedSimilarity: sug.similarity,
      id: +sug.clauseId,
      date: sug.date,
      created: sug.created,
      is_favorite: !!sug.userFlags?.favorite,
      like_status: sug.userFlags?.likeStatus !== 'NEUTRAL',
      tags: sug.tags,
      documents: transformDocumentsResponse(sug.documents as DocumentResponse[]),
    } as IClause;
  };
  if (suggesitons instanceof Array) {
    if (!suggesitons.length) {
      return [] as Array<IClause>;
    }
    return suggesitons.map(mapToFE);
  } else {
    return mapToFE(suggesitons);
  }
};

const suggestionTransformer = <T, V>(suggestions: T): V => {
  return mapResponseSuggestions(suggestions) as V;
};

export const transformSuggestionsResponse = (suggestions: SuggestionResponse[]) => {
  return suggestionTransformer<SuggestionResponse[], IClause[]>(suggestions);
};

export const transformSuggestionResponse = (suggestion: SuggestionResponse) => {
  return suggestionTransformer<SuggestionResponse, IClause>(suggestion);
};
