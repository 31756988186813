import { useSelector } from 'react-redux';
import { authApi } from '../services';
import { RootState } from '../store';

export default function useAuth() {
  const isSSOEnabled = useSelector((state: RootState) => state.global.ssoEnabled as boolean);
  const ssoFallbackURL = useSelector((state: RootState) => state.global.ssoFallbackURL as string);
  const appContext = useSelector((state: RootState) => state.global.appContext);
  const isLoggedIn = useSelector((state: RootState) => state.global.appContext?.isLoggedIn);

  const { isLoading: isLoadingAppContext } = authApi.useGetAppContextQuery(undefined, {
    skip: !!appContext,
  });

  const { isLoading: isLoadingAzureStatus } = authApi.useGetAzureStatusQuery(undefined, {
    skip: !!appContext,
  });

  const [credentialLogin] = authApi.useLoginMutation();
  const [loginWithAzureADToken] = authApi.useLoginAzureADTokenMutation();
  const [loginWithAzureAD] = authApi.useLoginAzureADMutation();

  const login = async (username: string, password: string) => {
    try {
      await credentialLogin({ username, password }).unwrap();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const loginAzureADToken = async (accessToken: string) => {
    try {
      await loginWithAzureADToken(accessToken);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const loginAzureAD = async (code: string, hostname: string) => {
    try {
      await loginWithAzureAD({ code, hostname });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    isSSOEnabled,
    ssoFallbackURL,
    isLoggedIn,
    isLoading: isLoadingAppContext || isLoadingAzureStatus,
    login,
    loginAzureADToken,
    loginAzureAD,
  };
}
