import { ApplicationType } from '../enums';

export const DEFAULT_APPLICATION_ID = 'app_17004310880732';

export type AppSettings = {
  type: ApplicationType;
  name: string;
  shortName: string;
  description: string;
  active: boolean;
};

export const SUPPORTED_APPS: AppSettings[] = [
  {
    type: ApplicationType.DOCUMENT_LIBRARY_APP,
    description: 'Search for and open precedent documents from your documents library',
    name: 'Load a document',
    shortName: 'Load Document',
    active: false,
  },
  {
    type: ApplicationType.SUGGESTION_APP,
    description: 'Search for clause suggestions using meaning or keyword',
    name: 'Find drafting Suggestion',
    shortName: 'Search Drafting',
    active: true,
  },
  {
    type: ApplicationType.BROWSE_APP,
    description: 'Browse your collection of clauses and definitions',
    name: 'Browse clauses and definitions',
    shortName: 'Browse Provisions',
    active: false,
  },
  {
    type: ApplicationType.ANOMALY_DETECTION_APP,
    description: 'Analyze the content of your document against your database for anomalies',
    name: 'Detect Anomalies',
    shortName: 'Detect Anomalies',
    active: false,
  },
  {
    type: ApplicationType.CHECKLIST_APP,
    description: 'Open guidance checklists or build your own content and tasks checklists',
    name: 'Create Tasklist',
    shortName: 'Create Tasklist',
    active: false,
  },
  {
    type: ApplicationType.CHECKLIST_APP,
    description: 'Open guidance checklists or build your own content and tasks checklists',
    name: 'Open tasklist',
    shortName: 'Open tasklist',
    active: false,
  },
  {
    type: ApplicationType.CONTENT_MANAGEMENT_APP,
    description: 'Add content to the database',
    name: 'Add content to the database',
    shortName: 'Add Content',
    active: false,
  },
  {
    type: ApplicationType.SETTINGS_APP,
    description: 'Customize features and preferences in one place',
    name: 'Settings',
    shortName: 'Settings',
    active: true,
  },
];

export const DEFAULT_RANKING_OPTIONS = {
  similarity_threshold: {
    enabled: false,
    value: 50,
    min: 0,
    max: 100,
    postfix: '%',
  },
  topic_match: { enabled: false, value: 1, min: 0, max: 2, postfix: 'x' },
  recency: { enabled: false, value: 0, min: 0, max: 2, postfix: 'x' },
  prevalence: { enabled: false, value: 1, min: 0, max: 2, postfix: 'x' },
  endorsed: { enabled: true, value: 1, min: 0, max: 2, postfix: 'x' },
  favorited: { enabled: false, value: 0, min: 0, max: 2, postfix: 'x' },
  bookmarked: { enabled: false, value: 0, min: 0, max: 2, postfix: 'x' },
};
