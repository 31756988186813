import { Menu, MenuItemCheckbox, MenuList, MenuPopover, MenuProps, MenuTrigger } from '@fluentui/react-components';
import React from 'react';
import { useAppData } from '../../../hooks';

type SuggestionFootnoteMenuProps = {
  appId: string;
} & React.ComponentProps<React.ElementType>;

const SuggestionFootnoteMenu = React.memo(function SuggestionFootnoteMenu({
  appId,
  children,
}: SuggestionFootnoteMenuProps) {
  const [footnoteItems, setFootnoteItems] = useAppData<Array<string>>(appId, 'footnoteItems');

  const onFootNoteSelectionChange: MenuProps['onCheckedValueChange'] = (e, { checkedItems }) => {
    setFootnoteItems(checkedItems);
  };

  return (
    <Menu checkedValues={{ footnotes: footnoteItems }} onCheckedValueChange={onFootNoteSelectionChange}>
      <MenuTrigger disableButtonEnhancement>{children}</MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItemCheckbox name="footnotes" value="relevancy">
            Relevancy Score
          </MenuItemCheckbox>
          <MenuItemCheckbox name="footnotes" value="documents">
            Source Documents
          </MenuItemCheckbox>
          <MenuItemCheckbox name="footnotes" value="bold_keywords">
            Bold Keywords
          </MenuItemCheckbox>
          <MenuItemCheckbox name="footnotes" value="preferences">
            Preferences
          </MenuItemCheckbox>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
});

export default SuggestionFootnoteMenu;
