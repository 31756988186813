import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_APPLICATION_ID } from '../../constants';
import { ApplicationType } from '../../enums';
import { RootState } from '../index';

type AppStaticState = {
  id: string;
  type: ApplicationType;
  name: string;
};

type AppState = AppStaticState & {
  [key: string]: any;
};

const appAdapter = createEntityAdapter({
  selectId: (app: AppState) => app.id,
});

const HomeApp = {
  id: DEFAULT_APPLICATION_ID,
  type: ApplicationType.HOME_APP,
  name: 'Home',
};

const initialState = appAdapter.getInitialState(
  {
    selectedApp: DEFAULT_APPLICATION_ID,
  },
  [HomeApp],
);

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    selectApp: (state, action: PayloadAction<string>) => {
      if (!state.ids.includes(action.payload)) return;
      state.selectedApp = action.payload;
    },
    createApp: (state, action: PayloadAction<AppState>) => {
      appAdapter.addOne(state, action.payload);
      state.selectedApp = action.payload.id;
    },
    closeApp: (state, action: PayloadAction<string>) => {
      if (!state.ids.includes(action.payload)) return;

      const appIds = state.ids;
      const idx = appIds.findIndex((id: string) => id === action.payload);
      const nextAppIdx = idx - 1 >= 0 ? idx - 1 : idx + 1;

      appAdapter.removeOne(state, action.payload);
      state.selectedApp = appIds[nextAppIdx] ?? DEFAULT_APPLICATION_ID;
    },
    updateApp: (state, action: PayloadAction<{ id: string; field: string; valueOrUpdater?: any }>) => {
      const { id, field, valueOrUpdater } = action.payload;
      if (!state.ids.includes(id)) {
        return;
      }
      const prevValue = state.entities[id][field];
      const newValue = typeof valueOrUpdater === 'function' ? valueOrUpdater(prevValue) : valueOrUpdater;
      appAdapter.updateOne(state, { id, changes: { [field]: newValue } });
    },
    setAppDataFields: (state, action: PayloadAction<{ id: string; valueOrUpdater?: any }>) => {
      const { id, valueOrUpdater } = action.payload;
      if (!state.ids.includes(id)) {
        return;
      }
      const prevValue = state.entities[id];
      const newValue = typeof valueOrUpdater === 'function' ? valueOrUpdater(prevValue) : valueOrUpdater;
      appAdapter.updateOne(state, { id, changes: newValue });
    },
    resetAppSlice: () => initialState,
  },
});

export const appSelector = appAdapter.getSelectors((state: RootState) => state.apps);

const { reducer, actions } = appsSlice;
export const { selectApp, createApp, updateApp, closeApp, resetAppSlice, setAppDataFields } = actions;
export default reducer;
