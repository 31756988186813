import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    ...shorthands.padding('10px', '10px'),
  },
  dividerContainer: {
    height: '20px',
  },
  dividerText: {
    color: '#242424',
    fontSize: '12px',
    lineHeight: '16px',
    wordWrap: 'break-word',
    textTransform: 'capitalize',
  },
  searchTextPlaceholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    ...shorthands.padding('10px', 0),
  },
  searchTextPlaceholder: {
    width: '100%',
    backgroundColor: 'rgba(211, 211, 211, 0.3)',
  },
  suggestionListContainer: {
    ...shorthands.flex(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    minHeight: 'min-content',
    ...shorthands.padding('10px', '5px'),
    ...shorthands.overflow('hidden', 'auto'),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100vh',
    width: '100%',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    backdropFilter: '10px',
    zIndex: 1,
    ...shorthands.transition('display', '5s', 'inherit', 'ease-in-out'),
  },
  displayNone: { display: 'none' },
  popOverTrigger: {
    position: 'relative',
    minWidth: '36px',
    paddingRight: '5px',
  },
  popOverTriggerActive: {
    position: 'absolute',
    right: '15px',
    top: '10px',
    zIndex: '1000',
    ...shorthands.borderRadius('1000px'),
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  popOverTriggerBadge: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
});
