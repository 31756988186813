import React from 'react';

export function createReactElement(node: Node): React.ReactNode {
  if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as Element;
    const tagName = element.tagName.toLowerCase();
    const props: Record<string, any> = {};

    for (const attr of element.attributes) {
      props[attr.name === 'class' ? 'className' : attr.name] = attr.value;
    }

    const children = Array.from(node.childNodes).map(createReactElement);

    return React.createElement(tagName, props, ...children);
  } else if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  }

  return null;
}
