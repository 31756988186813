import { BrandVariants, createDarkTheme, createLightTheme, Theme } from '@fluentui/react-components';

const syntheiaTheme: BrandVariants = {
  10: '#060201',
  20: '#25110C',
  30: '#401914',
  40: '#561E19',
  50: '#6C231D',
  60: '#842722',
  70: '#9D2C27',
  80: '#B6302B',
  90: '#CD3632',
  100: '#D65045',
  110: '#DE6759',
  120: '#E67C6D',
  130: '#EC9082',
  140: '#F2A497',
  150: '#F7B8AD',
  160: '#FACCC3',
};

const syntheiaWebLight: Theme = {
  ...createLightTheme(syntheiaTheme),
};

const syntheiaWebDark: Theme = {
  ...createDarkTheme(syntheiaTheme),
};

syntheiaWebDark.colorBrandForeground1 = syntheiaTheme[110];
syntheiaWebDark.colorBrandForeground2 = syntheiaTheme[120];

const syntheiaBrandTheme: BrandVariants = {
  10: '#030204',
  20: '#1B141E',
  30: '#2D2033',
  40: '#3C2945',
  50: '#4C3358',
  60: '#5C3D6B',
  70: '#6C487E',
  80: '#785688',
  90: '#846593',
  100: '#91749E',
  110: '#9D83A9',
  120: '#AA93B4',
  130: '#B6A3BF',
  140: '#C3B3CA',
  150: '#D0C3D6',
  160: '#DDD3E1',
};

const syntheiaBrandWebLight: Theme = {
  ...createLightTheme(syntheiaBrandTheme),
  colorBrandBackground: '#6A467C',
};

const syntheiaBrandDarkTheme: Theme = {
  ...createDarkTheme(syntheiaBrandTheme),
};

syntheiaBrandWebLight.colorBrandForeground1 = syntheiaBrandTheme[30];
syntheiaBrandDarkTheme.colorBrandForeground2 = syntheiaBrandTheme[30];

export const SyntheiaWebLight = syntheiaWebLight;
export const SyntheiaWebDark = syntheiaWebDark;
export const SyntheiaBrandWebLight = syntheiaBrandWebLight;
export const SyntheiaBrandDarkTheme = syntheiaBrandDarkTheme;
