import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import EditorApp from '../../components/EditorApp';
import TaskpaneApp from '../../components/TaskpaneApp';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    height: '100vh',
    width: '100vw',
    flexWrap: 'nowrap',
    ...shorthands.overflow('hidden'),
  },
  containerStandalone: {
    justifyContent: 'center',
  },
  editorContainer: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'flex-start',
    backgroundImage: 'url("landing.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  taskPaneContainer: {
    width: '540px',
    height: '100%',
    boxShadow: tokens.shadow16,
  },
});

export default function AuthenticatedApp(props: React.ComponentProps<any>) {
  const isStandalone = props.mode?.toString().toLowerCase().indexOf('standalone') > -1;
  const styles = useStyles();

  return (
    <div className={mergeClasses(styles.container, isStandalone ? styles.containerStandalone : '')}>
      {!isStandalone && (
        <div className={styles.editorContainer}>
          <EditorApp />
        </div>
      )}
      <div className={styles.taskPaneContainer}>
        <TaskpaneApp />
      </div>
    </div>
  );
}
