import {
  Button,
  Combobox,
  ComboboxProps,
  Field,
  makeStyles,
  tokens,
  useComboboxFilter,
} from '@fluentui/react-components';
import { ArrowSyncRegular } from '@fluentui/react-icons';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Workspace } from '../../models/responses/workspaces';
import { LoadingContainer } from '../Loading';

export type WorkspaceSelectorProps = {
  selectedWorkspace?: string;
  onSelectWorkspace: (selectedWorkspace: string) => void;
  workspaces: Workspace[];
  isLoading?: boolean;
  refreshWorkspaces?: () => void;
} & ComboboxProps;

const useStyles = makeStyles({
  loaderContainer: {
    width: 'auto',
  },
});

const WorkspaceSelector = React.memo(
  ({
    selectedWorkspace,
    workspaces,
    isLoading,
    refreshWorkspaces,
    onSelectWorkspace,
    ...props
  }: WorkspaceSelectorProps) => {
    const styles = useStyles();

    const options = useMemo(() => {
      if (isLoading) return [];
      return workspaces
        .map((workspace: any) => ({
          children: workspace.name,
          value: `${workspace.workspaceId}`,
        }))
        .sort((a: any, b: any) => a.children - b.children);
    }, [workspaces, isLoading]);

    const onChangeWorkspaceText: ComboboxProps['onChange'] = event => {
      const value = event.target.value?.trim();
      setQuery(value as string);
    };

    const [query, setQuery] = useState<string>(() => {
      if (selectedWorkspace) {
        return (_.find(workspaces, w => w.workspaceId === selectedWorkspace.toString())?.name as string) || '';
      }
      return '';
    });

    useEffect(() => {
      if (!selectedWorkspace) return;
      setQuery((_.find(workspaces, w => w.workspaceId === selectedWorkspace.toString())?.name as string) || '');
    }, [selectedWorkspace]);

    const children = useComboboxFilter(query, options, {
      noOptionsMessage: 'No results match your search.',
      optionToText: option => option.children,
    });

    const onFocus = () => {
      setQuery('');
    };

    const onBlur = () => {
      if (selectedWorkspace) {
        setQuery((_.find(workspaces, w => w.workspaceId === selectedWorkspace.toString())?.name as string) || '');
      }
    };

    return (
      <Field
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          columnGap: '3px',
          width: '100%',
        }}
      >
        <Combobox
          onFocus={onFocus}
          onBlur={onBlur}
          positioning={'below-end'}
          disabled={isLoading}
          multiselect={false}
          listbox={{ style: { maxHeight: '300px' } }}
          style={{ flexGrow: 1, borderColor: tokens.colorNeutralStroke1 }}
          placeholder="Select Workspaces"
          size={props.size}
          freeform={true}
          value={query}
          onChange={onChangeWorkspaceText}
          selectedOptions={selectedWorkspace ? [selectedWorkspace] : []}
          onOptionSelect={(_ev, data) => {
            data.optionText && setQuery(data.optionText);
            data.optionValue && onSelectWorkspace(data.optionValue);
          }}
          {...props}
        >
          {children}
        </Combobox>
        <LoadingContainer isLoading={!!isLoading} size="extra-tiny" className={styles.loaderContainer}>
          <Button
            size="small"
            appearance="transparent"
            icon={<ArrowSyncRegular color={tokens.colorBrandBackground} />}
            onClick={refreshWorkspaces}
          />
        </LoadingContainer>
      </Field>
    );
  },
);

export default WorkspaceSelector;
