import { makeStyles, Textarea, TextareaProps } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import parseHtmlContent from '../../../utils/parseHtmlContent';

type DropboxTextAreaProps = {
  onChange: (value: string) => void;
  value: string;
} & Omit<TextareaProps, 'value' | 'onChange'>;

const useStyles = makeStyles({
  inputContainer: {
    minWidth: '66%',
    '@media screen and (max-width: 400px)': { minWidth: '80%' },
  },
});

const DropboxTextArea = React.memo(({ value, onChange, ...props }: DropboxTextAreaProps) => {
  const styles = useStyles();

  const onInputHandleResize: React.FormEventHandler<HTMLTextAreaElement> = ev => {
    const { style } = ev.currentTarget;
    style.height = style.minHeight = 'auto';
    style.minHeight = `${Math.min(ev.currentTarget.scrollHeight + 4, parseInt(style.maxHeight))}px`;
    style.height = `${ev.currentTarget.scrollHeight + 4}px`;
  };

  const onDragOver: React.DragEventHandler<HTMLDivElement> = ev => {
    ev.preventDefault();
  };

  const onDrop: React.DragEventHandler<HTMLDivElement> = (ev: React.DragEvent<HTMLDivElement>) => {
    const htmlContent = ev.dataTransfer.getData('text/html') || `<p>${ev.dataTransfer.getData('text/plain')}</p>`;
    console.log(htmlContent);
    const text = parseHtmlContent(htmlContent);
    if (text) {
      onChange(value + text);
    }
  };

  useEffect(() => {
    const textArea: any = document.querySelector('textarea');
    if (textArea) {
      onInputHandleResize({ currentTarget: textArea } as any);
    }
  }, [value]);

  return (
    <div className={styles.inputContainer} onDragOver={onDragOver} onDrop={onDrop}>
      <Textarea
        {...props}
        className={props.className}
        onChange={(_ev, data) => onChange(data.value as string)}
        onInput={onInputHandleResize}
        placeholder="Describe what you want to find in natural language or paste in a provision from your document."
        textarea={{ style: { maxHeight: '148px' } }}
        value={value}
      />
    </div>
  );
});

export default DropboxTextArea;
