import { makeStyles, shorthands } from '@fluentui/react-components';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationApp from '../../../libs/apps/AuthenticationApp';
import { AppLoadingContainer } from '../../../libs/components/Loading';
import { useAuth } from '../../../libs/hooks';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100vmin',
    flexDirection: 'row',
    columnGap: '12px',
    columnCount: 3,
    justifyContent: 'center',
    alignItems: 'center',
    ...shorthands.overflow('hidden'),
  },
});

export default function LoginPage(props: React.ComponentProps<any>) {
  const navigate = useNavigate();
  const codeRef = useRef(
    new URLSearchParams(window.location.search).get('code') ??
      new URLSearchParams(window.location.hash?.slice(1)).get('code'),
  );
  const isStandalone = window.location.pathname.includes('standalone');

  const styles = useStyles();

  const { ssoFallbackURL, isSSOEnabled, isLoggedIn, isLoading, loginAzureAD, login } = useAuth();

  const onLoginSSO = useCallback(() => {
    const isURLValid = (url: string) => {
      try {
        new URL(url);
        return true;
      } catch (_err: any) {
        return false;
      }
    };
    if (ssoFallbackURL && !_.isEmpty(ssoFallbackURL) && isURLValid(ssoFallbackURL)) {
      window.location.href = ssoFallbackURL as string;
    }
  }, [ssoFallbackURL]);

  useEffect(() => {
    const code = codeRef.current;
    if (code && !isLoggedIn) {
      loginAzureAD(code, `${window.location.protocol}//${window.location.host}`)
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {
          codeRef.current = null;
          if (isStandalone) {
            navigate('/standalone');
          }
        });
    }
  }, [loginAzureAD, isLoggedIn, navigate, isStandalone]);

  return (
    <div className={styles.container}>
      <AppLoadingContainer isLoading={isLoading}>
        <AuthenticationApp onLoginSSO={onLoginSSO} isSSOEnabled={isSSOEnabled} login={login} />
      </AppLoadingContainer>
    </div>
  );
}
