import { WorkerMessageType } from '../../enums';
import { AppContextV3 } from '../../models/requests';

type UpdateTrigramPayload = {
  appId: string;
  filter: any;
  clauses: Array<{ id: string; text: string }>;
  rankingOptions?: Record<string, any>;
};

const SharedGobalWorker = new SharedWorker(new URL('../../workers/index.ts', import.meta.url), {
  name: 'background-update-worker',
  type: 'classic',
  credentials: 'same-origin',
});

SharedGobalWorker.port.start();

export const dispatchUpdateTrigrams = (payload: UpdateTrigramPayload, appContext: AppContextV3) => {
  SharedGobalWorker.port.postMessage({ type: WorkerMessageType.UNUSUAL_TEXT_TRIGRAM_COUNTS, appContext, payload });
};

export default SharedGobalWorker.port;
